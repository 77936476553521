@charset "UTF-8";
/***************************************
	Theme Name: Sahifa
	Theme URI: http://themes.tielabs.com/sahifa/
	Description: The Ultimate WordPress News Theme you have been waiting for!
	Author: TieLabs
	Author URI: http://tielabs.com/
	Version: 5.6.17
	License: license purchased
	License URI: http://themeforest.net/licenses/regular_extended

	Tags: two-columns, fluid-layout, fixed-layout, custom-background, threaded-comments, translation-ready, custom-menu
***************************************/

/**
 * Main Styles
 * -----------------------------------------------------------------------------
 */

* {
    padding: 0;
    margin: 0;
    outline: none;
    list-style: none;
    border: 0 none;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

body {
    background: #FFF url(images/patterns/body-bg7.png);
    color: #333;
    font-family: 'Droid Sans', Arial, Verdana, sans-serif;

}

pre,
code {
    direction: ltr;
    background: url(images/code-bg.png);
    font: 11px/19px 'andale mono', 'lucida console', monospace;
    padding: 3px;
    display: block;
    overflow-x: visible;
    overflow-y: hidden;
    margin: 0 0 20px 0;
    color: #666;
    border-top: 1px solid #E1E1E1;
    border-left: 1px solid #E1E1E1;
    border-bottom: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

.alignright {
    float: right;
}

.alignleft {
    float: left;
}

.aligncenter {
    clear: both;
    margin-left: auto;
    margin-right: auto;
}

a {
    color: #444;
    text-decoration: none;
}

a:hover {
    color: #000;
}

.clear {
    clear: both;
}

#theme-header:after,
#signup_form:after,
#buddypress:after,
#main-nav:after,
.gallery:after,
#respond:before {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " ";
}

a,
.post-title a,
ul.slider-navigation li,
.pagenavi a,
a.more-link,
.main-menu li a,
.subscribe-icons a,
.flickr_badge_image img,
.mega-menu-item img,
.arqam-lite-widget-counter a i,
.arqam-lite-widget-counter a span,
.arqam-lite-widget-counter a small,
.search-block-large .search-button,
.search-block-large #s,
.widget.timeline-posts li span.tie-date,
.widget.timeline-posts li span.tie-date:before {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

iframe {
    max-width: 100%;
}

textarea,
input,
select {
    border: 1px solid #ccc;
    background: #fff;
    font-size: inherit;
}

textarea {
    overflow: auto;
    width: 100%;
}

textarea,
input {
    padding: 5px;
    outline: none;
}

select {
    padding: 2px;
}

option {
    padding: 0 4px;
}

input[type=button],
button {
    padding: 4px;
    cursor: pointer;
    vertical-align: middle;
}

input.text_input,
textarea.textarea {
    border: 1px solid #ccc;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

textarea,
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=tel],
input[type=number],
input[type=date],
input[type=file],
input[type=search],
input[type=color],
input[type=datetime],
input[type=datetime-local],
input[type=month],
input[type=range],
input[type=time],
input[type=week],
select {
    border-color: #F1F1F1;
    border-top-color: #DDD;
    border-left-color: #DDD;
    background-color: #F9F9F9;
    color: #515151;
    font: 12px Tahoma;
    padding: 8px;
    margin-bottom: 7px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

textarea:hover,
input[type=text]:hover,
input[type=password]:hover,
input[type=email]:hover,
input[type=url]:hover,
input[type=tel]:hover,
input[type=number]:hover,
input[type=date]:hover,
input[type=file]:hover,
input[type=search]:hover,
input[type=color]:hover,
input[type=datetime]:hover,
input[type=datetime-local]:hover,
input[type=month]:hover,
input[type=range]:hover,
input[type=time]:hover,
input[type=week]:hover,
select:focus {
    background-color: #F5F5F5;
}

textarea:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=tel]:focus,
input[type=number]:focus,
input[type=date]:focus,
input[type=file]:focus,
input[type=search]:focus,
input[type=color]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=month]:focus,
input[type=range]:focus,
input[type=time]:focus,
input[type=week]:focus,
select:focus {
    background-color: #fff;
}

.button,
a.button,
a.more-link,
#main-content input[type="submit"],
.form-submit #submit,
#login-form .login-button,
.widget-feedburner .feedburner-subscribe,
input[type="submit"],
#buddypress button,
#buddypress a.button,
#buddypress input[type=submit],
#buddypress input[type=reset],
#buddypress ul.button-nav li a,
#buddypress div.generic-button a,
#buddypress .comment-reply-link,
a.bp-title-button {
    width: auto;
    position: relative;
    background-color: #F88C00;
    color: #FFF;
    padding: 5px 12px;
    font: 12px Tahoma;
    display: inline-block;
    line-height: 22px;
    border: 0 none;
    cursor: pointer;
    text-decoration: none;
    webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

.button:hover,
a.button:hover,
a.more-link:hover,
#main-content input[type="submit"]:hover,
.form-submit #submit:hover,
#login-form .login-button:hover,
.widget-feedburner .feedburner-subscribe:hover,
input[type="submit"]:hover,
#buddypress button:hover,
#buddypress a.button:hover,
#buddypress input[type=submit]:hover,
#buddypress input[type=reset]:hover,
#buddypress ul.button-nav li a:hover,
#buddypress div.generic-button a:hover,
#buddypress .comment-reply-link:hover,
a.bp-title-button:hover {
    background-color: #777 !important;
    text-decoration: none;
}

::-moz-selection {
    background: #F9A386;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #F9A386;
    color: #fff;
    text-shadow: none;
}

::-webkit-scrollbar-track {
    background: #FFFFFF;
    -webkit-box-shadow: inset 1px 1px 2px #E0E0E0;
    border: 1px solid #D8D8D8;
}

::-webkit-scrollbar-thumb {
    background: #F88C00;
    -webkit-box-shadow: inset 1px 1px 2px rgba(155, 155, 155, 0.4);
}

::-webkit-scrollbar-thumb:hover {
    -webkit-box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:active {
    background: #888;
    -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
}


/**
 * Fonts
 * -----------------------------------------------------------------------------
 */

@font-face {
    font-family: 'BebasNeueRegular';
    src: url('/builds/fonts/BebasNeue/BebasNeue-webfont.eot');
    src: url('/builds/fonts/BebasNeue/BebasNeue-webfontd41d.eot?#iefix') format('embedded-opentype'),
    url('/builds/fonts/BebasNeue/BebasNeue-webfont.woff') format('woff'),
    url('/builds/fonts/BebasNeue/BebasNeue-webfont.ttf') format('truetype'),
    url('/builds/fonts/BebasNeue/BebasNeue-webfont.svg#BebasNeueRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

#respond h3,
.woocommerce-tabs .entry-content h2,
.woocommerce .related.products h2,
.entry .woocommerce h2,
.woocommerce-billing-fields h3,
.woocommerce-shipping-fields h3,
#order_review_heading,
#bbpress-forums fieldset.bbp-form legend,
#buddypress .item-body h4,
#buddypress #item-body h4 {
    font-size: 22px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
}


/* Font Awesome */
@font-face {
    font-family: 'FontAwesome';
    src: url('/builds/fonts/fontawesome-webfont5b62.eot?v=4.6.3');
    src: url('/builds/fonts/fontawesome-webfontd41d.eot?#iefix&v=4.6.3') format('embedded-opentype'),
    url('/builds/fonts/fontawesome-webfont5b62.woff2?v=4.6.3') format('woff2'),
    url('/builds/fonts/fontawesome-webfont5b62.woff?v=4.6.3') format('woff'),
    url('/builds/fonts/fontawesome-webfont5b62.ttf?v=4.6.3') format('truetype'),
    url('/builds/fonts/fontawesome-webfont5b62.svg?v=4.6.3#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.fa-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%
}

.fa-2x {
    font-size: 2em
}

.fa-3x {
    font-size: 3em
}

.fa-4x {
    font-size: 4em
}

.fa-5x {
    font-size: 5em
}

.fa-fw {
    width: 1.28571429em;
    text-align: center
}

.fa-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none
}

.fa-ul > li {
    position: relative
}

.fa-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: .14285714em;
    text-align: center
}

.fa-li.fa-lg {
    left: -1.85714286em
}

.fa-border {
    padding: .2em .25em .15em;
    border: solid .08em #eee;
    border-radius: .1em
}

.fa-pull-left {
    float: left
}

.fa-pull-right {
    float: right
}

.fa.fa-pull-left {
    margin-right: .3em
}

.fa.fa-pull-right {
    margin-left: .3em
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.fa.pull-left {
    margin-right: .3em
}

.fa.pull-right {
    margin-left: .3em
}

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
}

.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg)
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1)
}

.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1)
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical {
    filter: none
}

.fa-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle
}

.fa-stack-1x, .fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}

.fa-stack-1x {
    line-height: inherit
}

.fa-stack-2x {
    font-size: 2em
}

.fa-inverse {
    color: #fff
}

.fa-glass:before {
    content: "\f000"
}

.fa-music:before {
    content: "\f001"
}

.fa-search:before {
    content: "\f002"
}

.fa-envelope-o:before {
    content: "\f003"
}

.fa-heart:before {
    content: "\f004"
}

.fa-star:before {
    content: "\f005"
}

.fa-star-o:before {
    content: "\f006"
}

.fa-user:before {
    content: "\f007"
}

.fa-film:before {
    content: "\f008"
}

.fa-th-large:before {
    content: "\f009"
}

.fa-th:before {
    content: "\f00a"
}

.fa-th-list:before {
    content: "\f00b"
}

.fa-check:before {
    content: "\f00c"
}

.fa-remove:before, .fa-close:before, .fa-times:before {
    content: "\f00d"
}

.fa-search-plus:before {
    content: "\f00e"
}

.fa-search-minus:before {
    content: "\f010"
}

.fa-power-off:before {
    content: "\f011"
}

.fa-signal:before {
    content: "\f012"
}

.fa-gear:before, .fa-cog:before {
    content: "\f013"
}

.fa-trash-o:before {
    content: "\f014"
}

.fa-home:before {
    content: "\f015"
}

.fa-file-o:before {
    content: "\f016"
}

.fa-clock-o:before {
    content: "\f017"
}

.fa-road:before {
    content: "\f018"
}

.fa-download:before {
    content: "\f019"
}

.fa-arrow-circle-o-down:before {
    content: "\f01a"
}

.fa-arrow-circle-o-up:before {
    content: "\f01b"
}

.fa-inbox:before {
    content: "\f01c"
}

.fa-play-circle-o:before {
    content: "\f01d"
}

.fa-rotate-right:before, .fa-repeat:before {
    content: "\f01e"
}

.fa-refresh:before {
    content: "\f021"
}

.fa-list-alt:before {
    content: "\f022"
}

.fa-lock:before {
    content: "\f023"
}

.fa-flag:before {
    content: "\f024"
}

.fa-headphones:before {
    content: "\f025"
}

.fa-volume-off:before {
    content: "\f026"
}

.fa-volume-down:before {
    content: "\f027"
}

.fa-volume-up:before {
    content: "\f028"
}

.fa-qrcode:before {
    content: "\f029"
}

.fa-barcode:before {
    content: "\f02a"
}

.fa-tag:before {
    content: "\f02b"
}

.fa-tags:before {
    content: "\f02c"
}

.fa-book:before {
    content: "\f02d"
}

.fa-bookmark:before {
    content: "\f02e"
}

.fa-print:before {
    content: "\f02f"
}

.fa-camera:before {
    content: "\f030"
}

.fa-font:before {
    content: "\f031"
}

.fa-bold:before {
    content: "\f032"
}

.fa-italic:before {
    content: "\f033"
}

.fa-text-height:before {
    content: "\f034"
}

.fa-text-width:before {
    content: "\f035"
}

.fa-align-left:before {
    content: "\f036"
}

.fa-align-center:before {
    content: "\f037"
}

.fa-align-right:before {
    content: "\f038"
}

.fa-align-justify:before {
    content: "\f039"
}

.fa-list:before {
    content: "\f03a"
}

.fa-dedent:before, .fa-outdent:before {
    content: "\f03b"
}

.fa-indent:before {
    content: "\f03c"
}

.fa-video-camera:before {
    content: "\f03d"
}

.fa-photo:before, .fa-image:before, .fa-picture-o:before {
    content: "\f03e"
}

.fa-pencil:before {
    content: "\f040"
}

.fa-map-marker:before {
    content: "\f041"
}

.fa-adjust:before {
    content: "\f042"
}

.fa-tint:before {
    content: "\f043"
}

.fa-edit:before, .fa-pencil-square-o:before {
    content: "\f044"
}

.fa-share-square-o:before {
    content: "\f045"
}

.fa-check-square-o:before {
    content: "\f046"
}

.fa-arrows:before {
    content: "\f047"
}

.fa-step-backward:before {
    content: "\f048"
}

.fa-fast-backward:before {
    content: "\f049"
}

.fa-backward:before {
    content: "\f04a"
}

.fa-play:before {
    content: "\f04b"
}

.fa-pause:before {
    content: "\f04c"
}

.fa-stop:before {
    content: "\f04d"
}

.fa-forward:before {
    content: "\f04e"
}

.fa-fast-forward:before {
    content: "\f050"
}

.fa-step-forward:before {
    content: "\f051"
}

.fa-eject:before {
    content: "\f052"
}

.fa-chevron-left:before {
    content: "\f053"
}

.fa-chevron-right:before {
    content: "\f054"
}

.fa-plus-circle:before {
    content: "\f055"
}

.fa-minus-circle:before {
    content: "\f056"
}

.fa-times-circle:before {
    content: "\f057"
}

.fa-check-circle:before {
    content: "\f058"
}

.fa-question-circle:before {
    content: "\f059"
}

.fa-info-circle:before {
    content: "\f05a"
}

.fa-crosshairs:before {
    content: "\f05b"
}

.fa-times-circle-o:before {
    content: "\f05c"
}

.fa-check-circle-o:before {
    content: "\f05d"
}

.fa-ban:before {
    content: "\f05e"
}

.fa-arrow-left:before {
    content: "\f060"
}

.fa-arrow-right:before {
    content: "\f061"
}

.fa-arrow-up:before {
    content: "\f062"
}

.fa-arrow-down:before {
    content: "\f063"
}

.fa-mail-forward:before, .fa-share:before {
    content: "\f064"
}

.fa-expand:before {
    content: "\f065"
}

.fa-compress:before {
    content: "\f066"
}

.fa-plus:before {
    content: "\f067"
}

.fa-minus:before {
    content: "\f068"
}

.fa-asterisk:before {
    content: "\f069"
}

.fa-exclamation-circle:before {
    content: "\f06a"
}

.fa-gift:before {
    content: "\f06b"
}

.fa-leaf:before {
    content: "\f06c"
}

.fa-fire:before {
    content: "\f06d"
}

.fa-eye:before {
    content: "\f06e"
}

.fa-eye-slash:before {
    content: "\f070"
}

.fa-warning:before, .fa-exclamation-triangle:before {
    content: "\f071"
}

.fa-plane:before {
    content: "\f072"
}

.fa-calendar:before {
    content: "\f073"
}

.fa-random:before {
    content: "\f074"
}

.fa-comment:before {
    content: "\f075"
}

.fa-magnet:before {
    content: "\f076"
}

.fa-chevron-up:before {
    content: "\f077"
}

.fa-chevron-down:before {
    content: "\f078"
}

.fa-retweet:before {
    content: "\f079"
}

.fa-shopping-cart:before {
    content: "\f07a"
}

.fa-folder:before {
    content: "\f07b"
}

.fa-folder-open:before {
    content: "\f07c"
}

.fa-arrows-v:before {
    content: "\f07d"
}

.fa-arrows-h:before {
    content: "\f07e"
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
    content: "\f080"
}

.fa-twitter-square:before {
    content: "\f081"
}

.fa-facebook-square:before {
    content: "\f082"
}

.fa-camera-retro:before {
    content: "\f083"
}

.fa-key:before {
    content: "\f084"
}

.fa-gears:before, .fa-cogs:before {
    content: "\f085"
}

.fa-comments:before {
    content: "\f086"
}

.fa-thumbs-o-up:before {
    content: "\f087"
}

.fa-thumbs-o-down:before {
    content: "\f088"
}

.fa-star-half:before {
    content: "\f089"
}

.fa-heart-o:before {
    content: "\f08a"
}

.fa-sign-out:before {
    content: "\f08b"
}

.fa-linkedin-square:before {
    content: "\f08c"
}

.fa-thumb-tack:before {
    content: "\f08d"
}

.fa-external-link:before {
    content: "\f08e"
}

.fa-sign-in:before {
    content: "\f090"
}

.fa-trophy:before {
    content: "\f091"
}

.fa-github-square:before {
    content: "\f092"
}

.fa-upload:before {
    content: "\f093"
}

.fa-lemon-o:before {
    content: "\f094"
}

.fa-phone:before {
    content: "\f095"
}

.fa-square-o:before {
    content: "\f096"
}

.fa-bookmark-o:before {
    content: "\f097"
}

.fa-phone-square:before {
    content: "\f098"
}

.fa-twitter:before {
    content: "\f099"
}

.fa-facebook-f:before, .fa-facebook:before {
    content: "\f09a"
}

.fa-github:before {
    content: "\f09b"
}

.fa-unlock:before {
    content: "\f09c"
}

.fa-credit-card:before {
    content: "\f09d"
}

.fa-feed:before, .fa-rss:before {
    content: "\f09e"
}

.fa-hdd-o:before {
    content: "\f0a0"
}

.fa-bullhorn:before {
    content: "\f0a1"
}

.fa-bell:before {
    content: "\f0f3"
}

.fa-certificate:before {
    content: "\f0a3"
}

.fa-hand-o-right:before {
    content: "\f0a4"
}

.fa-hand-o-left:before {
    content: "\f0a5"
}

.fa-hand-o-up:before {
    content: "\f0a6"
}

.fa-hand-o-down:before {
    content: "\f0a7"
}

.fa-arrow-circle-left:before {
    content: "\f0a8"
}

.fa-arrow-circle-right:before {
    content: "\f0a9"
}

.fa-arrow-circle-up:before {
    content: "\f0aa"
}

.fa-arrow-circle-down:before {
    content: "\f0ab"
}

.fa-globe:before {
    content: "\f0ac"
}

.fa-wrench:before {
    content: "\f0ad"
}

.fa-tasks:before {
    content: "\f0ae"
}

.fa-filter:before {
    content: "\f0b0"
}

.fa-briefcase:before {
    content: "\f0b1"
}

.fa-arrows-alt:before {
    content: "\f0b2"
}

.fa-group:before, .fa-users:before {
    content: "\f0c0"
}

.fa-chain:before, .fa-link:before {
    content: "\f0c1"
}

.fa-cloud:before {
    content: "\f0c2"
}

.fa-flask:before {
    content: "\f0c3"
}

.fa-cut:before, .fa-scissors:before {
    content: "\f0c4"
}

.fa-copy:before, .fa-files-o:before {
    content: "\f0c5"
}

.fa-paperclip:before {
    content: "\f0c6"
}

.fa-save:before, .fa-floppy-o:before {
    content: "\f0c7"
}

.fa-square:before {
    content: "\f0c8"
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
    content: "\f0c9"
}

.fa-list-ul:before {
    content: "\f0ca"
}

.fa-list-ol:before {
    content: "\f0cb"
}

.fa-strikethrough:before {
    content: "\f0cc"
}

.fa-underline:before {
    content: "\f0cd"
}

.fa-table:before {
    content: "\f0ce"
}

.fa-magic:before {
    content: "\f0d0"
}

.fa-truck:before {
    content: "\f0d1"
}

.fa-pinterest:before {
    content: "\f0d2"
}

.fa-pinterest-square:before {
    content: "\f0d3"
}

.fa-google-plus-square:before {
    content: "\f0d4"
}

.fa-google-plus:before {
    content: "\f0d5"
}

.fa-money:before {
    content: "\f0d6"
}

.fa-caret-down:before {
    content: "\f0d7"
}

.fa-caret-up:before {
    content: "\f0d8"
}

.fa-caret-left:before {
    content: "\f0d9"
}

.fa-caret-right:before {
    content: "\f0da"
}

.fa-columns:before {
    content: "\f0db"
}

.fa-unsorted:before, .fa-sort:before {
    content: "\f0dc"
}

.fa-sort-down:before, .fa-sort-desc:before {
    content: "\f0dd"
}

.fa-sort-up:before, .fa-sort-asc:before {
    content: "\f0de"
}

.fa-envelope:before {
    content: "\f0e0"
}

.fa-linkedin:before {
    content: "\f0e1"
}

.fa-rotate-left:before, .fa-undo:before {
    content: "\f0e2"
}

.fa-legal:before, .fa-gavel:before {
    content: "\f0e3"
}

.fa-dashboard:before, .fa-tachometer:before {
    content: "\f0e4"
}

.fa-comment-o:before {
    content: "\f0e5"
}

.fa-comments-o:before {
    content: "\f0e6"
}

.fa-flash:before, .fa-bolt:before {
    content: "\f0e7"
}

.fa-sitemap:before {
    content: "\f0e8"
}

.fa-umbrella:before {
    content: "\f0e9"
}

.fa-paste:before, .fa-clipboard:before {
    content: "\f0ea"
}

.fa-lightbulb-o:before {
    content: "\f0eb"
}

.fa-exchange:before {
    content: "\f0ec"
}

.fa-cloud-download:before {
    content: "\f0ed"
}

.fa-cloud-upload:before {
    content: "\f0ee"
}

.fa-user-md:before {
    content: "\f0f0"
}

.fa-stethoscope:before {
    content: "\f0f1"
}

.fa-suitcase:before {
    content: "\f0f2"
}

.fa-bell-o:before {
    content: "\f0a2"
}

.fa-coffee:before {
    content: "\f0f4"
}

.fa-cutlery:before {
    content: "\f0f5"
}

.fa-file-text-o:before {
    content: "\f0f6"
}

.fa-building-o:before {
    content: "\f0f7"
}

.fa-hospital-o:before {
    content: "\f0f8"
}

.fa-ambulance:before {
    content: "\f0f9"
}

.fa-medkit:before {
    content: "\f0fa"
}

.fa-fighter-jet:before {
    content: "\f0fb"
}

.fa-beer:before {
    content: "\f0fc"
}

.fa-h-square:before {
    content: "\f0fd"
}

.fa-plus-square:before {
    content: "\f0fe"
}

.fa-angle-double-left:before {
    content: "\f100"
}

.fa-angle-double-right:before {
    content: "\f101"
}

.fa-angle-double-up:before {
    content: "\f102"
}

.fa-angle-double-down:before {
    content: "\f103"
}

.fa-angle-left:before {
    content: "\f104"
}

.fa-angle-right:before {
    content: "\f105"
}

.fa-angle-up:before {
    content: "\f106"
}

.fa-angle-down:before {
    content: "\f107"
}

.fa-desktop:before {
    content: "\f108"
}

.fa-laptop:before {
    content: "\f109"
}

.fa-tablet:before {
    content: "\f10a"
}

.fa-mobile-phone:before, .fa-mobile:before {
    content: "\f10b"
}

.fa-circle-o:before {
    content: "\f10c"
}

.fa-quote-left:before {
    content: "\f10d"
}

.fa-quote-right:before {
    content: "\f10e"
}

.fa-spinner:before {
    content: "\f110"
}

.fa-circle:before {
    content: "\f111"
}

.fa-mail-reply:before, .fa-reply:before {
    content: "\f112"
}

.fa-github-alt:before {
    content: "\f113"
}

.fa-folder-o:before {
    content: "\f114"
}

.fa-folder-open-o:before {
    content: "\f115"
}

.fa-smile-o:before {
    content: "\f118"
}

.fa-frown-o:before {
    content: "\f119"
}

.fa-meh-o:before {
    content: "\f11a"
}

.fa-gamepad:before {
    content: "\f11b"
}

.fa-keyboard-o:before {
    content: "\f11c"
}

.fa-flag-o:before {
    content: "\f11d"
}

.fa-flag-checkered:before {
    content: "\f11e"
}

.fa-terminal:before {
    content: "\f120"
}

.fa-code:before {
    content: "\f121"
}

.fa-mail-reply-all:before, .fa-reply-all:before {
    content: "\f122"
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
    content: "\f123"
}

.fa-location-arrow:before {
    content: "\f124"
}

.fa-crop:before {
    content: "\f125"
}

.fa-code-fork:before {
    content: "\f126"
}

.fa-unlink:before, .fa-chain-broken:before {
    content: "\f127"
}

.fa-question:before {
    content: "\f128"
}

.fa-info:before {
    content: "\f129"
}

.fa-exclamation:before {
    content: "\f12a"
}

.fa-superscript:before {
    content: "\f12b"
}

.fa-subscript:before {
    content: "\f12c"
}

.fa-eraser:before {
    content: "\f12d"
}

.fa-puzzle-piece:before {
    content: "\f12e"
}

.fa-microphone:before {
    content: "\f130"
}

.fa-microphone-slash:before {
    content: "\f131"
}

.fa-shield:before {
    content: "\f132"
}

.fa-calendar-o:before {
    content: "\f133"
}

.fa-fire-extinguisher:before {
    content: "\f134"
}

.fa-rocket:before {
    content: "\f135"
}

.fa-maxcdn:before {
    content: "\f136"
}

.fa-chevron-circle-left:before {
    content: "\f137"
}

.fa-chevron-circle-right:before {
    content: "\f138"
}

.fa-chevron-circle-up:before {
    content: "\f139"
}

.fa-chevron-circle-down:before {
    content: "\f13a"
}

.fa-html5:before {
    content: "\f13b"
}

.fa-css3:before {
    content: "\f13c"
}

.fa-anchor:before {
    content: "\f13d"
}

.fa-unlock-alt:before {
    content: "\f13e"
}

.fa-bullseye:before {
    content: "\f140"
}

.fa-ellipsis-h:before {
    content: "\f141"
}

.fa-ellipsis-v:before {
    content: "\f142"
}

.fa-rss-square:before {
    content: "\f143"
}

.fa-play-circle:before {
    content: "\f144"
}

.fa-ticket:before {
    content: "\f145"
}

.fa-minus-square:before {
    content: "\f146"
}

.fa-minus-square-o:before {
    content: "\f147"
}

.fa-level-up:before {
    content: "\f148"
}

.fa-level-down:before {
    content: "\f149"
}

.fa-check-square:before {
    content: "\f14a"
}

.fa-pencil-square:before {
    content: "\f14b"
}

.fa-external-link-square:before {
    content: "\f14c"
}

.fa-share-square:before {
    content: "\f14d"
}

.fa-compass:before {
    content: "\f14e"
}

.fa-toggle-down:before, .fa-caret-square-o-down:before {
    content: "\f150"
}

.fa-toggle-up:before, .fa-caret-square-o-up:before {
    content: "\f151"
}

.fa-toggle-right:before, .fa-caret-square-o-right:before {
    content: "\f152"
}

.fa-euro:before, .fa-eur:before {
    content: "\f153"
}

.fa-gbp:before {
    content: "\f154"
}

.fa-dollar:before, .fa-usd:before {
    content: "\f155"
}

.fa-rupee:before, .fa-inr:before {
    content: "\f156"
}

.fa-cny:before, .fa-rmb:before, .fa-yen:before, .fa-jpy:before {
    content: "\f157"
}

.fa-ruble:before, .fa-rouble:before, .fa-rub:before {
    content: "\f158"
}

.fa-won:before, .fa-krw:before {
    content: "\f159"
}

.fa-bitcoin:before, .fa-btc:before {
    content: "\f15a"
}

.fa-file:before {
    content: "\f15b"
}

.fa-file-text:before {
    content: "\f15c"
}

.fa-sort-alpha-asc:before {
    content: "\f15d"
}

.fa-sort-alpha-desc:before {
    content: "\f15e"
}

.fa-sort-amount-asc:before {
    content: "\f160"
}

.fa-sort-amount-desc:before {
    content: "\f161"
}

.fa-sort-numeric-asc:before {
    content: "\f162"
}

.fa-sort-numeric-desc:before {
    content: "\f163"
}

.fa-thumbs-up:before {
    content: "\f164"
}

.fa-thumbs-down:before {
    content: "\f165"
}

.fa-youtube-square:before {
    content: "\f166"
}

.fa-youtube:before {
    content: "\f167"
}

.fa-xing:before {
    content: "\f168"
}

.fa-xing-square:before {
    content: "\f169"
}

.fa-youtube-play:before {
    content: "\f16a"
}

.fa-dropbox:before {
    content: "\f16b"
}

.fa-stack-overflow:before {
    content: "\f16c"
}

.fa-instagram:before {
    content: "\f16d"
}

.fa-flickr:before {
    content: "\f16e"
}

.fa-adn:before {
    content: "\f170"
}

.fa-bitbucket:before {
    content: "\f171"
}

.fa-bitbucket-square:before {
    content: "\f172"
}

.fa-tumblr:before {
    content: "\f173"
}

.fa-tumblr-square:before {
    content: "\f174"
}

.fa-long-arrow-down:before {
    content: "\f175"
}

.fa-long-arrow-up:before {
    content: "\f176"
}

.fa-long-arrow-left:before {
    content: "\f177"
}

.fa-long-arrow-right:before {
    content: "\f178"
}

.fa-apple:before {
    content: "\f179"
}

.fa-windows:before {
    content: "\f17a"
}

.fa-android:before {
    content: "\f17b"
}

.fa-linux:before {
    content: "\f17c"
}

.fa-dribbble:before {
    content: "\f17d"
}

.fa-skype:before {
    content: "\f17e"
}

.fa-foursquare:before {
    content: "\f180"
}

.fa-trello:before {
    content: "\f181"
}

.fa-female:before {
    content: "\f182"
}

.fa-male:before {
    content: "\f183"
}

.fa-gittip:before, .fa-gratipay:before {
    content: "\f184"
}

.fa-sun-o:before {
    content: "\f185"
}

.fa-moon-o:before {
    content: "\f186"
}

.fa-archive:before {
    content: "\f187"
}

.fa-bug:before {
    content: "\f188"
}

.fa-vk:before {
    content: "\f189"
}

.fa-weibo:before {
    content: "\f18a"
}

.fa-renren:before {
    content: "\f18b"
}

.fa-pagelines:before {
    content: "\f18c"
}

.fa-stack-exchange:before {
    content: "\f18d"
}

.fa-arrow-circle-o-right:before {
    content: "\f18e"
}

.fa-arrow-circle-o-left:before {
    content: "\f190"
}

.fa-toggle-left:before, .fa-caret-square-o-left:before {
    content: "\f191"
}

.fa-dot-circle-o:before {
    content: "\f192"
}

.fa-wheelchair:before {
    content: "\f193"
}

.fa-vimeo-square:before {
    content: "\f194"
}

.fa-turkish-lira:before, .fa-try:before {
    content: "\f195"
}

.fa-plus-square-o:before {
    content: "\f196"
}

.fa-space-shuttle:before {
    content: "\f197"
}

.fa-slack:before {
    content: "\f198"
}

.fa-envelope-square:before {
    content: "\f199"
}

.fa-wordpress:before {
    content: "\f19a"
}

.fa-openid:before {
    content: "\f19b"
}

.fa-institution:before, .fa-bank:before, .fa-university:before {
    content: "\f19c"
}

.fa-mortar-board:before, .fa-graduation-cap:before {
    content: "\f19d"
}

.fa-yahoo:before {
    content: "\f19e"
}

.fa-google:before {
    content: "\f1a0"
}

.fa-reddit:before {
    content: "\f1a1"
}

.fa-reddit-square:before {
    content: "\f1a2"
}

.fa-stumbleupon-circle:before {
    content: "\f1a3"
}

.fa-stumbleupon:before {
    content: "\f1a4"
}

.fa-delicious:before {
    content: "\f1a5"
}

.fa-digg:before {
    content: "\f1a6"
}

.fa-pied-piper-pp:before {
    content: "\f1a7"
}

.fa-pied-piper-alt:before {
    content: "\f1a8"
}

.fa-drupal:before {
    content: "\f1a9"
}

.fa-joomla:before {
    content: "\f1aa"
}

.fa-language:before {
    content: "\f1ab"
}

.fa-fax:before {
    content: "\f1ac"
}

.fa-building:before {
    content: "\f1ad"
}

.fa-child:before {
    content: "\f1ae"
}

.fa-paw:before {
    content: "\f1b0"
}

.fa-spoon:before {
    content: "\f1b1"
}

.fa-cube:before {
    content: "\f1b2"
}

.fa-cubes:before {
    content: "\f1b3"
}

.fa-behance:before {
    content: "\f1b4"
}

.fa-behance-square:before {
    content: "\f1b5"
}

.fa-steam:before {
    content: "\f1b6"
}

.fa-steam-square:before {
    content: "\f1b7"
}

.fa-recycle:before {
    content: "\f1b8"
}

.fa-automobile:before, .fa-car:before {
    content: "\f1b9"
}

.fa-cab:before, .fa-taxi:before {
    content: "\f1ba"
}

.fa-tree:before {
    content: "\f1bb"
}

.fa-spotify:before {
    content: "\f1bc"
}

.fa-deviantart:before {
    content: "\f1bd"
}

.fa-soundcloud:before {
    content: "\f1be"
}

.fa-database:before {
    content: "\f1c0"
}

.fa-file-pdf-o:before {
    content: "\f1c1"
}

.fa-file-word-o:before {
    content: "\f1c2"
}

.fa-file-excel-o:before {
    content: "\f1c3"
}

.fa-file-powerpoint-o:before {
    content: "\f1c4"
}

.fa-file-photo-o:before, .fa-file-picture-o:before, .fa-file-image-o:before {
    content: "\f1c5"
}

.fa-file-zip-o:before, .fa-file-archive-o:before {
    content: "\f1c6"
}

.fa-file-sound-o:before, .fa-file-audio-o:before {
    content: "\f1c7"
}

.fa-file-movie-o:before, .fa-file-video-o:before {
    content: "\f1c8"
}

.fa-file-code-o:before {
    content: "\f1c9"
}

.fa-vine:before {
    content: "\f1ca"
}

.fa-codepen:before {
    content: "\f1cb"
}

.fa-jsfiddle:before {
    content: "\f1cc"
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-saver:before, .fa-support:before, .fa-life-ring:before {
    content: "\f1cd"
}

.fa-circle-o-notch:before {
    content: "\f1ce"
}

.fa-ra:before, .fa-resistance:before, .fa-rebel:before {
    content: "\f1d0"
}

.fa-ge:before, .fa-empire:before {
    content: "\f1d1"
}

.fa-git-square:before {
    content: "\f1d2"
}

.fa-git:before {
    content: "\f1d3"
}

.fa-y-combinator-square:before, .fa-yc-square:before, .fa-hacker-news:before {
    content: "\f1d4"
}

.fa-tencent-weibo:before {
    content: "\f1d5"
}

.fa-qq:before {
    content: "\f1d6"
}

.fa-wechat:before, .fa-weixin:before {
    content: "\f1d7"
}

.fa-send:before, .fa-paper-plane:before {
    content: "\f1d8"
}

.fa-send-o:before, .fa-paper-plane-o:before {
    content: "\f1d9"
}

.fa-history:before {
    content: "\f1da"
}

.fa-circle-thin:before {
    content: "\f1db"
}

.fa-header:before {
    content: "\f1dc"
}

.fa-paragraph:before {
    content: "\f1dd"
}

.fa-sliders:before {
    content: "\f1de"
}

.fa-share-alt:before {
    content: "\f1e0"
}

.fa-share-alt-square:before {
    content: "\f1e1"
}

.fa-bomb:before {
    content: "\f1e2"
}

.fa-soccer-ball-o:before, .fa-futbol-o:before {
    content: "\f1e3"
}

.fa-tty:before {
    content: "\f1e4"
}

.fa-binoculars:before {
    content: "\f1e5"
}

.fa-plug:before {
    content: "\f1e6"
}

.fa-slideshare:before {
    content: "\f1e7"
}

.fa-twitch:before {
    content: "\f1e8"
}

.fa-yelp:before {
    content: "\f1e9"
}

.fa-newspaper-o:before {
    content: "\f1ea"
}

.fa-wifi:before {
    content: "\f1eb"
}

.fa-calculator:before {
    content: "\f1ec"
}

.fa-paypal:before {
    content: "\f1ed"
}

.fa-google-wallet:before {
    content: "\f1ee"
}

.fa-cc-visa:before {
    content: "\f1f0"
}

.fa-cc-mastercard:before {
    content: "\f1f1"
}

.fa-cc-discover:before {
    content: "\f1f2"
}

.fa-cc-amex:before {
    content: "\f1f3"
}

.fa-cc-paypal:before {
    content: "\f1f4"
}

.fa-cc-stripe:before {
    content: "\f1f5"
}

.fa-bell-slash:before {
    content: "\f1f6"
}

.fa-bell-slash-o:before {
    content: "\f1f7"
}

.fa-trash:before {
    content: "\f1f8"
}

.fa-copyright:before {
    content: "\f1f9"
}

.fa-at:before {
    content: "\f1fa"
}

.fa-eyedropper:before {
    content: "\f1fb"
}

.fa-paint-brush:before {
    content: "\f1fc"
}

.fa-birthday-cake:before {
    content: "\f1fd"
}

.fa-area-chart:before {
    content: "\f1fe"
}

.fa-pie-chart:before {
    content: "\f200"
}

.fa-line-chart:before {
    content: "\f201"
}

.fa-lastfm:before {
    content: "\f202"
}

.fa-lastfm-square:before {
    content: "\f203"
}

.fa-toggle-off:before {
    content: "\f204"
}

.fa-toggle-on:before {
    content: "\f205"
}

.fa-bicycle:before {
    content: "\f206"
}

.fa-bus:before {
    content: "\f207"
}

.fa-ioxhost:before {
    content: "\f208"
}

.fa-angellist:before {
    content: "\f209"
}

.fa-cc:before {
    content: "\f20a"
}

.fa-shekel:before, .fa-sheqel:before, .fa-ils:before {
    content: "\f20b"
}

.fa-meanpath:before {
    content: "\f20c"
}

.fa-buysellads:before {
    content: "\f20d"
}

.fa-connectdevelop:before {
    content: "\f20e"
}

.fa-dashcube:before {
    content: "\f210"
}

.fa-forumbee:before {
    content: "\f211"
}

.fa-leanpub:before {
    content: "\f212"
}

.fa-sellsy:before {
    content: "\f213"
}

.fa-shirtsinbulk:before {
    content: "\f214"
}

.fa-simplybuilt:before {
    content: "\f215"
}

.fa-skyatlas:before {
    content: "\f216"
}

.fa-cart-plus:before {
    content: "\f217"
}

.fa-cart-arrow-down:before {
    content: "\f218"
}

.fa-diamond:before {
    content: "\f219"
}

.fa-ship:before {
    content: "\f21a"
}

.fa-user-secret:before {
    content: "\f21b"
}

.fa-motorcycle:before {
    content: "\f21c"
}

.fa-street-view:before {
    content: "\f21d"
}

.fa-heartbeat:before {
    content: "\f21e"
}

.fa-venus:before {
    content: "\f221"
}

.fa-mars:before {
    content: "\f222"
}

.fa-mercury:before {
    content: "\f223"
}

.fa-intersex:before, .fa-transgender:before {
    content: "\f224"
}

.fa-transgender-alt:before {
    content: "\f225"
}

.fa-venus-double:before {
    content: "\f226"
}

.fa-mars-double:before {
    content: "\f227"
}

.fa-venus-mars:before {
    content: "\f228"
}

.fa-mars-stroke:before {
    content: "\f229"
}

.fa-mars-stroke-v:before {
    content: "\f22a"
}

.fa-mars-stroke-h:before {
    content: "\f22b"
}

.fa-neuter:before {
    content: "\f22c"
}

.fa-genderless:before {
    content: "\f22d"
}

.fa-facebook-official:before {
    content: "\f230"
}

.fa-pinterest-p:before {
    content: "\f231"
}

.fa-whatsapp:before {
    content: "\f232"
}

.fa-server:before {
    content: "\f233"
}

.fa-user-plus:before {
    content: "\f234"
}

.fa-user-times:before {
    content: "\f235"
}

.fa-hotel:before, .fa-bed:before {
    content: "\f236"
}

.fa-viacoin:before {
    content: "\f237"
}

.fa-train:before {
    content: "\f238"
}

.fa-subway:before {
    content: "\f239"
}

.fa-medium:before {
    content: "\f23a"
}

.fa-yc:before, .fa-y-combinator:before {
    content: "\f23b"
}

.fa-optin-monster:before {
    content: "\f23c"
}

.fa-opencart:before {
    content: "\f23d"
}

.fa-expeditedssl:before {
    content: "\f23e"
}

.fa-battery-4:before, .fa-battery-full:before {
    content: "\f240"
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
    content: "\f241"
}

.fa-battery-2:before, .fa-battery-half:before {
    content: "\f242"
}

.fa-battery-1:before, .fa-battery-quarter:before {
    content: "\f243"
}

.fa-battery-0:before, .fa-battery-empty:before {
    content: "\f244"
}

.fa-mouse-pointer:before {
    content: "\f245"
}

.fa-i-cursor:before {
    content: "\f246"
}

.fa-object-group:before {
    content: "\f247"
}

.fa-object-ungroup:before {
    content: "\f248"
}

.fa-sticky-note:before {
    content: "\f249"
}

.fa-sticky-note-o:before {
    content: "\f24a"
}

.fa-cc-jcb:before {
    content: "\f24b"
}

.fa-cc-diners-club:before {
    content: "\f24c"
}

.fa-clone:before {
    content: "\f24d"
}

.fa-balance-scale:before {
    content: "\f24e"
}

.fa-hourglass-o:before {
    content: "\f250"
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
    content: "\f251"
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
    content: "\f252"
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
    content: "\f253"
}

.fa-hourglass:before {
    content: "\f254"
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
    content: "\f255"
}

.fa-hand-stop-o:before, .fa-hand-paper-o:before {
    content: "\f256"
}

.fa-hand-scissors-o:before {
    content: "\f257"
}

.fa-hand-lizard-o:before {
    content: "\f258"
}

.fa-hand-spock-o:before {
    content: "\f259"
}

.fa-hand-pointer-o:before {
    content: "\f25a"
}

.fa-hand-peace-o:before {
    content: "\f25b"
}

.fa-trademark:before {
    content: "\f25c"
}

.fa-registered:before {
    content: "\f25d"
}

.fa-creative-commons:before {
    content: "\f25e"
}

.fa-gg:before {
    content: "\f260"
}

.fa-gg-circle:before {
    content: "\f261"
}

.fa-tripadvisor:before {
    content: "\f262"
}

.fa-odnoklassniki:before {
    content: "\f263"
}

.fa-odnoklassniki-square:before {
    content: "\f264"
}

.fa-get-pocket:before {
    content: "\f265"
}

.fa-wikipedia-w:before {
    content: "\f266"
}

.fa-safari:before {
    content: "\f267"
}

.fa-chrome:before {
    content: "\f268"
}

.fa-firefox:before {
    content: "\f269"
}

.fa-opera:before {
    content: "\f26a"
}

.fa-internet-explorer:before {
    content: "\f26b"
}

.fa-tv:before, .fa-television:before {
    content: "\f26c"
}

.fa-contao:before {
    content: "\f26d"
}

.fa-500px:before {
    content: "\f26e"
}

.fa-amazon:before {
    content: "\f270"
}

.fa-calendar-plus-o:before {
    content: "\f271"
}

.fa-calendar-minus-o:before {
    content: "\f272"
}

.fa-calendar-times-o:before {
    content: "\f273"
}

.fa-calendar-check-o:before {
    content: "\f274"
}

.fa-industry:before {
    content: "\f275"
}

.fa-map-pin:before {
    content: "\f276"
}

.fa-map-signs:before {
    content: "\f277"
}

.fa-map-o:before {
    content: "\f278"
}

.fa-map:before {
    content: "\f279"
}

.fa-commenting:before {
    content: "\f27a"
}

.fa-commenting-o:before {
    content: "\f27b"
}

.fa-houzz:before {
    content: "\f27c"
}

.fa-vimeo:before {
    content: "\f27d"
}

.fa-black-tie:before {
    content: "\f27e"
}

.fa-fonticons:before {
    content: "\f280"
}

.fa-reddit-alien:before {
    content: "\f281"
}

.fa-edge:before {
    content: "\f282"
}

.fa-credit-card-alt:before {
    content: "\f283"
}

.fa-codiepie:before {
    content: "\f284"
}

.fa-modx:before {
    content: "\f285"
}

.fa-fort-awesome:before {
    content: "\f286"
}

.fa-usb:before {
    content: "\f287"
}

.fa-product-hunt:before {
    content: "\f288"
}

.fa-mixcloud:before {
    content: "\f289"
}

.fa-scribd:before {
    content: "\f28a"
}

.fa-pause-circle:before {
    content: "\f28b"
}

.fa-pause-circle-o:before {
    content: "\f28c"
}

.fa-stop-circle:before {
    content: "\f28d"
}

.fa-stop-circle-o:before {
    content: "\f28e"
}

.fa-shopping-bag:before {
    content: "\f290"
}

.fa-shopping-basket:before {
    content: "\f291"
}

.fa-hashtag:before {
    content: "\f292"
}

.fa-bluetooth:before {
    content: "\f293"
}

.fa-bluetooth-b:before {
    content: "\f294"
}

.fa-percent:before {
    content: "\f295"
}

.fa-gitlab:before {
    content: "\f296"
}

.fa-wpbeginner:before {
    content: "\f297"
}

.fa-wpforms:before {
    content: "\f298"
}

.fa-envira:before {
    content: "\f299"
}

.fa-universal-access:before {
    content: "\f29a"
}

.fa-wheelchair-alt:before {
    content: "\f29b"
}

.fa-question-circle-o:before {
    content: "\f29c"
}

.fa-blind:before {
    content: "\f29d"
}

.fa-audio-description:before {
    content: "\f29e"
}

.fa-volume-control-phone:before {
    content: "\f2a0"
}

.fa-braille:before {
    content: "\f2a1"
}

.fa-assistive-listening-systems:before {
    content: "\f2a2"
}

.fa-asl-interpreting:before, .fa-american-sign-language-interpreting:before {
    content: "\f2a3"
}

.fa-deafness:before, .fa-hard-of-hearing:before, .fa-deaf:before {
    content: "\f2a4"
}

.fa-glide:before {
    content: "\f2a5"
}

.fa-glide-g:before {
    content: "\f2a6"
}

.fa-signing:before, .fa-sign-language:before {
    content: "\f2a7"
}

.fa-low-vision:before {
    content: "\f2a8"
}

.fa-viadeo:before {
    content: "\f2a9"
}

.fa-viadeo-square:before {
    content: "\f2aa"
}

.fa-snapchat:before {
    content: "\f2ab"
}

.fa-snapchat-ghost:before {
    content: "\f2ac"
}

.fa-snapchat-square:before {
    content: "\f2ad"
}

.fa-pied-piper:before {
    content: "\f2ae"
}

.fa-first-order:before {
    content: "\f2b0"
}

.fa-yoast:before {
    content: "\f2b1"
}

.fa-themeisle:before {
    content: "\f2b2"
}

.fa-google-plus-circle:before, .fa-google-plus-official:before {
    content: "\f2b3"
}

.fa-fa:before, .fa-font-awesome:before {
    content: "\f2b4"
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

.fa,
blockquote:before,
.flexslider:before,
.flex-direction-nav .flex-next,
.flex-direction-nav .flex-prev,
.tie-list-shortcode ul li:before,
.ei-slider-loading:before,
#mobile-menu li.menu-item-home a:before,
#main-nav .mega-cat-content.mega-cat-sub-exists .mega-cat-content-tab.loading-items:before,
.woocommerce-pagination .page-numbers li .page-numbers.next:before,
.woocommerce-pagination .page-numbers li .page-numbers.prev:before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: none;
}

@font-face {
    font-family: 'tiefontello';
    src: url('builds/fonts/tiefont/fontello.eot');
    src: url('builds/fonts/tiefont/fontello3b05.eot?14434071#iefix') format('embedded-opentype'),
    url('builds/fonts/tiefont/fontello.woff') format('woff'),
    url('builds/fonts/tiefont/fontello.ttf') format('truetype'),
    url('builds/fonts/tiefont/fontello.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: 'tiefontello';
        src: url('builds/fonts/tiefont/fontello.svg') format('svg');
    }
}

[class^="tieicon-"]:before,
[class*=" tieicon-"]:before,
.tie_slider .overlay-icon:before {
    font-family: "tiefontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    min-width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
}

.tieicon-evernote:before {
    content: '\e800';
}

.tieicon-picasa:before {
    content: '\e801';
}

.tieicon-vimeo:before {
    content: '\e802';
}

.tieicon-blogger:before {
    content: '\e803';
}

.tieicon-grooveshark:before {
    content: '\e804';
}

.tieicon-deviantart:before {
    content: '\e805';
}

.tieicon-viadeo:before {
    content: '\e806';
}

.tieicon-forrst:before {
    content: '\e807';
}

.tieicon-fivehundredpx:before {
    content: '\e808';
}

.tieicon-flickr:before {
    content: '\e809';
}

.tieicon-instagram:before {
    content: '\e80a';
}

.tieicon-sun:before {
    content: '\e80b';
}

.tieicon-moon:before {
    content: '\e80d';
}

.tieicon-cloud:before {
    content: '\e80e';
}

.tieicon-clouds:before {
    content: '\e80f';
}

.tieicon-clouds-flash-alt:before {
    content: '\e810';
}

.tieicon-cloud-moon:before {
    content: '\e811';
}

.tieicon-cloud-sun:before {
    content: '\e812';
}

.tieicon-fog:before {
    content: '\e813';
}

.tieicon-hail:before {
    content: '\e814';
}

.tieicon-rain:before {
    content: '\e815';
}

.tieicon-picture:before {
    content: '\e80c';
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    #main-nav ul li.menu-item-home a {
        background-image: url(images/home%402x.png) !important;
        background-size: 30px 78px;
    }

    span.stars-large,
    span.stars-large span {
        background-image: url(images/stars-large%402x.png);
        background-size: 98px 44px;
    }

    span.stars-small,
    span.stars-small span {
        background-image: url(images/stars-small%402x.png);
        background-size: 54px 30px;
    }
}


/**
 * Wrapper Styles
 * -----------------------------------------------------------------------------
 */

.wrapper-outer {
    position: relative;
}

.background-cover {
    position: fixed;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.container {
    width: 1045px;
    margin: 0 auto;
}

/* BOXED LAYOUT */
#wrapper.boxed {
    margin: 0 auto;
}

#wrapper.boxed #theme-header,
#wrapper.boxed .breaking-news,
#wrapper.boxed #main-content {
    width: 1045px;
    -webkit-box-shadow: 0 0 3px #CACACA;
    -moz-box-shadow: 0 0 3px #cacaca;
    box-shadow: 0 0 3px #CACACA;
}

#wrapper.boxed #main-content {
    padding-right: 24px;
    padding-left: 24px;
}

#wrapper.boxed .post-cover-head {
    margin: -24px -24px 20px;
}

/* BOXED ALL LAYOUT */

#wrapper.boxed-all {
    position: relative;
    background: #FFF;
    width: 1045px;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 3px #CACACA;
    -moz-box-shadow: 0 0 3px #cacaca;
    box-shadow: 0 0 3px #CACACA;
}

#wrapper.boxed-all #theme-header {
    margin-top: 0;
}

#wrapper.boxed-all .breaking-news {
    margin-right: 24px;
    margin-left: 24px;
    -webkit-box-shadow: 0 0 2px #CACACA;
    -moz-box-shadow: 0 0 2px #cacaca;
    box-shadow: 0 0 2px #CACACA;
}

#wrapper.boxed-all #main-content {
    width: 997px;
    margin-top: 0;
    padding: 0;
    background: transparent;
}

#wrapper.boxed-all #footer-widget-area,
#wrapper.boxed-all .footer-bottom .container {
    width: 997px;
}

#wrapper.boxed-all .post-cover-head {
    margin: 0 -24px 20px;
}

/* WIDE LAYOUT */

#wrapper.wide-layout {
    background: #FFF;
    position: relative;
}

#wrapper.wide-layout .header-content,
#wrapper.wide-layout .breaking-news,
#wrapper.wide-layout .container,
#wrapper.wide-layout #main-nav ul li.mega-menu .mega-menu-block {
    width: 1240px;
}

#wrapper.wide-layout #theme-header {
    margin-top: 0;
}

#wrapper.wide-layout #main-nav .main-menu {
    margin: 0 !important;
}

#wrapper.wide-layout .breaking-news {
    margin-bottom: 0;
    -webkit-box-shadow: 0 0 1px #CACACA;
    -moz-box-shadow: 0 0 1px #cacaca;
    box-shadow: 0 0 1px #CACACA;
}

#wrapper.wide-layout #main-content {
    /*margin-top: 10px;*/
    background: transparent;
}

#wrapper.wide-layout .post-cover-head {
    margin: -10px 0 20px;
}


/**
 * Header
 * -----------------------------------------------------------------------------
 */

#theme-header {
    background: #FFF;
    margin: auto;
    border-top: 3px solid #f88c00;
    position: relative;
}

/* Top Menu Styles */

.top-nav {
    background: #fbfbfb;
    border-bottom: 1px solid #ececec;
    height: 31px;
}

.top-nav ul {
    float: left;
}

.top-nav ul li {
    position: relative;
    display: inline-block;
    float: left;
    font-size: 12px;
}

.top-nav ul li a {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    color: #838383;
    padding: 0 10px;
}

.top-nav ul li a:hover {
    color: #000;
}

.top-nav ul ul {
    background: #fbfbfb;
    display: none;
    padding: 0;
    position: absolute;
    top: 30px;
    width: 180px;
    z-index: 200;
    float: left;

    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.top-nav ul ul li {
    background: none !important;
    border: none !important;
    z-index: 200;
    min-width: 180px;
}

.top-nav ul ul ul {
    right: auto;
    left: 100%;
    top: 0;
    z-index: 200;
}

.top-nav ul ul a {
    background: none !important;
    height: auto !important;
    line-height: 1em;
    padding: 10px 10px;
    width: 160px;
    display: block !important;
    margin-right: 0 !important;
    z-index: 200;
    color: #777 !important;
}

.top-nav ul li:hover > a,
.top-nav ul:hover > a,
.top-nav ul li.current-menu-item a {
    color: #000;
}

.top-nav ul li.menu-item-has-children a {
    padding-right: 20px;
}

/* SubList Arrow */

#main-nav ul li.menu-item-has-children:after,
#main-nav ul li.mega-menu:after,
.top-menu ul li.menu-item-has-children:after {
    position: absolute;
    right: 7px;
    top: 50%;
    display: inline-block;
    content: '';
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top: 4px solid #bbb;
}

#main-nav ul ul li.menu-item-has-children:after,
#main-nav ul ul li.mega-menu:after,
.top-nav .top-menu ul ul li.menu-item-has-children:after {
    border: 4px solid transparent;
    border-left-color: #bbb;
}

.top-nav ul li.current-menu-item:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    z-index: 2;
    right: 45%;
    border: 4px solid transparent;
    border-top-color: #F88C00;
}

.top-nav ul ul li.current-menu-item:before {
    display: none;
}

.top-nav ul ul li:hover > a,
.top-nav ul ul:hover > a {
    color: #000 !important;
    padding: 10px 5px 10px 15px;
}

.top-nav ul li:hover > ul {
    display: block;

    -webkit-animation: tieFadeInDown .1s ease-in;
    -moz-animation: tieFadeInDown .1s ease-in;
    animation: tieFadeInDown .1s ease-in;
}

/* Today Date */

.today-date {
    float: left;
    padding: 0 10px;
    font-size: 10px;
    color: #666;
    margin: 10px 10px 0 0;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #CCC;
    border-width: 0 1px 0 0;
}

/* Search */

.search-block {
    height: 30px;
    overflow: hidden;
    float: right;
    margin-left: 5px;
    position: relative;
}

.search-block:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 10px;
    z-index: 2;
    right: 30px;
    border: 4px solid transparent;
    border-right-color: #F88C00;
}

.search-block #s-header {
    background: #FFF;
    float: right;
    font: normal 11px tahoma;
    padding: 9px 12px 8px 12px;
    width: 90px;
    color: #C5C5C5;
    border: 0 none;
    border: 1px solid #ececec;
    border-width: 0 0 0 1px;
    -webkit-transition: width .7s, color .4s;
    -moz-transition: width .7s, color .4s;
    -o-transition: width .7s, color .4s;
    transition: width .7s, color .4s;

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.search-block #s-header:focus {
    color: #000;
    width: 150px;
}

.search-block .search-button {
    background: #f88c00;
    cursor: pointer;
    float: right;
    height: 30px;
    width: 30px;
    display: block;
    border: 0 none;
    box-shadow: none !important;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.search-block .search-button:active {
    top: 0;
}

.search-block:hover .search-button,
.search-block:hover #s-header {
    opacity: 1;
    color: #444;
}

.search-block button.search-button i:before {
    font-size: 18px;
    color: #FFF;
}

/* LIVE SEARCH */

#live-search_results {
    background: #FFF;
    z-index: 550 !important;
    overflow: hidden;
    padding: 10px;
    -webkit-box-shadow: 0 0 3px #CACACA;
    -moz-box-shadow: 0 0 3px #cacaca;
    box-shadow: 0 0 3px #CACACA;
}

.live-search_more {
    display: block;
    min-height: 1px;
    margin: 3px 0 0px;
    padding: 8px;
    text-align: center;
    font-weight: bold;
    line-height: 12px;
}

.live-search_header {
    display: none;
}

.live-search_result_container li {
    padding: 8px 0 4px;
    border-bottom: 1px solid #f2f2f2;
    overflow: hidden;
}

.live-search_result_container .post-thumbnail {
    float: left;
    margin-bottom: 0;
}

.live-search_result_container .post-thumbnail img {
    max-width: 65px;
}

#live-search_loading {
    display: block !important;
    width: 100%;
    height: 31px !important;
}

/* WPML */
#main-nav .submenu-languages {
    display: none;
    padding: 0;
    position: absolute;
    z-index: 205;
    border-top: 2px solid #F88C00;
    background: #2d2d2d;
    color: #999;
    -webkit-box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

#main-nav .menu-item-language:hover .submenu-languages {
    display: block;
}

#lang_sel_footer {
    position: relative;
    z-index: 998;
}


/* WPML language switcher */
#tie_lang_switcher {
    float: right;
    padding: 9px 5px 0;
}

#tie_lang_switcher img {
    margin: 0 2px;
}

#tie_lang_switcher a {
    opacity: 0.4;
    line-height: 0;
}

#tie_lang_switcher a:hover {
    opacity: 1;
}

/* Top Nav Social */

.top-nav .social-icons {
    float: right;
    padding-top: 2px;
}

.top-nav .social-icons a {
    color: #555;
}

.top-nav .social-icons a,
.footer-bottom .social-icons a {
    opacity: 0.5;
}

.top-nav .social-icons a:hover,
.footer-bottom .social-icons a:hover {
    top: 0;
    opacity: 1
}

.top-nav .social-icons i:before,
.footer-bottom .social-icons i:before {
    padding: 0 2px;
    line-height: 24px;
    font-size: 14px;
}

/* Header Content area  */

.header-content {
    padding: 20px 15px;
    margin: 0 auto;
}

.logo {
    float: left;
}

.logo img {
    max-width: 100%;
    max-height: 180px;
}

.logo h1,
.logo h2 {
    font: bold 32pt arial;
}

.logo strong {
    display: none;
}

.logo span {
    font-weight: normal;
    font-size: 14px;
    display: block;
    clear: both;
}

/* Full Width Logo */

#theme-header.full-logo .header-content {
    padding: 0;
}

#theme-header.full-logo .logo {
    float: none;
    margin: 0 !important;
}

#theme-header.full-logo .logo a {
    display: block;
    line-height: 0;
}

#theme-header.full-logo .logo img {
    width: 100%;
    height: auto;
}

/* Center The Logo */

#theme-header.center-logo .logo,
#theme-header.center-logo .e3lan-top {
    float: none;
    text-align: center;
}

/* Main Nav  */

#main-nav {
    margin: 0 auto;
    background: #2d2d2d;
    box-shadow: inset -1px -5px 0px -1px #393939;
    min-height: 52px;
    border-bottom: 5px solid #F88C00;
}

#main-nav .container,
#main-nav-uber .container {
    position: relative;
}

#main-nav .main-menu {
    margin-left: 10px;
}

#main-nav ul li {
    font-size: 13px;
    position: relative;
    display: inline-block;
    float: left;
    border: 1px solid #222222;
    height: 42px;

}

#menu-main-menu > li:not(:first-child) {
    padding: 0 30px;
}

#main-nav ul li:first-child,
#main-nav ul li:first-child a {
    border: 0 none;
}

#main-nav ul li:last-child a {
    border-right-width: 0;
}

#main-nav ul li a {
    display: block;
    height: 42px;
    position: relative;
    line-height: 47px;
    color: #ddd;
    padding: 0 10px;
}

#main-nav ul li i.fa,
#slide-out ul li i.fa,
#mobile-menu li.menu-item-home a:before {
    margin-right: 5px;
}

#main-nav ul li.menu-item-has-children a,
#main-nav ul li.mega-menu a {
    padding-right: 20px;
}

#main-nav ul li.current-menu-item a,
#main-nav ul li.current-menu-item a:hover,
#main-nav ul li.current-menu-parent a,
#main-nav ul li.current-menu-parent a:hover,
#main-nav ul li.current_page_parent a,
#main-nav ul li.current_page_parent a:hover,
#main-nav ul li.current-page-ancestor a,
#main-nav ul li.current-page-ancestor a:hover {
    background: #F88C00;
    color: #FFF;
    height: 52px;
    line-height: 57px;
    border-width: 0 !important;
}

#main-nav ul li.current-menu-item .menu-sub-content a,
#main-nav ul li.current-menu-item .menu-sub-content a:hover,
#main-nav ul li.current-menu-parent .menu-sub-content a,
#main-nav ul li.current-menu-parent .menu-sub-content a:hover,
#main-nav ul li.current_page_parent .menu-sub-content a,
#main-nav ul li.current_page_parent .menu-sub-content a:hover,
#main-nav ul li.current-page-ancestor .menu-sub-content a,
#main-nav ul li.current-page-ancestor .menu-sub-content a:hover,
#main-nav ul li.current-menu-item .menu-sub-content ul,
#main-nav ul li.current-menu-parent .menu-sub-content ul,
#main-nav ul li.current_page_parent .menu-sub-content ul,
#main-nav ul li.current-page-ancestor .menu-sub-content ul {
    top: 0;
}

/* SubList Arrow */

#main-nav ul li.menu-item-has-children:after,
#main-nav ul li.mega-menu:after {
    border-top-color: #ddd;
}

#main-nav ul ul li.menu-item-has-children:after {
    border-left-color: #ddd;
}

#main-nav ul > li.menu-item-has-children:hover > a:after,
#main-nav ul > li.mega-menu:hover > a:after {
    content: "";
    position: absolute;
    left: 20px;
    top: 36px;
    border-width: 0 8px 8px;
    border-style: solid;
    border-color: transparent transparent #F88C00;
    display: block;
    width: 0;
    z-index: 200;
    -webkit-animation: tieFadeInDown .2s ease-in;
    -moz-animation: tieFadeInDown .2s ease-in;
    animation: tieFadeInDown .2s ease-in;
}

#main-nav ul ul li.current-menu-item a,
#main-nav ul ul li.current-menu-item a:hover,
#main-nav ul ul li.current-menu-parent a,
#main-nav ul ul li.current-menu-parent a:hover,
#main-nav ul ul li.current_page_parent a,
#main-nav ul ul li.current_page_parent a:hover,
#main-nav ul ul li.current-page-ancestor a,
#main-nav ul ul li.current-page-ancestor a:hover {
    border-width: 1px !important;
}

/* Remove Arrows from the Sub Sub Menus */

#main-nav ul ul > li.menu-item-has-children:hover > a:after,
#main-nav ul ul > li.mega-menu:hover > a:after,
#main-nav ul li .mega-menu-block ul.sub-menu:before {
    display: none;
}

#main-nav ul > li.current-menu-item.menu-item-has-children:hover > a:after,
#main-nav ul > li.current_page_parent.menu-item-has-children:hover > a:after {
    top: 45px;
}

#main-nav ul ul.sub-menu {
    width: 250px;
    z-index: 250;
}

#main-nav ul ul ul.sub-menu,
#main-nav ul ul li.current-menu-item ul,
#main-nav ul ul li.current_page_parent ul,
#main-nav ul ul li.current-menu-parent ul,
#main-nav ul ul li.current-page-ancestor ul {
    top: -2px;
}

#main-nav ul .mega-menu-block ul ul.sub-menu,
#main-nav ul .mega-menu-block ul li.current-menu-item ul,
#main-nav ul .mega-menu-block ul li.current_page_parent ul,
#main-nav ul .mega-menu-block ul li.current-menu-parent ul,
#main-nav ul .mega-menu-block ul li.current-page-ancestor ul {
    top: 0;
}

#main-nav .menu-sub-content {
    display: none;
    padding: 0;
    position: absolute;
    z-index: 205;
    border-top: 2px solid #F88C00;
    background: #2d2d2d;
    color: #999;
    -webkit-box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

}

#main-nav ul li.current-menu-item ul,
#main-nav ul li.current-menu-parent ul,
#main-nav ul li.current_page_parent ul,
#main-nav ul li.current-page-ancestor ul {
    top: 52px;
}

#main-nav ul ul li,
#main-nav ul ul li:first-child {
    background: none !important;
    width: 100%;
    border: 0 none;
    border-bottom: 1px solid #333;
    font-size: 12px;
    height: auto !important;
    margin: 0 !important;
}

#main-nav ul ul ul,
#main-nav ul li .mega-menu-block ul.sub-menu {
    border-top: 0 none;
}

#main-nav ul ul li:first-child,
#main-nav ul li.current-menu-item ul li:first-child,
#main-nav ul li.current_page_parent ul li:first-child,
#main-nav ul li.current-menu-parent ul li:first-child {
    border-top: 0 none !important;
}

#main-nav ul ul ul {
    right: auto;
    left: 100%;
    top: 0;
    z-index: 210;
}

#main-nav ul ul a,
#main-nav .mega-menu-block a {
    border: 0 none;
    background: none !important;
    height: auto !important;
    line-height: 1.5em !important;
    padding: 7px;
    display: block;
    margin-right: 0;
    z-index: 210;
    color: #ccc;
}

#main-nav .mega-menu-block a.mega-menu-link {
    padding: 0;
    margin-top: 10px;
}

#main-nav ul li.current-menu-item ul a,
#main-nav ul li.current_page_parent ul a,
#main-nav ul li.current-menu-parent ul a,
#main-nav ul li.current-page-ancestor ul a {
    color: #eee;
}

/* Top Level links */

#main-nav ul li:hover > a,
#main-nav ul:hover > a {
    color: #FFF;
}

/* Sub Level links */

#main-nav ul ul li:hover > a,
#main-nav ul ul:hover > a {
    color: #FFF;
    padding-left: 15px;
    padding-right: 5px;
}

#main-nav ul li:hover > ul,
#main-nav ul li:hover > .mega-menu-block {
    display: block;

    -webkit-animation: tieFadeInDown .2s ease-in;
    -moz-animation: tieFadeInDown .2s ease-in;
    animation: tieFadeInDown .2s ease-in;
}

#main-nav ul li.current-menu-item,
#main-nav ul li.current_page_parent,
#main-nav ul li.current-menu-parent,
#main-nav ul li.current-page-ancestor {
    margin-top: -5px;
    height: 52px;
    border-width: 0;
}

/* Home Icon */

#main-nav ul li.menu-item-home a {
    background-image: url(/builds/images/home.png) !important;
    background-repeat: no-repeat !important;
    background-position: center -44px;
    text-indent: -9999px;
    width: 52px;
}

#main-nav ul li.menu-item-home.current-menu-item a {
    background-position: center 13px;
    height: 52px;
}

#main-nav ul li.menu-item-home:after {
    display: none;
}

#main-nav ul li.menu-item-home ul li a,
#main-nav ul ul li.menu-item-home a,
#main-nav ul li.menu-item-home ul li a:hover {
    background-color: transparent !important;
    text-indent: 0;
    background-image: none !important;
    height: auto !important;
    width: auto;
}

/* Mega Menus */

#main-nav ul li.mega-menu .mega-menu-block {
    width: 1045px;
    left: -10px;
    padding: 25px 25px 5px;
}

#main-nav ul li.mega-menu .mega-menu-block img {
    max-width: 100%;
    height: auto;
}

#main-nav ul li.mega-menu .post-thumbnail,
#main-nav ul li.mega-menu .post-thumbnail a,
#main-nav ul li .mega-menu-block .tie-date i.fa {
    margin: 0;
    padding: 0;
}


/* Mega Links */

#main-nav ul li.mega-menu.mega-links .mega-menu-block {
    padding-right: 0;
}

#main-nav ul li.mega-menu.mega-links .mega-menu-block .sub-menu-columns-item {
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
}

#main-nav li.mega-menu.mega-links .mega-menu-block ul li.mega-link-column {
    float: left;
    border: 0 none;
    margin-bottom: 10px;
    padding-right: 25px;
    width: 50%;
}

#main-nav li.mega-menu.mega-links .mega-menu-block ul li.mega-link-column:after {
    display: none;
}

#main-nav ul li.mega-menu.mega-links a.mega-links-head {
    padding: 10px 0;
    text-align: center;
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Mega Links 3 columns */

#main-nav li.mega-menu.mega-links.mega-links-3col .mega-menu-block ul li.mega-link-column {
    width: 33.333%;
}

/* Mega Links 4 columns */

#main-nav li.mega-menu.mega-links.mega-links-4col .mega-menu-block ul li.mega-link-column {
    width: 25%;
}

/* Mega Links 5 columns */

#main-nav li.mega-menu.mega-links.mega-links-5col .mega-menu-block ul li.mega-link-column {
    width: 20%;
}

/* Mega Categories */

#main-nav ul li.mega-menu.mega-cat .mega-menu-block {
    padding: 0;
}

#main-nav .mega-cat-wrapper {
    clear: both;
}

#main-nav ul ul.sub-menu.mega-cat-more-links {
    width: 100%;
    margin: 10px 0;
}

#main-nav ul li.mega-menu.mega-cat .mega-cat-more-links li {
    float: left;
    width: 29.333333%;
    margin: 0 2% !important;
}

#main-nav ul li.mega-menu.mega-cat .mega-cat-more-links li ul li {
    float: none;
    width: 100%;
    margin: 0 !important;
}

.mega-cat-sub-categories {
    float: left;
    width: 20%;
    padding-top: 20px;
}

#main-nav ul li.cat-active a {
    background: #222 !important;
}

#main-nav .mega-cat-content-tab {
    display: none;
    overflow: hidden;
}

#main-nav .mega-cat-content-tab a {
    cursor: default;
}

#main-nav .mega-cat-content-tab.already-loaded a {
    cursor: pointer;
}

#main-nav .mega-cat-content {
    float: left;
    padding: 20px;
    padding-right: 0;
}

#main-nav .mega-cat-content.mega-cat-sub-exists {
    background: #222;
    width: 80%;
    position: relative;

}

#main-nav ul li.mega-menu.mega-cat .mega-menu-block .mega-menu-post {
    float: left;
    padding-right: 20px;
    width: 25%;
    opacity: 0;
    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
}

#main-nav ul li.mega-menu.mega-cat .mega-menu-block .already-loaded .mega-menu-post {
    opacity: 1;
}

#main-nav ul li.mega-menu.mega-cat .mega-menu-block .mega-menu-post a {
    border: 0 none;
    padding: 0;
}

/* Clear */

#main-nav ul ul.sub-menu.mega-cat-more-links:after {
    content: ' ';
    display: block;
    clear: both;
}


/* Mega Featured - Recent */

#main-nav .mega-recent-featured-list {
    width: 25%;
    margin-right: 3%;
    float: left;
}

#main-nav .mega-recent-post {
    float: left;
    width: 37%;
    margin-right: 3%;
}

#main-nav ul li.mega-menu.mega-recent-featured .mega-menu-block .mega-recent-post a {
    border: 0 none;
    padding: 0;
}

#main-nav .mega-check-also {
    float: left;
    width: 60%;
}

#main-nav ul .mega-check-also li {
    width: 50% !important;
    float: left !important;
    padding: 8px 0 !important;
    margin-bottom: 8px !important;
}

#main-nav ul .mega-check-also li:nth-child(2n+3) {
    clear: left;
}

#main-nav .menu-item-has-children .mega-check-also {
    width: 32%;
}

#main-nav ul .menu-item-has-children .mega-check-also li {
    width: 100% !important;
    float: none !important;
}

#main-nav .mega-menu-block .mega-check-also a.mega-menu-link {
    display: inline;
    margin-top: 0;
}

#main-nav ul li.mega-menu .mega-menu-block .mega-check-also .post-thumbnail {
    margin-right: 10px;
    float: left;
}

#main-nav ul li.mega-menu .mega-menu-block .mega-check-also .post-thumbnail img {
    max-width: 90px;
    height: auto;
}


/* Sticky Menu */

.fixed-nav {
    position: fixed;
    top: -90px;
    width: 1045px;
    z-index: 9999;
    opacity: 0.95;
    -webkit-transition: top .5s;
    -moz-transition: top .5s;
    -o-transition: top .5s;
    transition: top .5s;

    -webkit-box-shadow: 0 5px 3px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 5px 3px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 3px rgba(0, 0, 0, .1);
}

.fixed-nav-appear {
    top: 0;
}

.wide-layout #main-nav.fixed-nav {
    width: 100% !important;
}

body.admin-bar .fixed-nav-appear {
    top: 32px;
}

.main-nav-logo {
    padding: 10px 10px 0;
    float: left;
    display: none;
    opacity: 0.8;
}

.main-nav-logo:hover {
    opacity: 1;
}

.main-nav-logo img {
    height: 27px;
    width: auto;
}

.fixed-nav .main-nav-logo {
    display: block;
}


/* Random Article Button */

a.random-article,
a.tie-cart {
    background: #494949;
    width: 42px;
    height: 47px;
    display: block;
    float: right;
    margin-left: 1px;
    color: #FFF;
    text-align: center;
    position: relative;
}

a.random-article i:before,
a.tie-cart i:before {
    font-size: 18px;
    width: 42px;
    line-height: 50px;
}

a.random-article:hover,
a.tie-cart:hover {
    background-color: #777;
}

a.tie-cart span.shooping-count {
    display: block;
    position: absolute;
    background: #F88C00;
    top: 5px;
    right: 4px;
    padding: 1px 5px;
    font-size: 11px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

#main-nav-uber a.random-article,
#main-nav-uber a.tie-cart {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    height: 40px;
    z-index: 999;
}

#main-nav-uber a.random-article:before,
#main-nav-uber a.tie-cart:before {
    line-height: 40px;
}


/**
 * Breaking News
 * -----------------------------------------------------------------------------
 */

.breaking-news {
    background: #FFF;
    height: 32px;
    margin: -5px auto 25px;
    overflow: hidden;
    position: relative;
}

.breaking-news span.breaking-news-title {
    background: #F43D2A;
    display: block;
    float: left;
    padding: 8px 10px 0;
    height: 32px;
    color: #FFF;
    font-family: BebasNeueRegular, arial, Georgia, serif;
    font-size: 14pt;
}

.breaking-news span.breaking-news-title i {
    display: none;
}

.breaking-news ul {
    float: left;
}

.breaking-news ul li {
    display: block
}

.breaking-news ul a {
    padding: 8px;
    display: block;
    white-space: nowrap;
    font-family: tahoma;
    background: #FFF;
}

.tickercontainer {
    height: 32px;
    overflow: hidden;
}

.tickercontainer .mask {
    position: relative;
    top: 9px;
    overflow: hidden;
}

ul.newsticker {
    position: relative;
    left: 750px;
    list-style-type: none;
}

ul.newsticker li {
    padding: 0;
    display: inline-block;
}

ul.newsticker a {
    white-space: nowrap;
    padding: 0;
    margin: 0 50px 0 0;
}

/**
 * Site Content Area
 * -----------------------------------------------------------------------------
 */

#main-content {
    background: #FFF;
    margin: auto;
    position: relative;
    /*padding: 24px 0;*/
    -webkit-transform: none;
}

#main-content:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ".";
}

.full-width {
    background-image: none;
}

.content {
    float: left;
    width: calc(100% - 350px);
}

#crumbs,
.woocommerce-breadcrumb {
    margin-bottom: 10px;
    clear: both;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
}

#crumbs span.delimiter {
    padding: 0 3px;
}

div.not-found {
    padding: 20px;
}

.full-width .content,
body.error404 .content {
    width: auto;
    float: none;
}

.tie-hide,
.lazy-enabled #main-content .post-thumbnail,
.lazy-enabled #theme-footer div.post-thumbnail,
.lazy-enabled #main-content img,
.lazy-enabled #featured-posts {
    opacity: 0;
}

.lazy-enabled #main-content .post-thumbnail.tie-appear,
.lazy-enabled #theme-footer div.post-thumbnail.tie-appear,
.lazy-enabled #main-content img.tie-appear,
.lazy-enabled #featured-posts.tie-appear {
    opacity: 1;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.recaptchatable #recaptcha_image #recaptcha_challenge_image { /* Fix Compatibility issues with some plugins */
    opacity: 1 !important;
}

/* 404 Page */

body.error404 .content .entry,
body.error404 .content .post-title,
body.error404 .title-404 {
    text-align: center;
}

body.error404 .title-404 {
    font-size: 200px;
    font-weight: bold;
    color: #eee;

}

body.error404 .post-title {
    font-size: 50px;
}


/**
 * Homepage Blocks
 * -----------------------------------------------------------------------------
 */

.cat-box {
    margin-bottom: 35px;
}

.cat-box-title {
    padding: 3px 0;
    overflow: hidden;
}

.cat-box-title h2 {
    float: left;
    margin-right: 10px;
    font-size: 22px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
}

.cat-box-title h2 a {
    color: #111;
}

.cat-box-title h2 a:hover {
    color: #000;
}

h2.post-box-title {
    font-size: 20px;
    line-height: 26px;
}

h3.post-box-title {
    font-size: 14px;
    line-height: 20px;
}

.cat-box p.post-meta {
    margin: 5px 0 0;
    font-size: 80%;
}

.cat-box p.post-meta span,
.cat-box p.post-meta .stars-small {
    margin-right: 7px;
}

li.first-news p.post-meta,
.list-box li.first-news p.post-meta,
.wide-box li.first-news p.post-meta,
.cat-tabs-wrap li.first-news p.post-meta {
    padding: 0;
    margin: 5px 0 10px;
    font-size: 85%;
}

.stripe-line {
    background: url(/builds/images/stripe.png);
    height: 12px;
    overflow: hidden;
    margin-top: 5px;
}

.cat-box .entry {
    font-size: 12px;
}

.cat-box .entry p,
.archive-box .entry p {
    line-height: 21px;
    margin-bottom: 12px;
}

.cat-box .entry a.more-link,
.archive-box .entry a.more-link {
    margin-top: 0;
}

.cat-box-content,
#sidebar .widget-container,
.post-listing,
#commentform {
    background: #FFF;
    border-bottom: 4px solid #F88C00;
    -webkit-box-shadow: 0 0 3px #CACACA;
    -moz-box-shadow: 0 0 3px #cacaca;
    box-shadow: 0 0 3px #CACACA;
}

.cat-box li.first-news .inner-content {
    padding: 20px;
    overflow: hidden;
}

.cat-box .post-thumbnail img.attachment-tie-small {
    max-width: 90px;
    height: auto;
}

p.post-meta {
    color: #888;
    margin: 7px 0;
    font-size: 85%;
}

p.post-meta a {
    color: #888;
}

p.post-meta a:hover {
    border-bottom: 1px #000 dotted;
    color: #000;
    text-decoration: none;
}

.post-meta .stars-small {
    float: left;
    margin: -1px 10px 0 0;
}

span.post-views-widget i:before,
span.post-comments-widget i:before,
span.tie-date i:before,
.post-meta i:before {
    margin-right: 3px;
}

a.more-link {
    color: #FFF !important;
    float: left;
    margin-top: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
}

/* Two columns Block */

.cat-box.column2 {
    float: left;
    width: 48%;
    margin-right: 4%;
}

.column2.last-column {
    margin-left: 0;
    margin-right: 0;
}

.column2 li.other-news,
.list-box li.other-news {
    background: #f7f7f7;
    overflow: hidden;
    padding: 17px 20px;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #eaeaea;
    -webkit-transition: all .40s;
    -moz-transition: all .40s;
    -o-transition: all .40s;
    transition: all .40s;
}

.column2 li.other-news {
    padding-top: 10px;
    padding-bottom: 10px;
}

.column2 li.other-news:hover,
.list-box li.other-news:hover {
    background: #f2f2f2;
}

.column2 li.first-news {
    border-bottom: 3px solid #eaeaea;
    padding: 0;
}

/* Wide Bloxk */

.wide-box li {
    overflow: hidden;
    padding: 10px 20px 0;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #eaeaea;
    width: 50%;
    float: left;
    height: 78px;
    -webkit-transition: all .40s;
    -moz-transition: all .40s;
    -o-transition: all .40s;
    transition: all .40s;
}

.wide-box ul {
    overflow: hidden;
}

.wide-box li.first-news {
    border-bottom: 3px solid #eaeaea;
    padding: 0;
    width: 100%;
    float: none;
    height: auto;
}

.wide-box li.other-news {
    background: #f7f7f7;
}

/*.wide-box h3.post-box-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}*/


/* Video Block */

.video-box li {
    overflow: hidden;
    width: 22%;
    float: right;
    margin-bottom: 9px;
}

.video-box .big-video-column {
    width: 76.5%;
    float: left;
    margin-bottom: 0;
}

.video-box .cat-box-content {
    padding: 20px 20px 11px;
}

.video-box.cat-box li .post-thumbnail {
    margin: 0;
    padding: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.video-box.cat-box li .overlay-icon:before {
    content: "\f04b";
    opacity: 0.6;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.video-box.cat-box li.big-video-column .overlay-icon:before {
    width: 70px;
    height: 70px;
    font-size: 35px;
    line-height: 60px;
    border-width: 5px;
    margin: -35px 0 0 -35px;
}

/* list-box */

.list-box,
.wide-box {
    clear: both;
}

.list-box li {
    overflow: hidden;
    padding: 10px;
    width: 50%;
    float: right;
    clear: right;
}

.list-box li.other-news {
    border-left: 3px solid #eaeaea;
}


.list-box li.first-news {
    padding: 15px;
    float: left;
}

/* News In Pictures */

.pic-box .cat-box-content {
    padding: 20px 20px 13px;
}

.pic-box.pic-grid .cat-box-content {
    padding: 20px;
}

.pic-box li.first-pic {
    padding: 0;
    width: 50.94%;
}

.pic-box li {
    width: 16.3%;
    overflow: hidden;
    float: left;
}

.pic-box.cat-box li.first-pic .post-thumbnail,
.pic-box.pic-grid.cat-box li .post-thumbnail {
    position: relative;
    float: none;
}

.pic-box.pic-grid.cat-box li {
    width: 16.65%;
}

.pic-box.cat-box li .post-thumbnail img.attachment-tie-small {
    max-width: 100%;
}

.pic-box.cat-box li .post-thumbnail {
    padding: 1px;
    margin: 0;
}

/* Tabbed Categories */

.cat-tabs-wrap {
    padding: 0 20px 20px;
}

.cat-tabs-header {
    background: #F7F7F7;
    height: 46px;
    border-bottom: 3px solid #EAEAEA;
    padding: 8px 10px 0;
}

.cat-tabs-header li {
    float: left;
    margin-right: 10px;
    line-height: 30px;
    padding: 4px 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-topright: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.cat-tabs-header li.active {
    background: #FFF;
    box-shadow: 0 -1px 2px #d4d4d4;
    -moz-box-shadow: 0 -1px 2px #d4d4d4;
    -webkit-box-shadow: 0 -1px 2px #d4d4d4;
}

.cat-tabs-wrap li {
    overflow: hidden;
    padding: 12px 0;
    width: 47%;
    float: right;
    clear: right;
}

.cat-tabs-wrap li.first-news {
    width: 50%;
    float: left;
    padding-bottom: 0;
}

.cat-tabs-wrap li:last-child {
    padding-bottom: 0;
}

/* Scrolling Box */

.scroll-box {
    clear: both;
}

.scroll-box .cat-box-content {
    padding: 20px;
    position: relative;
}

.scroll-box .scroll-item .post-thumbnail {
    margin: 0 0 10px 0;
}

.group_items {
    width: 100%;
    background: #fff !important;
}

.group_items-box {
    width: 100% !important;
    overflow: hidden;
}

.group_items .scroll-item:last-child,
.scroll-box .scroll-item:nth-child(3) {
    margin-right: 0;
}

.scroll-item {
    display: none;
}

.scroll-item:nth-child(1),
.scroll-item:nth-child(2),
.scroll-item:nth-child(3),
.group_items .scroll-item {
    display: block;
}

.scroll-nav {
    background: #F7F7F7;
    margin: 10px -20px -20px;
    padding: 5px;
    text-align: center;
}

.scroll-nav a {
    background: #b4b4b4;
    width: 10px;
    height: 10px;
    text-indent: -9999px;
    overflow: hidden;
    display: inline-block;
    margin: 2px 2px 0;
    -webkit-transition: all .40s;
    -moz-transition: all .40s;
    -o-transition: all .40s;
    transition: all .40s;

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.scroll-nav a:hover {
    background: #939393;
}

.scroll-nav a.activeSlide {
    background: #666;
}

/* Recent Posts Box */

.recent-box {
    clear: both;
}

.recent-box .cat-box-content {
    padding: 20px;
    position: relative;
}

.cat-box.recent-box.recent-full_thumb .cat-box-content,
.cat-box.recent-box.recent-blog .cat-box-content {
    padding-top: 0;
}

.recent-masonry .cat-box-content {
    padding: 0;
}

.recent-box .recent-item,
.scroll-box .scroll-item {
    float: left;
    width: 31.33333333%;
    margin: 0 3% 10px 0;
    height: 190px;
}

.recent-box .recent-item:nth-child(3n+3) {
    margin-right: 0;
}

.recent-box .item-list {
    padding: 20px 0;
}

.recent-box-pagination {
    position: relative;
    margin: -35px 0 35px;
}

.recent-box-pagination .pagination {
    margin: 0;
}

/* Padding */

.tie-padding {
    display: inline-block;
}

/* Divider */

.divider {
    border-top: 1px solid #CCC;
    overflow: hidden;
    margin: 10px 0 10px;
    clear: both;
}

.divider.divider-solid {
    border-top-width: thick;
    border-style: solid;
}

.divider.divider-dashed {
    border-top-width: 2px;
    border-style: dashed;
}

.divider.divider-dotted {
    border-top-width: 2px;
    border-style: dotted;
}

.divider.divider-double {
    height: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

/* WooCommerce Box */

.cat-box.woocommerce.woocommerce-box .cat-box-content {
    padding: 20px 20px 0;
}

.cat-box.woocommerce .products {
    margin: 0;
}

.cat-box.woocommerce ul.products li.product {
    margin-bottom: 20px;
}

.cat-box.scroll-box.woocommerce ul.products li.product,
.cat-box.scroll-box.woocommerce .group_items,
.cat-box.scroll-box.woocommerce .group_items-box {
    height: 355px;
}

.cat-box.woocommerce ul.products li.product .price,
.cat-box.woocommerce ul.products li.product .star-rating {
    margin: 5px 0;
}


/**
 * Post & Pages Styles
 * -----------------------------------------------------------------------------
 */

.page-head {
    padding: 3px 0;
    overflow: hidden;
}

.page-head .stripe-line {
    margin-top: 7px;
}

.page-title {
    float: left;
    margin-right: 10px;
    font-size: 26px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
    color: #000;
}

a.rss-cat-icon {
    float: right;
    font-size: 14px;
    margin: 6px 0 0 4px;
    display: block;
    color: #f8bc2e;
}

.archive-meta {
    padding: 15px 0;
}

/* Author Page Styles */

.cat-box-content.author-cover {
    border-bottom: 0 none;
    line-height: 0;
    z-index: 0;
    min-height: 100px;
}

.author-cover-head {
    margin: -55px auto 45px;
    padding: 20px;
    max-width: 90%;
    position: relative;
    z-index: 1;
}

.author-cover-head .author-avatar img {
    max-width: 70px;
}

.page-head .author-bio {
    padding: 10px;
    margin-bottom: 15px;
}

.author-bio {
    clear: both;
    overflow: hidden;
}


/* Post Head Area */

.single-post-thumb {
    margin: 0 -1px !important;
    line-height: 0 !important;
}

.single-post-thumb img {
    width: 100%;
    height: auto;
    max-width: 100%;
}

.full-width .single-post-thumb {
    margin: 0 !important;
}

.google-map iframe {
    width: 100%;
}

.head-lightbox a {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.head-lightbox a:hover {
    opacity: .8;
}

.single-post-caption {
    color: #555;
    padding: 7px;
    text-align: center;
    font-style: italic;
    color: #666;
}

.entry .mejs-container {
    margin-bottom: 20px;
}

.mejs-container.wp-audio-shortcode {
    width: 100%;
}

.mejs-container.wp-video-shortcode,
.wp-video {
    width: 100% !important;
    height: auto !important;
}

.mejs-container.wp-video-shortcode.mejs-video {
    padding-top: 57%;
}

.mejs-overlay,
.mejs-poster {
    width: 100% !important;
    height: 100% !important;
}

.mejs-mediaelement video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
}

.wp-playlist.wp-playlist-light,
.wp-playlist.wp-playlist-dark {
    border-color: #eee;
    background: transparent;
    color: #333;
}

.wp-playlist.wp-playlist-light .wp-playlist-playing,
.wp-playlist.wp-playlist-dark .wp-playlist-playing {
    background: #eee;
    color: #444;
}

.wp-playlist .wp-playlist-item {
    padding: 5px;
    border-color: #eee;
}

.wp-playlist .wp-playlist-item-length {
    right: 6px;
    top: 5px;
}

.wp-playlist .wp-playlist-playing {
    font-weight: normal;
}

/* Post Cover Layout */

.post-cover #crumbs,
.post-cover-title #crumbs {
    border-bottom: 0 none;
}

.post-cover article.post-listing {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.post-cover article.post-listing .post-inner {
    padding: 0 0 20px;
}

.post-cover .share-post {
    margin-left: 0;
    margin-right: 0;
}

.post-cover-head .single-has-thumb.single-post-thumb {
    position: relative;
    margin: 0;
    height: 475px;
    background-repeat: no-repeat;
    background-position: center 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.post-cover-head .single-post-thumb.head-lightbox,
.post-cover-head .single-post-thumb.single-post-audio {
    height: auto;
}

.post-cover-title {
    width: 100%;
    line-height: 22px;
}

.single-has-thumb .post-cover-title {
    position: absolute;
    bottom: 0;
    padding: 50px 20px 10px;
    color: #CCC;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
}

.single-has-thumb .post-cover-title a,
.single-has-thumb .post-cover-title p.post-meta,
.single-has-thumb .post-cover-title p.post-meta a {
    color: #CCC;
}

.single-has-thumb .post-cover-title a:hover,
.single-has-thumb .post-cover-title p.post-meta a:hover {
    color: #FFF;
    border: 0 none;
}

.post-cover-title .post-title {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 0;
}

.single-has-thumb .post-cover-title .post-title {
    color: #FFF;
}

.post-cover-title #crumbs {
    margin-bottom: 0;
}

.post-cover-title p.post-meta {
    font-size: 14px;
}

a.go-to-the-post {
    padding: 10px;
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #FFF;
    font-size: 52px;
    color: #FFF;
    opacity: .6;
}

a.go-to-the-post:hover {
    opacity: 1;
}

/* Single Post Styles */

.post-listing.post {
    margin-bottom: 40px;
}

.post-inner {
    padding: 20px;
}

.post-title {
    font-family: BebasNeueRegular, arial, Georgia, serif;
    margin-bottom: 10px;
    font-size: 28px;
}

body.single .post-inner p.post-meta span {
    margin-right: 15px;
}

body.single .post-inner p.post-meta span i {
    margin-right: 5px;
}

.post-inner p.post-meta {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

p.post-meta span {
    display: inline-block;
    margin-right: 10px;
}

.item-list {
    border-bottom: 1px solid #f2f2f2;
    padding: 20px 20px 25px;
    clear: both;
}

.item-list p.post-meta {
    margin: 10px 0;
    display: flex;
}

.archive-wide-thumb {
    margin: -20px -20px 10px !important;
    padding: 0 !important;
    float: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.archive-wide-thumb a:hover img {
    opacity: 0.8
}

.page-link {
    padding: 10px 14px;
    background: #f6f6f6;
    clear: both;
}

.page-link a {
    color: #FFF;
    padding: 5px 6px;
    background: #999;
}

.page-link a:hover {
    background: #777;
}

.edit-link {
    clear: both;
    display: block;
    margin-top: 10px;
}

.post-tag {
    margin: -30px 0 35px
}

.post-tag a {
    background-color: #aaa;
    border-radius: 0 2px 2px 0;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    line-height: 13px;
    margin: 0 0 2px 10px;
    padding: 4px 7px 3px;
    position: relative;
    text-transform: uppercase;
}

.post-tag a:before {
    border-top: 10px solid transparent;
    border-right: 8px solid #aaa;
    border-bottom: 10px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: 0;
    left: -8px;
    width: 0;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}

.post-tag a:after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 4px;
    position: absolute;
    top: 8px;
    left: -2px;
    width: 4px;
}

.post-tag a:hover {
    background: #444;
    color: #FFF;
}

.post-tag a:hover:before {
    border-right-color: #444;
}

.mejs-container .mejs-controls .mejs-time-rail .mejs-time-current {
    background-color: #F88C00;
}

.mejs-container .mejs-controls a:focus > .mejs-offscreen {
    color: #000;
}

#reading-position-indicator {
    display: block;
    height: 4px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FF8500;
    width: 0;
    z-index: 9999;
    max-width: 100%;
}

.sticky {
}


/**
 * Post Thumbnail
 * -----------------------------------------------------------------------------
 */

.post-thumbnail {
    margin-right: 15px;
    position: relative;
    line-height: 0;
}

.post-thumbnail a {
    display: block !important;
    font-size: 0;
}

.post-thumbnail img {
    height: auto;
    max-width: 100%;
    width: 100%;
    -webkit-transition: all .40s;
    -moz-transition: all .40s;
    -o-transition: all .40s;
    transition: all .40s;
}

.post-thumbnail img.attachment-tie-small,
.post-thumbnail img.avatar {
    /*max-width: 100px;*/
}

.post-thumbnail a:hover img {
    opacity: 0.3 !important;
}

.post-thumbnail a:hover .overlay-icon:before,
.video-box .overlay-icon:before {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.overlay-icon:before,
.tie_slider .overlay-icon:before {
    content: '\f15c';
    color: #FFF;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 3px solid #FFF;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    line-height: 35px;
    margin: -20px 0 0 -20px;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.tie_map .overlay-icon:before {
    content: "\f041";
}

.tie_slider .overlay-icon:before {
    content: '\e80c';
}

.tie_lightbox .overlay-icon:before {
    content: "\f03e";
}

.tie_audio .overlay-icon:before,
.tie_soundcloud .overlay-icon:before {
    content: "\f028";
}

.tie_video .overlay-icon:before {
    content: "\f16a";
}

.item-list .post-thumbnail,
.timeline .post-thumbnail {
    float: left;
    margin-bottom: 0;
}

.cat-box li .post-thumbnail {
    margin: 0 10px 0 0;
}

.column2 .post-thumbnail {
    float: left;
}

.cat-box.column2 li.first-news .post-thumbnail {
    margin: 0 0 10px 0;
    float: none;
}

.wide-box li .post-thumbnail,
.list-box li .post-thumbnail {
    float: left;
}

.wide-box li.first-news .post-thumbnail {
    width: 275px;
}

.list-box li.first-news .post-thumbnail {
    margin: 0 0 15px 0;
    float: none;
}

.cat-tabs-wrap li .post-thumbnail {
    float: left;
    margin-bottom: 0;
}

.cat-tabs-wrap li.first-news .post-thumbnail {
    margin: 0 0 10px 0;
    float: none;
}

.recent-item .post-thumbnail {
    margin: 0 0 10px 0;
}


/**
 * Share Buttons
 * -----------------------------------------------------------------------------
 */

.share-post {
    clear: both;
    margin: 10px -20px -20px;
    padding: 8px 0 6px 10px;
    background: #F7F7F7;
    border-top: 1px solid #EAEAEA;
}

.mini-share-post {
    clear: both;
    margin: 10px -24px -20px;
    padding: 10px 10px 10px 25px;
    height: 20px;
}

.share-post .normal-social li,
.mini-share-post .normal-social li {
    position: relative;
    overflow: hidden;
}

.mini-share-post .share-text,
.entry .share-post .share-text {
    display: none;
}

.entry .share-post {
    margin: 0 0 15px;
    background: transparent;
    border: 0 none;
    height: auto;
    padding: 0;
}

.entry .share-post li,
.entry .share-post ul {
    list-style: none;
    margin: 0;
}

.recent-box .mini-share-post {
    margin-bottom: -5px;
}

.share-post li,
.mini-share-post li {
    float: left;
    width: 106px;
}

.entry .share-post li iframe,
.share-post li iframe,
.mini-share-post li iframe {
    max-width: none !important;
}

.share-post span.share-text {
    background: #FF8500;
    margin: -9px 10px -10px -10px;
    display: block;
    float: left;
    color: #FFF;
    padding: 0 9px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
    font-size: 14pt;
    height: 45px;
    line-height: 50px;
}

.share-post .flat-social a {
    color: #FFF !important;
    text-decoration: none !important;
}

/* Flat Share Buttons */

.flat-social li {
    width: auto !important;
}

.flat-social a {
    padding: 5px 9px;
    color: #FFF;
    margin: 0 6px 2px 0;
    display: inline-block;
    line-height: 14px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.flat-social a i {
    margin-right: 3px;
}

.flat-social a:hover {
    background: #444;
}

/**
 * Post Elements
 * -----------------------------------------------------------------------------
 */

.entry {
    word-wrap: break-word;
    line-height: 22px;
}

.entry p {
    margin-bottom: 5px !important;
}

.entry img {
    max-width: 100%;
    height: auto;
}

.entry h1,
.entry h2,
.entry h3,
.entry h4,
.entry h5,
.entry h6 {
    margin-top: 25px;
    margin-bottom: 10px;
    line-height: 1em;
    font-family: Helvetica;
    font-weight: normal;
}

.entry h1 {
    font-size: 36px;
}

.entry h2 {
    font-size: 30px;
}

.entry h3 {
    font-size: 24px;
}

.entry h4 {
    font-size: 18px;
}

.entry h5 {
    font-size: 14px;
}

.entry h6 {
    font-size: 12px;
}

.entry ol,
.entry ul {
    margin: 0 0 20px 15px;
}

.entry ul li {
    list-style: outside disc;
    list-style-image: none;
}

.entry ol li {
    list-style: outside decimal;
}

.entry li {
    margin: 0 0 5px 0;
}

.entry li ul,
.entry li ol {
    margin: 5px 0 0 15px;
}

.entry #user-login ul {
    margin-bottom: -5px;
}

.entry table {
    border-spacing: 0;
    width: 100%;
    border: 1px solid #eee;
    border-collapse: separate;
    margin-bottom: 1.5em;
}

.entry table tr:nth-child(even) {
    background: #F9F9F9;
}

.entry table td,
.entry table th {
    padding: .4em;
    text-align: left;
    border-right: 1px dotted #eee;
}

.entry table thead th {
    background: #F4F4F4;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    text-transform: uppercase;
    padding: 0.7em .4em;
    vertical-align: middle;
}

.entry table tbody th {
    background: #F4F4F4;
    border-bottom: 1px solid #eee;
}

.entry table tbody td {
    border-bottom: 1px solid #eee;
}

.entry table tr th:last-child,
.entry table tr td:last-child {
    border-right: 0;
}

.entry table tbody tr:last-child td,
.entry table tbody tr:last-child th {
    border-bottom: 0;
}

.gallery {
    margin: 0 auto 18px;
    clear: both;
}

.gallery .gallery-item {
    display: inline-block;
    padding: 10px;
    text-align: center;
    vertical-align: top;
    width: 100%;
}

.gallery-columns-2 .gallery-item {
    max-width: 50%;
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
    max-width: 25%;
}

.gallery-columns-5 .gallery-item {
    max-width: 20%;
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}

.gallery .gallery-item img {
    height: auto;
    margin: 0 auto;
    -webkit-transition: all .40s;
    -moz-transition: all .40s;
    -o-transition: all .40s;
    transition: all .40s;
}

.gallery .gallery-item a:hover img {
    opacity: 0.6 !important;
    margin: -5px 0 5px;
}

.gallery-caption {
    color: #888;
    margin: 0 0 12px;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
    display: none;
}

.gallery br + br {
    display: none;
}

.attachment img {
    display: block;
    margin: 0 auto;
}

blockquote p {
    color: #999;
    padding: 0 18px;
    font-family: "Georgia";
    font-size: 18px;
    line-height: 28px;
    font-style: italic;
    border: 4px solid #777;
    border-width: 0 0 0 4px;
}


/**
 * Related Posts
 * -----------------------------------------------------------------------------
 */

#related_posts,
#author-box {
    clear: both;
}

.block-head {
    padding: 3px 0 0;
    overflow: hidden;
}

.block-head h3,
#respond h3 {
    float: left;
    margin-right: 10px;
    font-size: 22px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
}

#related_posts .post-listing,
#author-box .post-listing {
    padding: 20px;
    margin-bottom: 40px;
}

#related_posts .related-item {
    float: left;
    width: 31.33333333%;
    margin: 0 3% 10px 0;
}

#related_posts .related-item:nth-child(3n+3),
.full-width #related_posts .related-item:nth-child(4n+4) {
    margin-right: 0;
}

#main-content:not(.full-width) #related_posts .related-item:nth-child(3n+4),
.full-width #related_posts .related-item:nth-child(4n+5) {
    clear: left;
}

#related_posts .related-item .post-thumbnail {
    margin: 0 0 10px 0;
}

.full-width #related_posts .related-item {
    margin-right: 2%;
    width: 23.5%;
}

.entry #related_posts {
    margin: 0 0 20px 20px;
    float: right;
    width: 30%;
    border: 1px solid #eee;
    border-width: 0 0 0 1px;
    padding-left: 20px;
}

.entry #related_posts .post-listing {
    border-bottom: 0 none;
    padding: 0;
    margin: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.entry #related_posts .related-item {
    float: none;
    width: 100%;
    height: auto;
    margin: 0 0 10px 0;
}

.entry #related_posts .related-item h3 {
    margin: 0;
    font-size: 15px;
}

.entry #related_posts .stripe-line {
    display: none;
}

.entry #related_posts .block-head h3 {
    margin-top: 0;
}

.entry #related_posts .post-inner p.post-meta {
    padding: 0;
}


/**
 * Check Also Box
 * -----------------------------------------------------------------------------
 */

#check-also-box {
    position: fixed;
    z-index: 999;
    bottom: 65px;
    right: -350px;
    width: 300px;
    border-top: 4px solid #F88C00;
    border-bottom: 0 none;
    padding: 10px 20px 20px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

#check-also-box.check-also-left {
    left: -350px;
    right: auto;
}

#check-also-box.show-check-also,
#check-also-box.check-also-left #check-also-close {
    right: 0;
    left: auto;
}

#check-also-box.check-also-left.show-check-also {
    right: auto;
    left: 0;
}

#check-also-box .block-head {
    padding: 0;
}

#check-also-box .block-head h3 {
    text-align: center;
    margin: 0 0 10px;
    display: block;
    float: none;
}

#check-also-box .post-thumbnail {
    margin: 0 0 10px;
}

.check-also-post {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.check-also-post:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0 none;
}

.check-also-post .post-title {
    font-size: 22px;
    margin-bottom: 5px;
}

.check-also-post p {
    line-height: 20px;
}

#check-also-close {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1px 8px 3px;
    background: #FF8500;
    color: #FFF;
    font-size: 19px;
}

#check-also-close:hover i {
    opacity: 0.7;
}


/**
 * Post Navigation
 * -----------------------------------------------------------------------------
 */

.navigation {
    margin: 10px 0;
    clear: both;
    overflow: hidden;
}

.nav-previous {
    float: left;
}

.nav-next {
    float: right;
}

.post-navigation {
    clear: both;
    margin: -10px 0 20px;
    overflow: hidden;
}

.post-navigation div {
    display: block;
    width: 245px;
    position: relative;
    font-size: 14px;
    color: #999;
}

.post-previous {
    float: left;
    padding-left: 40px;
}

.post-next {
    float: right;
    text-align: right;
    padding-right: 40px;
}

.post-navigation div span {
    display: block;
    font-size: 80%;
    color: #999;
}

.post-navigation div a:after {
    position: absolute;
    color: #ccc;
    font-size: 56px;
    margin-top: -11px;
    height: 22px;
    line-height: 22px;
    top: 34%;
}

.post-previous a:after {
    content: '«';
    left: 0;
}

.post-next a:after {
    content: '»';
    right: 0;
}


/**
 * Sidebar
 * -----------------------------------------------------------------------------
 */

#sidebar {
    float: right;
    width: 310px;
}

.full-width #sidebar {
    display: none !important;
}

.sidebar-left #sidebar {
    float: left;
}

.sidebar-left .content {
    float: right;
}

/* Sticky Sidebar */

.theiaStickySidebar:after {
    content: "";
    display: table;
    clear: both;
}

/* Widgets Styles */

.widget {
    clear: both;
    margin-bottom: 25px;
}

.widget-top {
    padding: 5px 0;
}

.widget-top h4 {
    float: left;
    margin-right: 10px;
    font-size: 20px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
}

.widget-top h4 a {
    color: #333;
}

.widget-top h4 a:hover {
    color: #000;
}

.widget-container {
    padding: 10px;
    clear: both;
}

.widget-container h3 {
    font-size: inherit;
}

.widget-container li {
    padding: 0 0 5px 0;
    line-height: 18px;
    overflow: hidden;
}

.widget-container p {
    padding-bottom: 1.0em;
}

.widget-container a:hover {
    text-decoration: underline;
}

.widget-container li span.tie-date,
.footer-widget-container li span.tie-date,
.mega-menu-block .tie-date,
span.post-comments-widget,
span.post-views-widget {
    color: #AAA;
    margin: 4px 0 0;
    font-size: 80%;
    display: inline-block
}

.widget-container li span.post-small-rate,
.footer-widget-container li span.post-small-rate {
    margin-top: 5px;
}

.widget-container .post-thumbnail,
.footer-widget-container .post-thumbnail {
    float: left;
    margin-right: 10px;
}

.widget_search label span,
#bbp-search-form label,
.widget_product_search label.screen-reader-text {
    display: none;
}

/* Text Widget */

.text-html-box {
    margin-bottom: 20px;
}

/* Rss Widget */

.widget_rss .widget-title img {
    width: 11px;
    height: 11px;
}

/* News in Picture Widget */

.widget.news-pic .post-thumbnail {
    padding: 1px;
    width: 33.3333333%;
    max-width: 160px;
    margin: 0;
}

.widget.news-pic .post-thumbnail img.attachment-tie-small {
    max-width: 100% !important;
}

/* Tabbed Widget */

#tabbed-widget .tabs-wrap li:last-child,
.comments-avatar .widget-container li:last-child,
.posts-list .widget-container li:last-child,
.categort-posts .widget-container li:last-child,
.authors-posts .widget-container li:last-child {
    border-bottom: 0 none;
}

#tabbed-widget .tabs-wrap li,
.comments-avatar .widget-container li,
.posts-list .widget-container li,
.categort-posts .widget-container li,
.authors-posts .widget-container li,
.widget.woocommerce .widget-container li,
.widget-container ul.reviews-posts li,
.widget_categories .widget-container li,
.widget_archive .widget-container li,
.widget_nav_menu .widget-container li,
.widget_meta .widget-container li,
.widget_pages .widget-container li,
.widget_recent_comments .widget-container li,
.widget_recent_entries .widget-container li,
.widget_display_forums .widget-container li,
.widget_display_views .widget-container li,
.widget_display_replies .widget-container li,
.widget_display_topics .widget-container li {
    padding: 6px 0;
    border-bottom: 1px solid #f2f2f2;
}

.widget_categories .widget-container li:before,
.widget_product_categories ul li:before {
    font-family: FontAwesome;
    margin-right: .53em;
    display: inline-block;
    opacity: .25;
    content: "\f114"
}

.widget_categories .widget-container li li,
.widget_nav_menu .widget-container li li {
    padding-left: 15px;
    border: 0 none;
}

.widget_categories .widget-container li li li,
.widget_nav_menu .widget-container li li li {
    padding-left: 25px;
}


/* Author Widget */

.authors-posts .widget-container li strong {
    float: right;
    display: block;
}

/* Instagram */
.widget_tie-instagram-theme .tie-insta-photos {
    margin: 0 -2px;
}

.widget_tie-instagram-theme .tie-insta-post {
    padding: 0 2px 4px !important;
}

.widget_tie-instagram-theme .button {
    margin-top: 10px;
    width: 100%;
    text-decoration: none !important;
    text-align: center;
}

.tie-insta-header {
    margin-bottom: 15px;
}

.tie-insta-avatar a {
    width: 70px;
    height: 70px;
    display: block;
    position: relative;
    float: left;
    margin-right: 15px;
}

.tie-insta-avatar a:before {
    content: '';
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    left: -3px;
    top: -3px;
    border-radius: 50%;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.tie-insta-avatar a:after {
    position: absolute;
    content: '';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    left: -2px;
    top: -2px;
    border-radius: 50%;
    background: #fff;
}

.tie-insta-avatar img {
    border-radius: 50%;
    position: relative;
    z-index: 2;
}

.tie-insta-info {
    font-size: 1.3em;
    font-weight: 300;
    margin-bottom: 5px;
}

.tie-insta-counts li {
    display: inline-block;
    padding: 0 5px 0 0;
}

.tie-insta-counts .counts-number {
    font-weight: bold;
}

.tie-insta-counts span:last-child {
    opacity: 0.7;
    font-size: 90%;
}

.tie-insta-desc {
    clear: both;
    margin-top: 20px;
}

.tie-insta-photos {
    overflow: hidden;
}

.tie-insta-post {
    position: relative;
    width: 33.33333333333%;
    float: left;
}

.tie-insta-post a {
    display: block;
    line-height: 0;
    position: relative;
}

.tie-insta-post a:hover img {
    opacity: 0.9;
}

.tie-insta-post img {
    max-width: 100%;
    width: 100%;
    height: auto;
    float: left;
}

.tie-insta-post .media-video {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ffffff;
    font-size: 20px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


/* Facebook Widget */

.facebook-box {
    text-align: center;
}

.facebook-box iframe {
    width: 100% !important
}

/* Cloud Tag Widget */

#wp_tag_cloud div {
    line-height: 1.6em;
}

/* Twitter Widget */

.twitter-widget-content ul {
    margin: 0 -10px;
}

#theme-footer .twitter-widget-content ul {
    margin: 0;
}

.twitter-widget-content li,
#theme-footer .twitter-widget-content li {
    background: transparent;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 10px 10px 55px;
}

.twitter-widget-content li i,
#theme-footer .twitter-widget-content li i {
    float: left;
    margin-left: -43px;
    margin-top: 0;
    color: #ddd;
    font-size: 32px;
    width: 30px;
}

#theme-footer .twitter-widget-content li i {
    color: #ccc;
}

.twitter-widget-content li:last-child {
    border-bottom: 0 none;
}

.twitter-widget-content .twitter-timestamp {
    font-style: italic;
    color: #888;
    font-size: 10px;
    float: right;
    clear: both;
}

.footer-widgets-box .twitter-widget-content li:before {
    margin-left: 0;
    color: #777;
}

/* Tabbed Widget */

#tabbed-widget .widget-top {
    background: #F7F7F7;
    height: 46px;
    border-bottom: 3px solid #EAEAEA;
    padding: 8px 0 0 2px;
    margin: -10px -10px 10px;
}

.tabs-wrap,
.cat-tabs-wrap {
    clear: both;
    display: none;
}

.tabs-wrap#tab1,
.cat-tabs-wrap.cat-tabs-wrap1 {
    display: block;
}

#tabbed-widget ul.tabs li {
    float: left;
    position: relative;
    margin-left: 3px;
    line-height: 30px;
    padding: 2px;
    background: transparent;
}

#tabbed-widget ul.tabs li a {
    display: inline-block;
    text-align: center;
    color: #666;
    padding: 5px 9px 1px;
    line-height: 30px;
}

#tabbed-widget ul.tabs li a:hover {
    color: #000;
}

#tabbed-widget ul.tabs li.active a {
    background: #FFF;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-topright: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    -webkit-box-shadow: 0 -1px 2px #d4d4d4;
    -moz-box-shadow: 0 -1px 2px #d4d4d4;
    box-shadow: 0 -1px 2px #d4d4d4;
}

#tabbed-widget .tabs-wrap.tagcloud {
    font-size: 0;
}

#tabbed-widget .tabs-wrap.tagcloud a {
    padding: 6px 8px;
    background: #F7F7F7;
    display: inline-block;
    margin: 0px 1px 1px 0px;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    transition-duration: .3s;
}

#tabbed-widget .tabs-wrap.tagcloud a:hover {
    background: #333;
    color: #FFF;
    text-decoration: none;
}


/* Calender */

#wp-calendar {
    width: 100%;
}

#wp-calendar caption {
    color: #222;
    font-weight: bold;
    padding-bottom: 4px;
    text-align: center;
}

#wp-calendar tbody {
    color: #aaa;
}

#wp-calendar tbody td {
    background: #f5f5f5;
    border: 1px solid #fff;
    padding: 3px 0 2px;
    text-align: center;
}

#wp-calendar tbody .pad,
footer #wp-calendar tbody .pad {
    background: none;
    border: 0 none;
}

#wp-calendar tfoot #next {
    text-align: right;
}

footer #wp-calendar tbody td {
    background: #333;
    border-color: #555;
}

footer #wp-calendar caption {
    color: #FFF;
}

/* Author Widget */

.author-avatar img {
    float: left;
    margin: 0 20px 0 0;
}

#author-box .author-avatar img {
    margin-right: 20px;
}

.author-description {
    font-size: 95%;
    line-height: 17px;
}

.author-social {
    float: left;
    margin-top: 10px;
}

.author-social a {
    width: 25px;
    text-align: center;
    padding: 5px 0;
    background: #f1f1f1;
    color: #777;
    line-height: 0;
}

.author-social a:hover {
    color: #FFF;
}

.author-social a.social-twitter {
    width: auto;
    padding: 5px 8px;
    text-decoration: none;
}

.widget_author .author-social a.social-twitter span {
    display: none;
}

.author-social a,
.author-social a i {
    margin: 0 !important;
}

/* Flickr Widget */

.flickr-widget .flickr_badge_image {
    float: left;
    width: 25%;
    padding: 2px;
    line-height: 0;
    max-width: 90px;
}

.flickr-widget .flickr_badge_image a {
    display: block;
}

.flickr-widget .flickr_badge_image img {
    opacity: 1;
    width: 100%;
    height: auto;
}

.flickr-widget .flickr_badge_image a:hover img {
    opacity: 0.6;
}

/* Search Widget */

.search-widget.search-block-large {
    margin-bottom: 25px;
    width: 100%;
}

.search-block-large {
    overflow: hidden;
    position: relative;
    width: 310px;
    max-width: 100%;
    margin: 10px auto;
}

.search-block-large.widget {
    margin-bottom: 25px;
}

.search-block-large #s {
    background: #FFF;
    font: normal 14px tahoma;
    padding: 9px 12px 8px 12px;
    width: 100%;
    color: #C5C5C5;
    margin-bottom: 0;
    border: 1px solid #E8E8E8;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.search-block-large #s:focus {
    color: #000;
}

.search-block-large .search-button {
    position: absolute;
    top: 1px;
    right: 1px;
    opacity: 0.7;
    background: #f88c00;
    cursor: pointer;
    height: 34px;
    width: 34px;
    display: block;
    border: 0 none;
    box-shadow: none !important;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.search-block-large:hover .search-button,
.search-block-large:hover #s {
    opacity: 1;
    color: #444;
}

.search-block-large button.search-button i:before {
    font-size: 18px;
    color: #FFF;
}

/* Built-In Search Widget */

.widget_search input[type=search] {
    min-width: 100%;
}

/* Login Widget */

#login-form {
    padding: 11px 11px 0;
}

#login-form #log,
#login-form #pwd,
.widget-feedburner .feedburner-email {
    border-color: #F1F1F1;
    border-top-color: #DDD;
    border-left-color: #DDD;
    background: #F9F9F9;
    color: #515151;
    font: 12px Tahoma;
    padding: 8px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    min-height: 24px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

#login-form #log:hover,
#login-form #pwd:hover,
.widget-feedburner .feedburner-email:hover {
    color: #000;
}

#login-form #log:focus,
#login-form #pwd:focus,
.widget-feedburner .feedburner-email:focus {
    background: #FFF;
    color: #000 !important;
}

#login-form .login-button {
    margin-right: 20px;
}

#login-form p {
    padding-bottom: 0;
}

ul.login-links {
    margin-top: 15px;
}

p.welcome-text {
    margin-bottom: 0;
    padding-bottom: 7px;
}

.content .entry #login-form p {
    padding: 0
}

#user-login li,
#user-login img {
    padding: 4px 0 0;
    margin-bottom: 0;
}

/* FeedBurner Widget */

.widget-feedburner-counter {
    padding: 11px;
}

/* Slider Widget */

#sidebar .flexslider {
    width: 310px;
    height: 205px !important;
    clear: both;
}

#sidebar .flexslider .slides > li {
    height: 205px !important;
}

#sidebar .slider-caption {
    width: 100%;
    padding: 0;
    bottom: 0;
}

#sidebar .slider-caption h2 {
    padding: 3px 10px;
    line-height: 20px;
}

#sidebar .slider-caption h2 a {
    font-size: 18px;
}

/* Timeline Widget */

.widget.timeline-posts li {
    padding: 0 0 0 5px;
}

.widget.timeline-posts li h3 {
    border: 2px solid #ccc;
    border-width: 0 0 0 2px;
    padding: 5px 0 12px 10px;
}

.widget.timeline-posts li span.tie-date {
    position: relative;
    padding-left: 12px;
    margin: 0;
    display: block;
    height: 16px;
}

.widget.timeline-posts li span.tie-date:before {
    content: '';
    width: 8px;
    height: 8px;
    border: 2px solid #ccc;
    display: block;
    position: absolute;
    left: -3px;
    top: 4px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.widget.timeline-posts li span.tie-date i {
    display: none;
}

.widget.timeline-posts li a:hover,
.widget.timeline-posts li a:hover span.tie-date {
    text-decoration: none;
    color: #F88C00;
}

.widget.timeline-posts li a:hover span.tie-date:before {
    background: #F88C00;
    border-color: #F88C00;
}


/* Counter Widget */

.arqam-lite-widget-counter {
    margin-bottom: 25px;
    overflow: hidden;
    background: white;
    background: #FFF;
    clear: both;
    font-size: 0;
    -webkit-box-shadow: 0 0 3px #CACACA;
    -moz-box-shadow: 0 0 3px #CACACA;
    box-shadow: 0 0 3px #CACACA;
}

.arqam-lite-widget-counter ul {
    text-align: center;
}

.arqam-lite-widget-counter li {
    line-height: 15px;
    display: inline-block;
    width: 33.333333%;
    border: 1px solid #eee;
    border-width: 0 0 1px 0;
}

.arqam-lite-widget-counter li a {
    color: #444;
    text-decoration: none;
    display: block;
    overflow: hidden;
    opacity: 0.8;
    padding: 0 0 6px;
}

.arqam-lite-widget-counter li a:hover {
    background: transparent;
    opacity: 1;
}

.arqam-lite-widget-counter a i {
    font-size: 28px;
    margin: 8px auto;
    width: 50px;
    color: #FFF;
    height: 50px;
    line-height: 50px;
    display: block;
    text-align: center;
    background: #555;
    -webkit-border-radius: 50px;
    moz-border-radius: 50px;
    border-radius: 50px;
}

.arqam-lite-widget-counter a:hover i,
.social-icons-widget .social-icons.social-colored a:hover {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
}

.arqam-lite-widget-counter a:hover span,
.arqam-lite-widget-counter a:hover small {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
}

.arqam-lite-widget-counter li:nth-child(3n+1) {
    border-width: 0 1px 1px 0;
}

.arqam-lite-widget-counter li:nth-child(3n+3) {
    border-width: 0 0 1px 1px;
}

.arqam-lite-widget-counter li span {
    display: block;
    font-size: 21px;
}

.arqam-lite-widget-counter li a small {
    font-size: 11px;
    color: #777;
    padding-top: 3px;
    display: inline-block;
}

.arqam-lite-widget-counter.border a i {
    background: transparent;
    border: 1px solid #555;
    color: #555;
}

.arqam-lite-widget-counter.border li a:hover i {
    border: 0 none;
    color: #FFF;
}

.arqam-lite-widget-counter.border .arq-lite-facebook a i {
    border-color: #39599f;
    color: #39599f;
}

.arqam-lite-widget-counter.border .arq-lite-twitter a i {
    border-color: #45b0e3;
    color: #45b0e3;
}

.arqam-lite-widget-counter.border .arq-lite-rss a i {
    border-color: #f8bc2e;
    color: #f8bc2e;
}

.arqam-lite-widget-counter.border .arq-lite-soundcloud a i {
    border-color: #F76700;
    color: #F76700;
}

.arqam-lite-widget-counter.border .arq-lite-github a i {
    border-color: #4183c4;
    color: #4183c4;
}

.arqam-lite-widget-counter.border .arq-lite-behance a i {
    border-color: #1769ff;
    color: #1769ff;
}

.arqam-lite-widget-counter.border .arq-lite-dribbble a i {
    border-color: #d875a2;
    color: #d875a2;
}

.arqam-lite-widget-counter.border .arq-lite-youtube a i {
    border-color: #cc181e;
    color: #cc181e;
}

.arqam-lite-widget-counter.border .arq-lite-instagram a i {
    border-color: #3897f0;
    color: #3897f0;
}

.arqam-lite-widget-counter.border .arq-lite-vimeo a i {
    border-color: #3fbfce;
    color: #3fbfce;
}

.arqam-lite-widget-counter.border .arq-lite-delicious a i {
    border-color: #2567ee;
    color: #2567ee;
}


/* Social Icons Widget */

.social-icons {
    text-align: center;
}

.social-icons a {
    margin: 2px;
    position: relative;
    text-shadow: none;
    display: inline-block;
}

.social-icons-widget .social-icons i:before {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 18px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.social-icons-widget .social-icons a {
    color: #555 !important;
}

#theme-footer .social-icons-widget .social-icons a {
    color: #999 !important;
}

.social-icons-widget .social-icons.social-colored a {
    color: #FFF !important;
}

.social-icons-widget .social-icons a:hover {
    opacity: 0.7;
}

.social-icons.social-colored .fa-delicious:before,
.arq-lite-delicious a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-delicious a i {
    background: #2567ee
}

.social-icons.social-colored .fa-foursquare:before {
    background: #0d9ed1
}

.social-icons.social-colored .fa-digg:before {
    background: #b2b2b2
}

.social-icons.social-colored .fa-reddit:before {
    background: #ff915c
}

.social-icons.social-colored .tieicon-vimeo:before,
.arq-lite-vimeo a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-vimeo a i {
    background: #3fbfce
}

.social-icons.social-colored .fa-play:before {
    background: #a0b532
}

.social-icons.social-colored .fa-soundcloud:before,
.arq-lite-soundcloud a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-soundcloud a i {
    background: #F76700
}

.social-icons.social-colored .fa-yelp:before {
    background: #c1230d
}

.social-icons.social-colored .fa-github:before {
    background: #666666
}

.social-icons.social-colored .fa-xing:before {
    background: #00605e
}

.social-icons.social-colored .fa-dropbox:before {
    background: #459ce6
}

.social-icons.social-colored .fa-tumblr:before {
    background: #385a75
}

.social-icons.social-colored .fa-apple:before {
    background: #7e878b
}

.social-icons.social-colored .fa-dribbble:before,
.arq-lite-dribbble a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-dribbble a i {
    background: #d875a2
}

.social-icons.social-colored .fa-skype:before {
    background: #00c6ff
}

.social-icons.social-colored .fa-vk:before {
    background: #336699
}

.social-icons.social-colored .fa-friendfeed:before {
    background: #93b4dd
}

.social-icons.social-colored .fa-lastfm:before {
    background: #f54a3d
}

.social-icons.social-colored .fa-spotify:before {
    background: #7fb718
}

.social-icons.social-colored .fa-paypal:before {
    background: #34aad2
}

.social-icons.social-colored .fa-wordpress:before {
    background: #2071ad
}

.social-icons.social-colored .fa-rss:before,
.arq-lite-rss a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-rss a i {
    background: #f8bc2e
}

.social-icons.social-colored .fa-share-alt:before {
    background: #55ac60
}

.social-icons.social-colored .tieicon-picasa:before {
    background: #9d48a1
}

.social-icons.social-colored .tieicon-evernote:before {
    background: #1c8328
}

.social-icons.social-colored .fa-github:before,
.arq-lite-github a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-github a i {
    background: #4183c4
}

.social-icons.social-colored .tieicon-blogger:before {
    background: #f39d38
}

.social-icons.social-colored .tieicon-viadeo:before {
    background: #f97618
}

.social-icons.social-colored .tieicon-deviantart:before {
    background: #5d6f6d
}

.social-icons.social-colored .tieicon-grooveshark:before {
    background: #248fd3
}

.social-icons.social-colored .tieicon-fivehundredpx:before {
    background: #00adf1
}

.social-icons.social-colored .fa-facebook:before,
.social-facebook,
.author-social a.social-facebook:hover,
.arq-lite-facebook a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-facebook a i {
    background: #39599f
}

.social-icons.social-colored .fa-twitter:before,
.social-twitter,
.author-social a.social-twitter:hover,
.arq-lite-twitter a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-twitter a i {
    background: #45b0e3
}

.social-icons.social-colored .fa-stumbleupon:before,
.social-stumble,
.author-social a.social-stumble:hover {
    background: #eb4924
}

.social-icons.social-colored .fa-linkedin:before,
.social-linkedin,
.author-social a.social-linkedin:hover {
    background: #65b7d2
}

.social-icons.social-colored .fa-pinterest:before,
.social-pinterest,
.author-social a.social-pinterest:hover {
    background: #E00707
}

.social-icons.social-colored .fa-youtube:before,
.social-youtube,
.author-social a.social-youtube:hover,
.arq-lite-youtube a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-youtube a i {
    background: #cc181e
}

.social-icons.social-colored .fa-instagram:before,
.social-instagram,
.author-social a.social-instagram:hover,
.arq-lite-instagram a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-instagram a i {
    background: #3897f0
}

.social-icons.social-colored .fa-behance:before,
.social-behance,
.author-social a.social-behance:hover,
.arq-lite-behance a:hover i,
.arqam-lite-widget-counter.colored .arq-lite-behance a i {
    background: #1769ff
}

.social-icons.social-colored .tieicon-flickr:before,
.social-flickr,
.author-social a.social-flickr:hover {
    background: #ff0084
}

.social-site {
    background: #666
}

/* ADS Widgets */

.e3lan-cell {
    width: 50%;
    display: block;
    padding: 5px;
    float: left;
    text-align: center;
    line-height: 0;
}

.e3lan-widget-content {
    overflow: hidden;
    margin-bottom: 25px;
    clear: both;
}

.widget-container .e3lan-widget-content {
    margin-bottom: 0;
}

.e3lan-one .e3lan-cell,
.e3lan160-600 .e3lan-cell,
.e3lan250-250 .e3lan-cell,
.e3lan300-100 .e3lan-cell,
.e3lan300-250 .e3lan-cell,
.e3lan300-600 .e3lan-cell {
    width: 100%;
    padding: 0;
    float: none;
}

.widget-container .e3lan300-100,
.widget-container .e3lan300-250,
.widget-container .e3lan300-600 {
    margin: 0 -10px;
}

.e3lan300-100 .e3lan-cell {
    padding: 5px 0;
}

/* Weather Widget */

.tie-weather-wrap {
    clear: both;
    overflow: hidden;
}

.weather-icon[class^="tieicon-"]:before {
    font-size: 50pt;
    line-height: 60px;
    height: 60px;
}

.weather-icon {
    position: relative;
    margin: 0 auto;
}

.weather-icon i {
    display: block;
    line-height: 0;
}

.tie-weather-current-temp {
    padding: 10px 0;
    width: 35%;
    float: left;
    font-size: 44px;
    line-height: 1em;
    font-weight: 300;
    text-align: center;
    color: #666;
}

.tie-weather-current-temp sup {
    font-size: 24px;
    top: -1.3em;
    padding-left: 3px;
}

.tie-weather-todays-stats {
    float: right;
    padding: 14px 5%;
    line-height: 1.4em;
    width: 65%;
}

.weather_name {
    font-size: 24pt;
    margin-bottom: 10px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
}

.weather_desc {
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.tie-weather-forecast {
    border-top: solid 1px #EEE;
    padding: 20px 10px 15px;
    clear: both;
}

.tie-weather-forecast-day {
    width: 18%;
    margin: 0 1%;
    float: left;
    text-align: center;
    font-weight: 400;
}

.weather_days_2 .tie-weather-forecast-day {
    width: 48%;
}

.weather_days_3 .tie-weather-forecast-day {
    width: 31%;
}

.weather_days_4 .tie-weather-forecast-day {
    width: 23%;
}

.tie-weather-forecast-day-temp {
    font-size: 1.8em;
    font-weight: 400;
}

.tie-weather-forecast-day-temp sup {
    font-size: 0.6em;
    padding-left: 2px;
}

.tie-weather-forecast-day-abbr {
    text-transform: uppercase;
    font-size: 0.8em;
    margin-top: 5px;
    padding-right: 4px;
}


/**
 * Images
 * -----------------------------------------------------------------------------
 */

img,
img.size-auto,
img.size-full,
img.size-large,
img.size-medium,
.attachment img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

img.centered,
img.aligncenter {
    display: block;
    margin: 5px auto;
}

img.alignright {
    margin: 5px 0 5px 25px;
    display: inline;
}

img.alignleft {
    margin: 5px 25px 5px 0;
    display: inline;
}

/* Image Caption */

.wp-caption {
    line-height: 18px;
    margin-bottom: 5px;
    text-align: left;
    font-style: italic;
    max-width: 100%;
}

.wp-caption.alignleft {
    margin-right: 25px;
}

.wp-caption.alignright {
    margin-left: 25px;
}

.wp-caption img {
    max-width: 100% !important;
    max-height: 168px;
    width: auto;
    margin: auto;
    display: block;
    margin-bottom: 10px;
}

.wp-caption p.wp-caption-text {
    color: #888;
    padding: 4px 0;
    margin: 0;
}


/**
 * Pagination
 * -----------------------------------------------------------------------------
 */

ul.pagination {
    overflow: hidden;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination a,
.pagination span.extend,
.pagination span.current {
    float: left;
    /*margin-right: 3px;*/
    color: #777;
    /*padding: 6px 10px 3px;*/
}

.pagination a:hover {
    color: #000;
}

.pagination span.current {
    color: #FFF;
    background-color: #F88C00;
    padding: 4px 10px 6px;
}

.pagination .pages {
    float: right;
    display: block;
    font-size: 85%;
    padding: 7px 0 2px;
}


/**
 * Comments List
 * -----------------------------------------------------------------------------
 */

#comments-box .post-listing {
    padding: 20px 20px 0;
    margin-bottom: 40px;
}

#comments-title {
}

#comments-title em {
    color: #333;
}

.comment-avatar {
    float: left;
    margin: 0 10px 0 0;
}

.commentlist li {
    margin-bottom: 20px;
    clear: both;
    position: relative;
    clear: both;
}

.author-comment {
    overflow: hidden;
    position: relative;
    margin-bottom: 5px;
}

.author-comment cite {
    color: #3b3b3b;
    position: relative;
    display: block;
    margin-bottom: 2px;
    padding-top: 5px;
    font-style: normal;
    font-weight: bold;
    float: left;
}

.commentmetadata {
    display: block;
    font-size: 85%;
    float: right;
    padding-top: 5px;
}

.commentmetadata a {
    color: #3b3b3b;
}

.commentmetadata a:hover {
    color: #000;
}

.comment-wrap {
    position: relative;
    border-bottom: 1px solid #E7E7E7;
}

.comment-content {
    position: relative;
    display: block;
    overflow: hidden;
    padding-bottom: 15px;
    margin-left: 80px;
    min-height: 80px;
}

.comment-content p {
    line-height: 17px;
    margin-bottom: 10px;
}

.nocomments {
    text-align: center;
}

.commentlist li.pingback {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
}

.commentlist .children {
    margin-top: 15px;
    margin-left: 50px;
}

.commentlist .reply a {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: #E7E7E7;
    padding: 3px 10px;
    font-size: 11px;
}

.commentlist .reply a:hover {
    background: #333;
    color: #FFF;
}

.bypostauthor {
}

#bbpress-forums div.bbp-reply-author img.avatar {
    position: relative !important;
}

/**
 * Post Comment Form
 * -----------------------------------------------------------------------------
 */

#respond {
    margin-bottom: 10px;
    position: relative;
    clear: both;
}

#respond:before {
    height: 1px;
}

.commentlist li #respond {
    margin: 10px 0 20px 70px !important;
}

#commentform {
    padding: 20px 20px 10px;
    margin-top: 5px;
    overflow: hidden;
    clear: both;
}

#commentform .required {
    font-weight: bold;
}

#commentform label {
    padding-bottom: 4px;
    color: #888;
    display: block;
}

#commentform p {
    margin: 0 0 10px;
}

#commentform input {
    display: block;
    width: 250px;
}

#commentform input[type="checkbox"] {
    margin: 2px 7px 0 0;
    float: left;
    clear: left;
    width: auto;
}

#commentform .form-allowed-tags,
.comment-form-comment label {
    display: none;
}

#commentform p.form-submit {
    margin-top: 0;
}

.comment-form-email,
.comment-form-url,
.comment-form-url,
.comment-form-comment {
    clear: both;
}

.comment-notes {
    margin-bottom: 10px;
}

input.error,
textarea.error {
    border: 1px solid #FF0000 !important;
}


/**
 * ADS
 * -----------------------------------------------------------------------------
 */

.e3lan-top {
    float: right;
    line-height: 0;
}

.e3lan-bottom,
.e3lan-below_header {
    line-height: 0;
    margin: 15px auto 20px;
    text-align: center;
    position: relative;
}

.e3lan-post {
    line-height: 0;
    margin: 20px auto;
    text-align: center;
}

.home-e3lan {
    clear: both;
    margin: 20px auto;
    text-align: center;
    min-height: 1px;
}

.e3lan-top iframe,
.e3lan-bottom iframe,
.home-e3lan iframe,
.e3lan-post iframe,
.e3lan-below_header iframe {
    margin: 0 auto;
    max-width: none;
}

.e3lan-top img,
.e3lan-bottom img,
.home-e3lan img,
.e3lan-post img,
.e3lan-below_header img {
    max-width: 100%;
    height: auto;
}

.e3lan-top ins,
.e3lan-bottom ins,
.e3lan-below_header ins,
.home-e3lan ins,
.e3lan-post ins {
    margin: 0 auto !important;
    padding: 0 !important;
    background: transparent !important;
    text-decoration: none !important;
}

@media only screen and (min-width: 990px) {
    .e3lan-top ins {
        width: 728px;
        height: 90px;
    }
}


/**
 * ToolTips
 * -----------------------------------------------------------------------------
 */

a.ttip,
a.tooldown {
    /*display: block;*/
}

.tipsy {
    font-size: 10px;
    position: absolute;
    padding: 5px;
    z-index: 100000;
}

.tipsy-inner {
    background-color: #000;
    color: #FFF;
    max-width: 200px;
    padding: 5px 8px 4px 8px;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.tipsy-arrow {
    position: absolute;
    width: 0;
    height: 0;
    line-height: 0;
    border: 5px dashed #000;
}

.tipsy-arrow-n {
    border-bottom-color: #000;
}

.tipsy-arrow-s {
    border-top-color: #000;
}

.tipsy-arrow-e {
    border-left-color: #000;
}

.tipsy-arrow-w {
    border-right-color: #000;
}

.tipsy-n .tipsy-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-nw .tipsy-arrow {
    top: 0;
    left: 10px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-ne .tipsy-arrow {
    top: 0;
    right: 10px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-s .tipsy-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-sw .tipsy-arrow {
    bottom: 0;
    left: 10px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-se .tipsy-arrow {
    bottom: 0;
    right: 10px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-e .tipsy-arrow {
    right: 0;
    top: 50%;
    margin-top: -5px;
    border-left-style: solid;
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.tipsy-w .tipsy-arrow {
    left: 0;
    top: 50%;
    margin-top: -5px;
    border-right-style: solid;
    border-left: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
}


/**
 * Grid Featured Posts
 * -----------------------------------------------------------------------------
 */

#featured-posts {
    margin-bottom: 30px;
    overflow: hidden;
    display: block;
    position: relative;
}

#featured-posts .featured-post.fea-1,
#featured-posts .featured-post.fea-2,
#featured-posts .featured-post.fea-3,
#featured-posts .featured-post.fea-4,
#featured-posts .featured-post.fea-5,
#featured-posts .featured-posts-single-slide .featured-post {
    display: block;
}

#featured-posts .featured-post {
    display: none;
    float: left;
    position: relative;
    overflow: hidden;
    padding-left: 1px;
}

#featured-posts .featured-post-inner {
    height: 215px;
    position: relative;
    line-height: 0;
    color: #FFF;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#featured-posts .featured-post img {
    max-width: 100%;
    height: auto;
}

#featured-posts .featured-post.featured-post-1,
#featured-posts .featured-post.featured-post-3 {
    padding-left: 0;
}

.featured-post-1,
.featured-post-2 {
    width: 50%;
}

.featured-post-3,
.featured-post-4,
.featured-post-5 {
    width: 33.33333333%;
    padding-top: 1px;
}

.featured-cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 1) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 1) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 1) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(top, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 1) 100%);
}

.featured-cover a {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.featured-cover a span {
    display: none;
}

#featured-posts .featured-post-inner:hover .featured-cover {
    opacity: 1;
}

.featured-title {
    position: absolute;
    width: 100%;
    bottom: -60px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    line-height: 20px;
    padding: 20px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.featured-title h2 {
    margin-bottom: 15px;
    text-shadow: rgba(0, 0, 0, .6) 0 0 5px;
    font-size: 20px;
    line-height: 26px;
}

.featured-title h3 {
    font-size: 10pt;
    opacity: 0;
    min-height: 40px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.featured-title span.tie-date {
    font-size: 9pt;
}

.featured-title a {
    color: #FFF;
}

#featured-posts .flex-direction-nav a {
    width: 40px;
    height: 40px;
    margin: 0;
    color: #FFF;
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    background: #222;
}

#featured-posts.featured-posts-disable-nav .flex-direction-nav {
    display: none;
}

#featured-posts .flex-direction-nav .flex-next {
    right: 5px !important;
}

#featured-posts .flex-direction-nav .flex-prev {
    right: 50px !important;
    left: auto !important;
}

#featured-posts .flex-direction-nav .flex-next:before,
#featured-posts .flex-direction-nav .flex-prev:before {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20pt;
    display: block;
    text-align: center;
}

#featured-posts:hover .flex-next,
#featured-posts:hover .flex-prev {
    opacity: 0.8;
}

#featured-posts .flex-next:hover,
#featured-posts .flex-prev:hover {
    background: #F88C00;
}

#featured-posts .featured-post-inner:hover .featured-title {
    bottom: 0px;
}

#featured-posts .featured-post-inner:hover .featured-title h3 {
    opacity: .8;
}


/**
 * Flexi Slider
 * -----------------------------------------------------------------------------
 */

.flexslider .slides > li,
.full-width .content .flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
    position: relative;
    height: 498px;
}

.flexslider .slides img {
    width: 100%;
    height: 100%;
    display: block;
}

.slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}

.no-js .slides > li:first-child {
    display: block;
}

.flexslider {
    overflow: hidden;
    margin: 0 auto 20px;
    position: relative;
    zoom: 1;
    width: 100%;
    height: 498px;
    max-width: 100%;
    background: #000;
}

.flexslider:before,
.ei-slider-loading:before,
#main-nav .mega-cat-content.mega-cat-sub-exists .mega-cat-content-tab.loading-items:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -13.5px 0 0 -13.5px;
    height: 27px;
    width: 27px;
    font-size: 30px;
    content: "\f110";
    color: #999;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.flexslider .slides {
    zoom: 1;
}

.carousel li {
    margin-right: 5px
}

.flex-direction-nav a {
    width: 30px;
    height: 30px;
    margin: -15px 0 0;
    color: #FFF;
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
}

.flex-direction-nav .flex-next:before,
.flex-direction-nav .flex-prev:before {
    width: 30px;
    display: block;
    text-align: center;
    font-size: 26px;
}

.flex-direction-nav .flex-next {
    right: -30px;
}

.flex-direction-nav .flex-next:before {
    content: "\f105";
}

.flex-direction-nav .flex-prev {
    left: -30px;
}

.flex-direction-nav .flex-prev:before {
    content: "\f104";
}

.flexslider:hover .flex-next {
    opacity: 0.8;
    right: 5px;
}

.flexslider:hover .flex-prev {
    opacity: 0.8;
    left: 5px;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
    opacity: 1;
}

.flex-direction-nav .disabled {
    opacity: .3 !important;
    cursor: default;
}

.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
}

.flex-control-thumbs li {
    float: left;
    margin: 0;
}

.flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: .7;
    cursor: pointer;
}

.flex-control-thumbs img:hover {
    opacity: 1;
}

.flex-control-thumbs .active {
    opacity: 1;
    cursor: default;
}

.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -1px;
    text-align: center;
    height: 13px;
    z-index: 2;
}

.flex-control-nav li {
    margin: 0;
    *display: inline;
    display: inline-block;
    zoom: 1;
}

.flex-control-paging li a {
    display: block;
    cursor: pointer;
    text-indent: -9999px;
    width: 100%;
    background: #666;
    border-right: 1px solid #888;
    height: 13px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;

}

.flex-control-paging li:last-child a {
    border-right: 0 none;
}

.flex-control-paging li a:hover {
    background: #000;
}

.flex-control-paging li a.flex-active {
    background: #F88C00;
    cursor: default;
}

.slider-caption {
    position: absolute;
    width: 100%;
    padding: 15px 0;
    background: #000;
    right: 0;
    bottom: 13px;
    color: #FFF;
    opacity: 0.8;
}

.slider-caption h2 a,
.slider-caption h2,
.full-width .content .slider-caption h2 {
    color: #FFF;
    font-size: 35px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
}

.slider-caption h2,
.slider-caption p {
    padding: 0 15px;
}

/* Small Slider */

.content .flexslider {
    height: 330px;
}

.content .flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
    position: relative;
    height: 330px;
}

.content .flexslider .slides img {
    width: 100%;
    display: block;
}

.content .slider-caption h2 a,
.content .slider-caption h2 {
    font-size: 22px;
}

.content .ei-slider {
    height: 330px;
}

.content .ei-slider-loading {
    line-height: 375px;
}

.content .ei-title h3 {
    font-size: 12px;
    line-height: 14px;
}

.content .ei-title h2 {
    font-size: 22px;
}


/**
 * Elastic Slider
 * -----------------------------------------------------------------------------
 */

.ei-slider {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 498px;
    margin: 0 auto 35px;
}

.ei-slider-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background: #000;
    color: #fff;
    text-align: center;
    line-height: 520px;
}

.ei-slider-large {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.ei-slider-large li {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.ei-slider-large li img {
    width: 100% !important;
    margin: 0 !important;
}

.ei-title {
    position: absolute;
    right: 10%;
    margin-right: 0;
    margin-left: 2%;
    width: 88%;
    bottom: 25%;
    color: #fff;
    opacity: 0.8;
}

.ei-title h2,
.ei-title h3 {
    text-align: right;
}

.ei-title h2 {
    color: #b5b5b5;
    font-size: 35px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
    background: #000;
    float: right;
    padding: 5px;
    margin-bottom: 5px;
}

.ei-title h3 {
    clear: both;
    font-size: 14px;
    line-height: 20px;
    max-width: 90%;
    float: right;
    background: #000;
    padding: 5px;
}

.ei-slider-thumbs {
    height: 13px;
    margin: 0 auto;
    position: relative;
    max-width: 100% !important;
}

.ei-title a {
    color: #FFF;
}

.ei-slider-thumbs li {
    position: relative;
    float: left;
    height: 100%;
}

.ei-slider-thumbs li.ei-slider-element {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    z-index: 10;
    text-indent: -9000px;
    background: #F88C00;
}

.ei-slider-thumbs li a {
    display: block;
    text-indent: -9000px;
    background: #666;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-right: 1px solid #888;
    -webkit-transition: background 0.2s ease;
    -moz-transition: background 0.2s ease;
    -ms-transition: background 0.2s ease;
    -o-transition: background 0.2s ease;
    transition: background 0.2s ease;
}

.ei-slider-thumbs li:last-child a {
    border-right: 0 none;
}

.ei-slider-thumbs li a:hover {
    background-color: #f0f0f0;
}

.ei-slider-thumbs li img,
.lazy-enabled #main-content .ei-slider-thumbs li img {
    position: absolute;
    bottom: 50px;
    opacity: 0;
    z-index: 999;
    width: 100%;
    max-height: 100px;
    height: auto;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(rgba(255, 255, 255, 0.3)));
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.content .ei-slider-thumbs li img {
    max-height: 65px;
}

.ei-slider-thumbs li:hover img,
.lazy-enabled #main-content .ei-slider-thumbs li:hover img {
    opacity: 1;
    bottom: 13px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}


/**
 * Page Templates
 * -----------------------------------------------------------------------------
 */

/* Sitemap */

#sitemap {
    overflow: hidden;
    padding-top: 10px;
}

.sitemap-col {
    float: left;
    margin: 0 2% 10px 0;
    width: 23.5%;
}

#sitemap .last {
    margin-right: 0px;
}

.sitemap-col h2 {
    color: #111;
    font-size: 24px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 5px;
}

.sitemap-col ul {
    padding-left: 0px;
    margin-left: 0;
}

.sitemap-col ul li {
    list-style-position: inside;
}

.sitemap-col ul li a {
    padding: 4px 0px 4px;
    display: inline-block;
}

/* Authors List */

ul.authors-wrap {
    margin: 0 0 10px;
}

ul.authors-wrap li {
    background: transparent;
    padding: 20px;
    margin: 0 -20px 10px;
    border-bottom: 1px solid #f2f2f2;
    list-style: none;
}

ul.authors-wrap li h3 {
    font-size: 22px;
    text-transform: capitalize;
    font-family: BebasNeueRegular, arial, Georgia, serif;
    margin: 0 0 5px;
}

/* Best Reviews */

.entry ul.best-reviews li {
    list-style: none;
    margin: 0 0 10px;
    overflow: hidden;
    border-bottom: 1px solid #f2f2f2;
    padding-left: 170px;
    padding-bottom: 10px;
}

.best-review-score-image {
    margin-left: -170px;
}

.entry ul.best-reviews .best-review-score {
    background: #AAA;
    font-size: 28px;
    margin: 15px 15px 0 0;
    width: 50px;
    color: #FFF;
    height: 50px;
    line-height: 50px;
    display: block;
    text-align: center;

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.entry ul.best-reviews {
    margin: 0;
}

.entry ul.best-reviews .post-thumbnail,
.entry ul.best-reviews .best-reviews-content,
.entry ul.best-reviews .best-review-score {
    float: left;
}

.entry ul.best-reviews h3 {
    margin: 2px 0 5px;
}

.entry ul.best-reviews p.post-meta {
    border-bottom: 0 none;
    padding: 0;
    margin: 5px 0 0;
    clear: both;
}

.entry ul.best-reviews .post-thumbnail {
    width: 90px;
}

/* Timeline */

.timeline-contents.timeline-page {
    text-align: center;
}

ul.timeline {
    border: 2px solid #DFDFE0;
    border-width: 0 0 0 2px;
    margin: 0;
    padding: 5px 0 30px;
}

.timeline-page ul.timeline {
    width: 50%;
    direction: ltr;
    float: right;
    margin: 0;
}

h2.timeline-head {
    text-align: center;
    background: #eee;
    font-size: 12pt;
    padding: 8px 20px;
    font-weight: bold;
    color: #666;
    margin: 0 auto;
    display: inline-block;
}

ul.timeline li.timeline-post {
    background: transparent;
    list-style: none;
    width: 100%;
    padding-left: 4%;
    margin: 20px 0 0;
    position: relative;
    text-align: left;
}

ul.timeline li.timeline-post span.timeline-date {
    font-size: 12px;
    display: block;
    color: #999;
}

ul.timeline li.timeline-post:before {
    background: #DFDFE0;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 5px;
    left: -5.5px;
    content: "";
}

.timeline-page ul.timeline li:nth-child(2n+2) {
    margin-left: -100%;
    text-align: right;
    padding-left: 0;
    padding-right: 4%;
}

.timeline-page ul.timeline li:nth-child(2n+2):before {
    left: auto;
    right: -4.5px;
}

.timeline-archive ul.timeline {
    margin-left: 15px;
}

.timeline-archive ul.timeline li.timeline-post {
    padding-left: 15px;
    clear: both;
}

.timeline-archive ul.timeline li.timeline-post .timeline-content {
    overflow: hidden;
}

.timeline-archive ul.timeline li.timeline-post h2 {
    padding: 8px 0;
}

/* Media Page */

#media-page #featured-posts .featured-post {
    display: block;
    padding-top: 1px;
}

#media-page #featured-posts .featured-post .overlay-icon:before {
    opacity: 0.5;
    margin: 0;
    top: 10px;
    right: 10px;
    left: auto;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

#media-page #featured-posts .featured-post:hover .overlay-icon:before {
    opacity: 0.7;
}

/**
 * Masonry Layout
 * -----------------------------------------------------------------------------
 */

.masonry-grid {
    padding-left: 4%;
}

.masonry-grid .item-list {
    width: 44%;
    padding-left: 0;
    padding-right: 0;
    margin-right: 4%;
}

.masonry-grid .item-list .post-thumbnail {
    float: none;
    margin: 0 0 10px;
}

.full-width .masonry-grid.post-listing {
    padding-left: 3%;
}

.full-width .masonry-grid .item-list {
    width: 29.3%;
    margin-right: 3%;
}

/* isotope */

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -ms-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -ms-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: top, left, opacity;
    transition-property: transform, opacity;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}


/**
 * Footer
 * -----------------------------------------------------------------------------
 */

#theme-footer {
    position: relative;
    color: #ddd;
    background: #333;
    padding: 20px 0;
    border-top: 8px solid #FF8500;
    margin-top: 20px;
    -webkit-box-shadow: 0 -5px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 -8px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 -8px 0 rgba(0, 0, 0, .1);
}

#theme-footer a {
    color: #ccc;
}

#theme-footer a:hover {
    color: #FFF;
}

/* Footer Widgets */

#footer-widget-area {
    width: 1045px;
    margin: 0 auto;
}

.footer-widgets-box {
    float: left;
    margin-right: 4%;
}

.footer-1c .footer-widgets-box {
    width: 100%
}

.footer-2c .footer-widgets-box {
    width: 48%;
}

.footer-3c .footer-widgets-box {
    width: 30.66666666666667%;
}

.footer-4c .footer-widgets-box {
    width: 22%;
}

.narrow-wide-2c #footer-first,
.wide-narrow-2c #footer-second {
    width: 32%;
}

.narrow-wide-2c #footer-second,
.wide-narrow-2c #footer-first {
    width: 64%;
}

.wide-left-3c #footer-first,
.wide-right-3c #footer-third {
    width: 46%;
}

.wide-left-3c .footer-widgets-box,
.wide-right-3c .footer-widgets-box {
    width: 23%;
}

.footer-1c .footer-widgets-box,
.narrow-wide-2c #footer-second,
.wide-narrow-2c #footer-second,
.footer-2c #footer-second,
.footer-3c #footer-third,
.footer-4c #footer-fourth,
.wide-right-3c #footer-third,
.wide-left-3c #footer-third {
    margin-right: 0;
}

.footer-widget-top {
    border-bottom: 1px solid #fff;
    padding: 10px 0;
}

.footer-widget-top h4 {
    font-size: 22px;
    font-family: BebasNeueRegular, arial, Georgia, serif;
}

.footer-widget-container {
    padding: 5px 0;
    border-top: 1px solid #444;
}

.footer-widget-container p {
    padding-bottom: 1.0em;
}

.footer-widget-container li,
#theme-footer #tabbed-widget .tabs-wrap li {
    padding: 6px 12px 6px 0;
    line-height: 18px;
    overflow: hidden;
    border-bottom: 1px solid #fff;
}

#theme-footer .twitter-widget-content li {
    border-bottom: 1px solid #3c3c3c;
}

#theme-footer #tabbed-widget .widget-top {
    background: transparent;
    border-bottom-color: #444;
    overflow: hidden;
}

#theme-footer #tabbed-widget ul.tabs li a:hover {
    color: #FFF;
}

#theme-footer #tabbed-widget ul.tabs li.active a {
    color: #FFF;
    background: #444;
    -webkit-box-shadow: 0 -1px 2px #222;
    -moz-box-shadow: 0 -1px 2px #222;
    box-shadow: 0 -1px 2px #222;
}

#theme-footer #tabbed-widget .tabs-wrap.tagcloud a {
    background: #444;
}

#theme-footer #tabbed-widget .tabs-wrap.tagcloud a:hover {
    background: #555;
}

#theme-footer .search-widget input {
    background: #414141;
    border: 1px solid #202020;
    color: #222;
    -webkit-box-shadow: 0 1px 3px 0 #313131;
    -moz-box-shadow: 0 1px 3px 0 #313131;
    box-shadow: 0 1px 3px 0 #313131;
}

#theme-footer .search-widget input:hover,
#theme-footer .search-widget input:focus {
    color: #000;
}

/* Footer Bottom */

.footer-bottom {
    position: relative;
    background: #2e2e2e;
    line-height: 35px;
    border-top: 1px solid #444;
    color: #ccc;
    padding: 15px 0;
}

.footer-bottom .social-icons {
    float: right;
    margin: 3px 15px 0;
    line-height: 0;
}

.footer-bottom a {
    color: #ccc;
}

.footer-bottom a:hover {
    color: #FFF;
}

/* Go to top icon */
#topcontrol {
    z-index: 999;
    background: #FF8500;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    position: fixed;
    bottom: -100px;
    right: 10px;
    cursor: pointer;
    overflow: auto;
    opacity: 0.7;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

#topcontrol:before {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #FFF;
}

#topcontrol:hover {
    opacity: 1;
}


/**
 * Shortcodes
 * -----------------------------------------------------------------------------
 */

/* Post Content Slider */

.post-content-slideshow-outer {
    padding: 10px 0;
    border-bottom: 4px solid #f2f2f2;
    margin: 10px 0 20px;
    clear: both;
}

.post-content-slideshow {
    padding-bottom: 80px;
    position: relative;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.post-content-slide {
    width: 100% !important;
    height: auto !important;
    top: 40px !important;
    display: none;
}

.post-content-slide:nth-child(2) {
    display: block;
}

.post-tslideshow-nav-outer {
    height: 30px;
}

.post-tslideshow-nav-outer-bottom {
    height: 30px;
    bottom: 0;
    position: absolute;
    width: 100%;

}

.post-slideshow-nav {
    display: inline-block;
    text-align: center;
    padding: 5px 0;
}

.post-slideshow-nav a {
    background: #b4b4b4;
    width: 10px;
    height: 10px;
    text-indent: -9999px;
    overflow: hidden;
    display: inline-block;
    margin: 2px 2px 0;
    -webkit-transition: all .40s;
    -moz-transition: all .40s;
    -o-transition: all .40s;
    transition: all .40s;

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.post-slideshow-nav a:hover {
    background: #939393;
}

.post-slideshow-nav a.activeSlide {
    background: #666;
}

a.post-slideshow-next,
a.post-slideshow-prev {
    background: #FF8500;
    padding: 3px 15px;
    cursor: pointer;
    overflow: auto;
    color: #FFF;
    float: right;
    margin-left: 5px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

a.post-slideshow-next:hover,
a.post-slideshow-prev:hover {
    background: #444;
}

/* Google Map Shortcode */

.google-map.alignleft {
    margin-right: 10px;
}

.google-map.alignright {
    margin-left: 10px;
}

.google-map.aligncenter {
    text-align: center;
}

/* ToolTip Shortcode */

.post-tooltip {
    cursor: pointer;
    display: inline-block;
    border-bottom: 1px #666 dotted;
    color: #ed5109;
}

.post-tooltip:hover {
    border-bottom-color: #ed5109;
}

/* Highlightp Shortcode */

.highlight {
    background: #FF9;
    color: #FFF;
}

.highlight-yellow {
    background: #FF9;
    color: #222;
}

.highlight-red {
    background: #F43D2A;
}

.highlight-blue {
    background: #3DB2EA;
}

.highlight-orange {
    background: #F97405;
}

.highlight-black {
    background: #000;
}

.highlight-green {
    background: #8CCB0A;
}

.highlight-gray {
    background: #999;
}

.highlight-pink {
    background: #FF7979;
}

/* Dropcap Shortcode */

.dropcap {
    display: block;
    float: left;
    margin: 0 8px 0 0;
    font-size: 50px;
    line-height: 50px;
    font-family: Georgia, Arial, "helvetica neue", Helvetica, Verdana, sans-serif;
}

/* Lists Shortcode */

.tie-list-shortcode ul {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.tie-list-shortcode li {
    list-style: none !important;
    padding-left: 20px;
}

.tie-list-shortcode ul li:before {
    margin-right: 5px;
    margin-left: -20px;
}

.tie-list-shortcode.starlist ul li:before {
    content: "\f005";
    color: #fbd606;
}

.tie-list-shortcode.checklist ul li:before {
    content: "\f00c";
    color: #7fb718;
}

.tie-list-shortcode.lightbulb ul li:before {
    content: "\f0eb";
    color: #fbd606;
}

.tie-list-shortcode.thumbup ul li:before {
    content: "\f164";
    color: #7fb718;
}

.tie-list-shortcode.thumbdown ul li:before {
    content: "\f165";
    color: #f01818;
}

.tie-list-shortcode.plus ul li:before {
    content: "\f0fe";
    color: #555;
}

.tie-list-shortcode.minus ul li:before {
    content: "\f146";
    color: #555;
}

.tie-list-shortcode.heart ul li:before {
    content: "\f004";
    color: #FF7979;
}

.tie-list-shortcode.cons ul li:before {
    content: "\f00d";
    color: #f01818;
}

/* Boxes Shortcode */

.box {
    padding: 20px;
    background-color: #F6F6F6;
    margin-bottom: 20px;
    word-wrap: break-word;
}

.box div.box-inner-block {
    padding-left: 70px;
    min-height: 50px;
}

.box .tie-shortcode-boxicon {
    float: left;
    margin-left: -70px;
}

.box .tie-shortcode-boxicon:before {
    line-height: 60px;
    height: 60px;
    width: 60px;
    font-size: 60px;
    margin: 0;
}

.box.shadow {
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 0 5px rgba(153, 153, 153, 0.6);
    -moz-box-shadow: 0 0 5px rgba(153, 153, 153, 0.6);
    box-shadow: 0 0 5px rgba(153, 153, 153, 0.6);
}

.box.shadow div.box-inner-block {
    padding: 0;
}

.box.info {
    background: #d6f6ff;
    border-color: #BFE3EC;
}

.box.info .tie-shortcode-boxicon:before {
    content: "\f05a";
    color: #2CB4DA
}

.box.success {
    background: #e2f2cb;
    border-color: #D1E4B7;
}

.box.success .tie-shortcode-boxicon:before {
    content: "\f05d";
    color: #8AB84D
}

.box.warning {
    background: #fffddb;
    border-color: #E9E59E;
}

.box.warning .tie-shortcode-boxicon:before {
    content: "\f071";
    color: #ECC21B;
}

.box.error {
    background: #ffe6e2;
    border-color: #EEBFB8;
}

.box.error .tie-shortcode-boxicon:before {
    content: "\f05e";
    color: #F03317;
}

.box.download {
    background: #e2f2cb;
    border-color: #D1E4B7;
}

.box.download .tie-shortcode-boxicon:before {
    content: "\f019";
    color: #8AB84D
}

.box.note {
    background: #fffddb;
    border-color: #E9E59E;
}

.box.note .tie-shortcode-boxicon:before {
    content: '\f15c';
    color: #ECC21B;
}

/* Buttons Shortcode */

a.shortc-button {
    border: none;
    cursor: pointer;
    padding: 0 10px;
    display: inline-block;
    margin: 10px 0 0;
    font-weight: 700;
    outline: none;
    position: relative;
    background: #bdc3c7;
    color: #fff !important;
    text-decoration: none;
    font-size: 10px;
    height: 25px;
    line-height: 25px;
    opacity: .9;
    overflow: hidden;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

a.shortc-button:hover {
    opacity: 1;
}

a.shortc-button:active {
    top: 1px;
}

a.shortc-button i {
    margin-right: 10px;
}

a.shortc-button.red {
    background: #e74c3c;
}

a.shortc-button.green {
    background: #2ecc71;
}

a.shortc-button.blue {
    background: #3498db;
}

a.shortc-button.orange {
    background: #e67e22;
}

a.shortc-button.pink {
    background: #ff00a2;
}

a.shortc-button.purple {
    background: #9b59b6;
}

a.shortc-button.black {
    background: #222;
}

a.shortc-button.white {
    background: #ecf0f1;
    color: #333 !important;
}

a.shortc-button.medium {
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
}

a.shortc-button.big {
    font-size: 24px;
    height: 65px;
    line-height: 65px;
    padding: 0 20px;
}

/* Flickr Shortcode */

.flickr-wrapper {
    overflow: hidden;
    margin-bottom: 20px;
}

.flickr-wrapper .flickr_badge_image {
    float: left;
    margin: 8px;
}

.flickr-wrapper .flickr_badge_image img {
    opacity: 1;
    padding: 4px;
    border: 1px solid #eee;
}

.flickr-wrapper .flickr_badge_image a:hover img {
    opacity: 0.6 !important;
}

/* Toggle Shortcode */

.toggle {
    margin-bottom: 15px;
    border: 1px solid #eee;
    position: relative;
}

.toggle h3 {
    background: #F1F1F1;
    font-weight: normal;
    font-size: 14px;
    padding: 10px;
    margin: 0;
    cursor: pointer;
    -webkit-transition: background .2s ease;
    -moz-transition: background .2s ease;
    -o-transition: background .2s ease;
    transition: background .2s ease;
}

.toggle h3:hover {
    background: #E7E7E7;
}

h3.toggle-head-close {
    display: none;
}

h3.toggle-head-open i,
h3.toggle-head-close i {
    float: right;
    font-size: 16px;
}

.toggle-content {
    padding: 25px;
}

.toggle.close .toggle-content,
.toggle.close h3.toggle-head-open {
    display: none;
}

.toggle.close h3.toggle-head-close {
    display: block;
}

/* Author Shortcode */

.author-info {
    margin-bottom: 15px;
    border: 1px solid #EEE;
    padding: 25px;
    position: relative;
}

.author-info-content {
    padding-left: 100px;
}

.author-info-content h3 {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 0;
}

.author-info img.author-img {
    max-width: 70px;
    float: left;
    margin-right: 15px;
}

/* Columns Shortcode */

.one_half,
.one_third,
.two_third,
.three_fourth,
.one_fourth,
.one_fifth,
.two_fifth,
.three_fifth,
.four_fifth,
.one_sixth,
.five_sixth {
    position: relative;
    margin-right: 4%;
    float: left;
}

.one_half {
    width: 48%
}

.one_third {
    width: 30.66%
}

.two_third {
    width: 65.33%
}

.one_fourth {
    width: 22%
}

.three_fourth {
    width: 74%
}

.one_fifth {
    width: 16.8%
}

.two_fifth {
    width: 37.6%
}

.three_fifth {
    width: 58.4%
}

.four_fifth {
    width: 67.2%
}

.one_sixth {
    width: 13.33%
}

.five_sixth {
    width: 82.67%
}

.entry .last {
    margin-right: 0 !important;
    clear: right;
}

/* Tabs Shortcode */

.post-tabs {
    margin-bottom: 20px;
    border: 1px solid #eee;
}

.post-tabs ul.tabs-nav {
    margin: 0;
    background: #f1f1f1;
}

.post-tabs ul.tabs-nav li,
.post-tabs-ver ul.tabs-nav li {
    line-height: 32px;
    cursor: pointer;
    display: table-cell;
    width: 1%;
    margin: 0;
    padding: 0;
    text-align: center;
    border: 1px solid #FFF;
    border-width: 0 1px 0 0;
    -webkit-transition: background .2s ease;
    -moz-transition: background .2s ease;
    -o-transition: background .2s ease;
    transition: background .2s ease;
}

.post-tabs ul.tabs-nav li:last-child,
.post-tabs-ver ul.tabs-nav li:last-child {
    border: 0;
}

.post-tabs ul.tabs-nav li:hover,
.post-tabs-ver ul.tabs-nav li:hover {
    background: #E7E7E7;
}

.post-tabs ul.tabs-nav li {
    text-align: center;
}

.post-tabs ul.tabs-nav li.current {
    background: #FFF;
    z-index: 1;
    height: 33px;
}

.post-tabs .pane,
.post-tabs-ver .pane {
    display: none;
    padding: 25px;
}

.post-tabs .pane:first-child,
.post-tabs-ver .pane:first-child {
    display: block;
}

.post-tabs-ver {
    margin-bottom: 20px;
}

.post-tabs-ver ul.tabs-nav {
    position: relative;
    left: 1px;
    float: left;
    width: 25%;
    margin: 0;
    background: #f1f1f1;
    border: 1px solid #eee;
    border-width: 1px 0 1px 1px;
}

.post-tabs-ver ul.tabs-nav li {
    display: block;
    width: 100%;
    padding: 0 8px;
    border: 0 none;
    border-bottom: 1px solid #FFF;
}

.post-tabs-ver ul.tabs-nav li.current {
    background: #FFF;
    z-index: 1;
}

.post-tabs-ver .pane {
    float: left;
    width: 75%;
    border: 1px solid #eee;
}

/* Full size image */

.tie-full-width-img img {
    height: auto;
    margin-right: -20px;
    margin-left: -20px;
    width: 660px;
    left: 0;
    max-width: none;
    margin-bottom: 10px;
}

.post-cover .tie-full-width-img img {
    width: 680px;
    margin-right: -24px;
    margin-left: -24px;
}

.full-width .tie-full-width-img img {
    width: 997px;
}

.full-width .post-cover .tie-full-width-img img {
    width: 1045px;
    margin-right: -24px;
    margin-left: -24px;
}

/* Full Width layout size full image */

.wide-layout .post-cover .tie-full-width-img img {
    width: 660px;
    margin-right: 0;
    margin-left: 0;
}

.wide-layout .full-width .post-cover .tie-full-width-img img {
    width: 1010px;
    margin-right: 0;
    margin-left: 0;
}

.wide-layout .full-width .tie-full-width-img img {
    width: 1010px;
}


/**
 * Lightbox
 * -----------------------------------------------------------------------------
 */

.ilightbox-overlay, .ilightbox-loader, .ilightbox-loader *, .ilightbox-holder, .ilightbox-holder .ilightbox-container, .ilightbox-holder .ilightbox-container img.ilightbox-image, .ilightbox-holder .ilightbox-container .ilightbox-caption, .ilightbox-toolbar, .ilightbox-toolbar *, .ilightbox-thumbnails, .ilightbox-thumbnails *, .ilightbox-holder .ilightbox-container .ilightbox-social, .ilightbox-holder .ilightbox-container .ilightbox-social * {
    float: none;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    line-height: 100%;
    vertical-align: baseline;
    background: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.ilightbox-overlay, .ilightbox-loader, .ilightbox-loader *, .ilightbox-holder .ilightbox-container .ilightbox-caption, .ilightbox-toolbar, .ilightbox-thumbnails, .ilightbox-thumbnails *, .ilightbox-holder .ilightbox-container .ilightbox-social {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
}

.ilightbox-noscroll {
    overflow: hidden;
}

.ilightbox-closedhand * {
    cursor: url(css/ilightbox/closedhand.cur), default !important;
}

.ilightbox-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
}

.ilightbox-loader {
    position: fixed;
    z-index: 100005;
    top: 45%;
    left: -192px;
    padding-left: 30px;
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
    -webkit-border-radius: 0 100px 100px 0;
    border-radius: 0 100px 100px 0;
}

.ilightbox-loader div {
    width: 72px;
    height: 72px;
    -webkit-border-radius: 0 100px 100px 0;
    border-radius: 0 100px 100px 0;
}

.ilightbox-loader.horizontal {
    left: 45%;
    top: -192px;
    padding: 0;
    padding-top: 30px;
    -webkit-border-radius: 0 0 100px 100px;
    border-radius: 0 0 100px 100px;
}

.ilightbox-loader.horizontal div {
    -webkit-border-radius: 0 0 100px 100px;
    border-radius: 0 0 100px 100px;
}

.ilightbox-toolbar {
    display: none;
    position: fixed;
    z-index: 100010;
}

.ilightbox-toolbar a {
    float: left;
    cursor: pointer;
}

.ilightbox-toolbar .ilightbox-prev-button,
.ilightbox-toolbar .ilightbox-next-button {
    display: none;
}

.ilightbox-thumbnails {
    display: block;
    position: fixed;
    z-index: 100009;
}

.ilightbox-thumbnails.ilightbox-horizontal {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
}

.ilightbox-thumbnails.ilightbox-vertical {
    top: 0;
    right: 0;
    width: 140px;
    height: 100%;
    overflow: hidden;
}

.ilightbox-thumbnails .ilightbox-thumbnails-container {
    display: block;
    position: relative;
}

.ilightbox-thumbnails.ilightbox-horizontal .ilightbox-thumbnails-container {
    width: 100%;
    height: 100px;
}

.ilightbox-thumbnails.ilightbox-vertical .ilightbox-thumbnails-container {
    width: 140px;
    height: 100%;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid {
    display: block;
    position: absolute;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail {
    display: block;
    cursor: pointer;
    padding: 10px;
    position: relative;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail img {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 2px;
    border-radius: 2px;

    -ms-interpolation-mode: bicubic;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail .ilightbox-thumbnail-icon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .7;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail .ilightbox-thumbnail-icon:hover {
    opacity: 1;
}

.ilightbox-holder {
    display: none;
    position: fixed;
    z-index: 100003;
    -webkit-transform: none;
    -moz-transform: none;
}

.ilightbox-holder.ilightbox-next, .ilightbox-holder.ilightbox-prev {
    cursor: pointer;
}

.ilightbox-holder div.ilightbox-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.ilightbox-holder.supportTouch div.ilightbox-container {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.ilightbox-holder img.ilightbox-image {
    width: 100%;
    height: 100%;
}

.ilightbox-holder .ilightbox-container .ilightbox-caption {
    display: none;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    max-width: 100%;
    padding: 5px 10px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 150%;
    word-wrap: break-word;
    z-index: 20003;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}

.ilightbox-holder .ilightbox-alert {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    padding-top: 100px;
    margin: auto;
    width: 300px;
    height: 50px;
}

.ilightbox-holder .ilightbox-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.ilightbox-holder .ilightbox-inner-toolbar {
    position: relative;
    z-index: 100;
}

.ilightbox-holder .ilightbox-inner-toolbar .ilightbox-toolbar {
    position: absolute;
}

.ilightbox-button {
    position: fixed;
    z-index: 100008;
    cursor: pointer;
}

.isMobile .ilightbox-button,
.isMobile .ilightbox-thumbnails {
    display: none !important;
}

.isMobile .ilightbox-toolbar .ilightbox-prev-button,
.isMobile .ilightbox-toolbar .ilightbox-next-button {
    display: block;
}

.ilightbox-title {
    font-size: 12px !important;
}

.ilightbox-title a {
    color: #ccc;
}

.ilightbox-title a:hover {
    color: #FFF;
}

.ilightbox-holder.light .ilightbox-inner-toolbar .ilightbox-title a,
.ilightbox-holder.metro-white .ilightbox-inner-toolbar .ilightbox-title a {
    color: #555;
}

.ilightbox-holder.light .ilightbox-inner-toolbar .ilightbox-title a:hover,
.ilightbox-holder.metro-white .ilightbox-inner-toolbar .ilightbox-title a:hover {
    color: #000;
}


/**
 * Responsive Videos
 * -----------------------------------------------------------------------------
 */

.fluid-width-video-wrapper {
    width: 100%;
    position: relative;
    padding: 0;
}

.entry .fluid-width-video-wrapper {
    clear: both;
}

.fluid-width-video-wrapper iframe,
.fluid-width-video-wrapper object,
.fluid-width-video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/**
 * Off Canvas Mobile menu
 * -----------------------------------------------------------------------------
 */

#slide-out {
    background: #222;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 80%;
    color: #ddd;
    -webkit-transform: translate3d(-101%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.search-mobile {
    margin: 15px 10px;
    height: 30px;
    overflow: hidden;
    background: #fcfcfc;
    border: 1px solid #ddd;
    border-top-color: #d1d1d1;
    border-left-color: #d1d1d1;
    overflow: hidden;
    position: relative;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    -webkit-box-shadow: inset 0 2px 5px #eee;
    -moz-box-shadow: inset 0 2px 5px #eee;
    box-shadow: inset 0 2px 5px #eee;
}

.search-mobile #s-mobile {
    background: transparent;
    float: right;
    margin: 0;
    padding: 7px 12px;
    width: 100%;
    color: #444;
    border: 0 none;
}

.search-mobile button.search-button {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 28px;
    padding: 0;
    width: 40px;
    font-size: 18px;
    color: #666;
}

.search-mobile button.search-button:hover {
    color: #000;
}

#slide-out .social-icons {
    margin-bottom: 15px;
}

#slide-out .social-icons a {
    color: #ccc;
    font-size: 16px;
    padding: 0 4px;
}

#slide-out .social-icons a:hover {
    color: #FFF;
}

#mobile-menu {
    border-top: 1px solid #2D2D2D;
    display: none;
}

#mobile-menu.mobile-hide-icons i.fa {
    display: none;
}

#slide-out #mobile-menu .mega-menu-block {
    padding: 0 !important;
    background: transparent !important;
    min-height: inherit !important;
}

#slide-out #mobile-menu ul ul,
#slide-out #mobile-menu .sub-menu-columns-item {
    display: none;
    background: #333;
}

#slide-out #mobile-menu li {
    list-style: none;
    position: relative
}

#slide-out #mobile-menu ul li.menu-item-has-children i.mobile-arrows {
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px;
    border-left: 1px solid #333;
    margin: 0;
    cursor: pointer;
    display: block;
}

#slide-out #mobile-menu a {
    color: #ccc;
    display: block;
    font-size: 16px;
    padding: 10px;
    padding-right: 0;
    border-bottom: 1px solid #2D2D2D;
}

#slide-out #mobile-menu a:hover {
    background: #111;
    color: #FFF;
}

#slide-out #mobile-menu ul ul a {
    font-size: 14px;
    padding-left: 30px
}

#slide-out #mobile-menu ul ul ul a {
    font-size: 12px;
    padding-left: 40px
}

#slide-out #mobile-menu ul ul ul a {
    padding-left: 50px
}

#slide-out #mobile-menu ul ul ul ul a {
    padding-left: 60px
}

#mobile-menu li.menu-item-home a:before {
    content: "\f015";
}

#slide-out-open {
    display: none;
    margin: 0;
    position: absolute;
    top: 33px;
    left: 5px;
    opacity: 1;
    height: 33px;
    width: 40px;
    z-index: 505;
}

#slide-out-open span {
    left: 6px;
}

#slide-out-open span,
#slide-out-open span:after,
#slide-out-open span:before {
    top: 5px;
    position: absolute;
    content: ' ';
    display: block;
    height: 3px;
    width: 28px;
    background: #333;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

#slide-out-open span:before {
    top: 8px;
}

#slide-out-open span:after {
    top: 16px;
}

#open-slide-overlay {
    position: fixed;
    top: 0;
    left: 80%;
    z-index: 500;
    overflow: hidden;
    width: 100%;
    height: 100%;
}


/* open and Close the SlideOut panel */

.csstransforms3d.csstransitions .js-nav .inner-wrapper {
    left: 80%;
}

.csstransforms3d.csstransitions .js-nav #mobile-menu {
    display: block;
}

.inner-wrapper,
#slide-out {
    -webkit-transition: -webkit-transform 500ms ease, opacity 500ms ease;
    -moz-transition: -moz-transform 500ms ease, opacity 500ms ease;
    -o-transition: -o-transform 500ms ease, opacity 500ms ease;
    transition: transform 500ms ease, opacity 500ms ease;
}

.csstransforms3d.csstransitions .js-nav .inner-wrapper {
    left: 0 !important;
    /*opacity: .5;*/
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.csstransforms3d.csstransitions .js-nav #slide-out-open span {
    top: 14px;
    -webkit-animation: fa-spin .2s 2 linear;
    animation: fa-spin .2s 2 linear;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.csstransforms3d.csstransitions .js-nav #slide-out-open span:after {
    top: 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.csstransforms3d.csstransitions .js-nav #slide-out-open span:before {
    opacity: 0;
}

.csstransforms3d.csstransitions .js-nav .inner-wrapper {
    -webkit-transform: translate3d(80%, 0, 0);
    -moz-transform: translate3d(80%, 0, 0);
    -ms-transform: translate3d(80%, 0, 0);
    -o-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0);
}

.csstransforms3d.csstransitions .js-nav #slide-out {
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    -o-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

/* Animation */

@-webkit-keyframes tieFadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes tieFadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

/* Reviews */

.review-box {
    padding: 0;
    background: transparent;
}

.review-top {
    width: 55%;
}

#review-box .review-item h5 {
    color: #666;
    margin-top: 0;
}

#review-box.review-percentage .review-item h5,
#review-box.review-percentage .review-item h5 {
    line-height: 28px;
}

.review-percentage .review-item span span {
    background-color: #FF8500;
    height: 6px;
    top: auto;
    bottom: 0;
}

.review-final-score {
    background-color: #FF8500;
}

.review-box,
#review-box h2.review-box-header,
.review-item,
.review-summary,
.user-rate-wrap,
#review-box h1,
#review-box h2,
#review-box h3,
#review-box h4,
#review-box h5,
#review-box h6,
#review-box p,
#review-box strong,
#review-box .user-rating-text span,
#review-box .user-rating-text small {
    color: #444;
}

#review-box h2.review-box-header {
    background: #eee;
}

.review-item,
.review-summary,
.user-rate-wrap,
.review-stars .review-item,
.review-percentage .review-item span {
    background: #F2F2F2;
}

.review-final-score {
    width: 110px;
}


/**
 * Dark Skin
 * -----------------------------------------------------------------------------
 */

body.dark-skin {
    background-color: #000;
    color: #bfbfbf;
}

body.dark-skin #wrapper.boxed #theme-header,
body.dark-skin #wrapper.boxed .breaking-news,
body.dark-skin #wrapper.boxed #main-content,
body.dark-skin #wrapper.boxed-all #theme-header,
body.dark-skin #wrapper.boxed-all {
    background-color: #3c3c3c;
    -webkit-box-shadow: 0 0 3px #141414;
    -moz-box-shadow: 0 0 3px #141414;
    box-shadow: 0 0 3px #141414;
}

body.dark-skin .cat-box-content,
body.dark-skin #sidebar .widget-container,
body.dark-skin #wrapper.boxed-all .breaking-news,
body.dark-skin #wrapper.wide-layout .breaking-news,
body.dark-skin .post-listing,
body.dark-skin #live-search_results,
body.dark-skin .arqam-lite-widget-counter,
body.dark-skin #commentform {
    background-color: #3c3c3c;
    -webkit-box-shadow: 0 0 3px #2A2A2A;
    -moz-box-shadow: 0 0 3px #2A2A2A;
    box-shadow: 0 0 3px #2A2A2A;
}

body.dark-skin #tabbed-widget .tabs-wrap li,
body.dark-skin .comments-avatar .widget-container li,
body.dark-skin .posts-list .widget-container li,
body.dark-skin .categort-posts .widget-container li,
body.dark-skin .authors-posts .widget-container li,
body.dark-skin .widget.woocommerce .widget-container li,
body.dark-skin .widget-container ul.reviews-posts li,
body.dark-skin .widget_categories .widget-container li,
body.dark-skin .widget_archive .widget-container li,
body.dark-skin .widget_nav_menu .widget-container li,
body.dark-skin .widget_meta .widget-container li,
body.dark-skin .widget_pages .widget-container li,
body.dark-skin .widget_recent_comments .widget-container li,
body.dark-skin .widget_recent_entries .widget-container li,
body.dark-skin #crumbs,
body.dark-skin .woocommerce-breadcrumb,
body.dark-skin .post-inner p.post-meta,
body.dark-skin .comment-wrap,
body.dark-skin .tie-weather-forecast,
body.dark-skin .share-post,
body.dark-skin #tabbed-widget .widget-top,
body.dark-skin .item-list,
body.dark-skin .widget.timeline-posts li h3,
body.dark-skin .widget.timeline-posts li span.tie-date:before,
body.dark-skin .entry #related_posts,
body.dark-skin .live-search_result_container li,
body.dark-skin .twitter-widget-content li,
body.dark-skin #theme-footer .twitter-widget-content li,
body.dark-skin .list-box li.other-news,
body.dark-skin .column2 li,
body.dark-skin .wide-box li,
body.dark-skin .cat-tabs-header,
body.dark-skin .sitemap-col h2,
body.dark-skin ul.authors-wrap li,
body.dark-skin .entry ul.best-reviews li,
body.dark-skin .arqam-lite-widget-counter li,
body.dark-skin .divider,
body.dark-skin .post-content-slideshow-outer,
body.dark-skin .woocommerce ul.products li.product .price,
body.dark-skin .woocommerce-page ul.products li.product .price,
body.dark-skin .flickr-wrapper .flickr_badge_image img,
body.dark-skin .review-final-score {
    border-color: #474747;
}

body.dark-skin .search-block-large #s,
body.dark-skin ul.timeline {
    border-color: #2F2F2F;
}

body.dark-skin #wrapper.wide-layout,
body.dark-skin #wrapper.wide-layout #theme-header,
body.dark-skin .share-post,
body.dark-skin .search-block-large #s,
body.dark-skin .commentlist .reply a,
body.dark-skin #tabbed-widget ul.tabs li.active a,
body.dark-skin .cat-tabs-header li.active,
body.dark-skin .post-tabs ul.tabs-nav li.current,
body.dark-skin .post-tabs-ver ul.tabs-nav li.current {
    background-color: #3c3c3c;
}

body.dark-skin .commentlist .reply a:hover,
body.dark-skin #tabbed-widget .widget-top,
body.dark-skin ul.timeline li.timeline-post:before,
body.dark-skin h2.timeline-head,
body.dark-skin .top-nav ul ul,
body.dark-skin .cat-tabs-header,
body.dark-skin .entry ul.best-reviews .best-review-score,
body.dark-skin #tabbed-widget .tabs-wrap.tagcloud a:hover,
body.dark-skin .woocommerce-pagination .page-numbers li .page-numbers.current {
    background-color: #2A2A2A;
}

body.dark-skin .search-block #s-header {
    background-color: #303030;
    border-color: #444;
}

body.dark-skin .scroll-nav {
    background-color: #373737;
}

body.dark-skin #tabbed-widget ul.tabs li.active a,
body.dark-skin .cat-tabs-header li.active {
    -webkit-box-shadow: 0 -1px 2px #282828;
    -moz-box-shadow: 0 -1px 2px #282828;
    box-shadow: 0 -1px 2px #282828;
}

body.dark-skin .top-nav .social-icons a {
    color: #999;
}

body.dark-skin a,
body.dark-skin .tie-weather-current-temp,
body.dark-skin #tabbed-widget ul.tabs li a,
body.dark-skin .search-block #s-header:focus {
    color: #ECECEC;
}

body.dark-skin p.post-meta a {
    color: #888;
}

body.dark-skin .breaking-news ul a {
    background: #3c3c3c;
}

body.dark-skin a:hover,
body.dark-skin p.post-meta a:hover,
body.dark-skin .author-comment cite,
body.dark-skin .post-title,
body.dark-skin #tabbed-widget ul.tabs li.active a,
body.dark-skin textarea:focus,
body.dark-skin input[type=text]:focus,
body.dark-skin input[type=password]:focus,
body.dark-skin input[type=email]:focus,
body.dark-skin input[type=url]:focus,
body.dark-skin input[type=tel]:focus,
body.dark-skin input[type=number]:focus,
body.dark-skin input[type=date]:focus,
body.dark-skin input[type=file]:focus,
body.dark-skin input[type=search]:focus,
body.dark-skin input[type=color]:focus,
body.dark-skin input[type=datetime]:focus,
body.dark-skin input[type=datetime-local]:focus,
body.dark-skin input[type=month]:focus,
body.dark-skin input[type=range]:focus,
body.dark-skin input[type=time]:focus,
body.dark-skin input[type=week]:focus,
body.dark-skin select:focus,
body.dark-skin .top-nav ul li a:hover,
body.dark-skin .top-nav ul li:hover > a,
body.dark-skin .top-nav ul:hover > a,
body.dark-skin .top-nav ul li.current-menu-item a,
body.dark-skin .top-nav ul ul li a:hover,
body.dark-skin .top-nav ul ul li:hover > a,
body.dark-skin .top-nav ul ul:hover > a,
body.dark-skin .top-nav ul ul li.current-menu-item a,
body.dark-skin .cat-tabs-header li.active a,
body.dark-skin a.more-link,
body.dark-skin .page-title {
    color: #FFF !important;
}

body.dark-skin .single-post-caption,
body.dark-skin .sitemap-col h2 {
    color: #ccc;
}

body.dark-skin .top-nav {
    background-color: #333;
    border-bottom-color: #444;
}

body.dark-skin.woocommerce ul.products li.product .price,
body.dark-skin.woocommerce-page ul.products li.product .price {
    border-color: #555;
}

body.dark-skin .today-date {
    color: #888;
}

body.dark-skin .page-link,
body.dark-skin .author-social a,
body.dark-skin .post-tag a:hover {
    background-color: #2B2B2B
}

body.dark-skin .post-tag a:hover:before {
    border-right-color: #2B2B2B
}

body.dark-skin .column2 li.other-news,
body.dark-skin .list-box li.other-news,
body.dark-skin .wide-box li.other-news {
    background-color: #373737;
    border-bottom: 1px solid #282828;
}

body.dark-skin .column2 li.other-news:hover,
body.dark-skin .list-box li.other-news:hover,
body.dark-skin #tabbed-widget .tabs-wrap.tagcloud a {
    background: #303030;
}

body.dark-skin textarea,
body.dark-skin input[type=text],
body.dark-skin input[type=password],
body.dark-skin input[type=email],
body.dark-skin input[type=url],
body.dark-skin input[type=tel],
body.dark-skin input[type=number],
body.dark-skin input[type=date],
body.dark-skin input[type=file],
body.dark-skin input[type=search],
body.dark-skin input[type=color],
body.dark-skin input[type=datetime],
body.dark-skin input[type=datetime-local],
body.dark-skin input[type=month],
body.dark-skin input[type=range],
body.dark-skin input[type=time],
body.dark-skin input[type=week],
body.dark-skin select {
    color: #999;
    background: #383838;
    border-color: #303030;
    border-top-color: #303030;
    border-left-color: #303030;
}

body.dark-skin textarea:hover,
body.dark-skin input[type=text]:hover,
body.dark-skin input[type=password]:hover,
body.dark-skin input[type=email]:hover,
body.dark-skin input[type=url]:hover,
body.dark-skin input[type=tel]:hover,
body.dark-skin input[type=number]:hover,
body.dark-skin input[type=date]:hover,
body.dark-skin input[type=file]:hover,
body.dark-skin input[type=search]:hover,
body.dark-skin input[type=color]:hover,
body.dark-skin input[type=datetime]:hover,
body.dark-skin input[type=datetime-local]:hover,
body.dark-skin input[type=month]:hover,
body.dark-skin input[type=range]:hover,
body.dark-skin input[type=time]:hover,
body.dark-skin input[type=week]:hover,
body.dark-skin select:hover {
    background-color: #333;
}

body.dark-skin .entry table thead th,
body.dark-skin .entry table tbody th {
    background-color: #3A3A3A;
}

body.dark-skin .entry table tr:nth-child(even) {
    background: #414141;
}

body.dark-skin .post-tag a,
body.dark-skin .toggle h3,
body.dark-skin .post-tabs ul.tabs-nav,
body.dark-skin .post-tabs-ver ul.tabs-nav {
    background-color: #333;
}

body.dark-skin .post-tag a:before {
    border-right-color: #333;
}

body.dark-skin .toggle,
body.dark-skin .post-tabs,
body.dark-skin .post-tabs-ver .pane,
body.dark-skin .post-tabs-ver ul.tabs-nav,
body.dark-skin .author-info,
body.dark-skin .entry table,
body.dark-skin .entry table thead th,
body.dark-skin .entry table td,
body.dark-skin .entry table th,
body.dark-skin .entry table tbody th {
    border-color: #333;
}

body.dark-skin .toggle h3:hover,
body.dark-skin .post-tabs ul.tabs-nav li:hover,
body.dark-skin .post-tabs-ver ul.tabs-nav li:hover,
body.dark-skin .review-item,
body.dark-skin .review-summary,
body.dark-skin .user-rate-wrap,
body.dark-skin .review-stars .review-item,
body.dark-skin .review-percentage .review-item span {
    background-color: #303030;
}

body.dark-skin .post-tabs ul.tabs-nav li,
body.dark-skin .post-tabs-ver ul.tabs-nav li {
    border-color: #444;
}

body.dark-skin .box {
    color: #222;
}

body.dark-skin .box.shadow {
    border-color: #333;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}

body.dark-skin #slide-out-open span,
body.dark-skin #slide-out-open span:after,
body.dark-skin #slide-out-open span:before {
    background-color: #888;
}

body.dark-skin.error404 .title-404 {
    color: #555;
}

body.dark-skin .wp-playlist.wp-playlist-light,
body.dark-skin .wp-playlist.wp-playlist-dark,
body.dark-skin .wp-playlist-item {
    border-color: #474747;
    color: #ddd;
}

body.dark-skin .wp-playlist-light .wp-playlist-caption,
body.dark-skin .wp-playlist-dark .wp-playlist-caption,
body.dark-skin #review-box .review-item h5 {
    color: #DDD;
}

body.dark-skin .wp-playlist.wp-playlist-light .wp-playlist-playing,
body.dark-skin .wp-playlist.wp-playlist-dark .wp-playlist-playing {
    background: #333;
    color: #ECECEC;
}

body.dark-skin .review-box,
body.dark-skin #review-box h2.review-box-header,
body.dark-skin .review-item,
body.dark-skin .review-summary,
body.dark-skin .user-rate-wrap,
body.dark-skin #review-box h1,
body.dark-skin #review-box h2,
body.dark-skin #review-box h3,
body.dark-skin #review-box h4,
body.dark-skin #review-box h5,
body.dark-skin #review-box h6,
body.dark-skin #review-box p,
body.dark-skin #review-box strong {
    color: #FFF;
}

body.dark-skin #review-box h2.review-box-header {
    background: #2A2A2A;
}


/**
 * Reponsive
 * -----------------------------------------------------------------------------
 */

/* 1045px */

@media only screen and ( max-width: 1045px ) {

    #wrapper.boxed,
    #wrapper.boxed .fixed-nav,
    #wrapper.boxed-all,
    #wrapper.boxed-all #main-content,
    #wrapper.boxed-all .fixed-nav {
        width: 99%;
    }

    #wrapper.boxed #theme-header,
    #wrapper.boxed .breaking-news,
    #wrapper.boxed #main-content,
    #wrapper.boxed .container,
    #wrapper.boxed-all .container {
        width: 100%;
    }

    #wrapper.boxed #main-content {
        padding: 15px;
    }

    #wrapper.boxed .post-cover-head {
        margin-left: -15px;
        margin-right: -15px;
    }

    #wrapper.boxed-all .post-cover-head {
        margin-left: 0;
        margin-right: 0;
    }

    .footer-bottom .container,
    #footer-widget-area {
        width: 98% !important;
    }

    #wrapper.boxed-all .breaking-news {
        width: 98% !important;
        margin-right: 1%;
        margin-left: 1%;
    }

    /* Full Layout */
    #wrapper.wide-layout .header-content,
    #wrapper.wide-layout .breaking-news,
    #wrapper.wide-layout .container,
    #wrapper.wide-layout #main-nav ul li.mega-menu .mega-menu-block {
        width: 99%;
    }

    .content {
        width: 66.5%;
    }

    #sidebar {
        width: 32%;
    }

    .ei-slider,
    .flexslider,
    .flexslider .slides > li {
        height: 480px;
    }

    .content .ei-slider,
    .content .flexslider,
    .content .flexslider .slides > li {
        height: 315px;
    }

    .post-cover {
        padding-right: 20px;
        padding-left: 20px;
    }

    .flat-social a {
        padding-right: 7px;
        padding-left: 7px;
    }

    .share-post li,
    .mini-share-post li {
        width: 100px;
    }

    .tie-full-width-img img {
        margin-right: 0 !important;
        margin-left: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
    }

}

/* 990px */

@media only screen and (max-width: 990px) {

    .logo {
        float: none !important;
        text-align: center;
        margin: 0 !important;
        clear: both;
    }

    .ei-slider,
    .flexslider,
    .flexslider .slides > li {
        height: 466px;
    }

    .content .ei-slider,
    .content .flexslider,
    .content .flexslider .slides > li {
        height: 481px;
    }

    .ei-title h2,
    .content .ei-title h2,
    .content .slider-caption h2 a,
    .content .slider-caption h2,
    .slider-caption h2 a,
    .slider-caption h2,
    .full-width .content .slider-caption h2 {
        font-size: 30px;
    }

    .ei-title h3,
    .content .ei-title h3 {
        font-size: 14px;
    }

    .e3lan-top {
        margin-top: 20px;
        float: none !important;
        text-align: center;
    }

    .content,
    #sidebar {
        width: 100%;
        float: none;
    }

    .widget.news-pic .post-thumbnail {
        width: 16.66666666666667%;
    }

    .footer-widgets-box {
        float: none !important;
        width: 100% !important;
        margin: 0 !important;
    }

    #check-also-box {
        display: none;
    }
}

/* 900px */
@media only screen and (max-width: 900px) {
    .top-nav,
    #main-nav,
    #live-search_sb {
        display: none;
    }

    #slide-out-open {
        display: block;
    }

    .ei-slider,
    .flexslider,
    .flexslider .slides > li {
        height: 431px;
    }

    .content .ei-slider,
    .content .flexslider,
    .content .flexslider .slides > li {
        height: 450px;
    }

    #wrapper {
        background-color: #FFF;
        width: 100% !important;
    }

    #wrapper #theme-header {
        margin-top: 0;
        width: 100% !important;
        -webkit-box-shadow: 0 0 3px #CACACA !important;
        -moz-box-shadow: 0 0 3px #CACACA !important;
        box-shadow: 0 0 3px #CACACA !important;
    }

    #wrapper .breaking-news,
    #wrapper.boxed-all .breaking-news {
        width: 99% !important;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 10px;
        -webkit-box-shadow: 0 0 1px #CACACA !important;
        -moz-box-shadow: 0 0 1px #CACACA !important;
        box-shadow: 0 0 1px #CACACA !important;
    }

    body.dark-skin #wrapper {
        background-color: #3c3c3c;
    }

    body.dark-skin #wrapper #theme-header {
        -webkit-box-shadow: 0 0 3px #2A2A2A !important;
        -moz-box-shadow: 0 0 3px #2A2A2A !important;
        box-shadow: 0 0 3px #2A2A2A !important;
    }

    body.dark-skin #wrapper .breaking-news,
    body.dark-skin #wrapper.boxed-all .breaking-news {
        -webkit-box-shadow: 0 0 1px #2A2A2A !important;
        -moz-box-shadow: 0 0 1px #2A2A2A !important;
        box-shadow: 0 0 1px #2A2A2A !important;
    }

    #wrapper #main-content {
        width: 99% !important;
        padding: 0 !important;

        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
    }

    #wrapper #main-content {
        margin-top: 10px;
    }

    .header-content {
        padding-right: 0;
        padding-left: 0;
    }

    .footer-bottom .alignright,
    .footer-bottom .alignleft,
    .footer-bottom .social-icons {
        text-align: center;
        float: none;
        width: 100%;
        margin: 0;
        line-height: 20px;
    }

    .csstransforms3d.csstransitions .js-nav #wrapper {
        width: 100% !important;
    }

    #wrapper.boxed .post-cover-head,
    #wrapper.wide-layout .post-cover-head {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
    }

}

/* 730px */

@media only screen and (max-width: 730px) {

    .featured-title h3,
    .featured-title span.tie-date {
        display: none;
    }

    #featured-posts .featured-post-inner {
        height: 200px;
    }

    .featured-title {
        bottom: 0;
        font-size: 75%;
        padding: 8px;
    }

    .featured-title h2 {
        margin-bottom: 0;
    }

    .flat-social a {
        padding-right: 5px;
        padding-left: 5px;
    }

    .flickr-widget .flickr_badge_image {
        width: 12.5%;
    }

    #sitemap {
        padding: 10px 0 0 !important;
    }

    .sitemap-col {
        width: 100%;
        float: none;
        margin: 0 0 10px;
    }

    .post-tabs ul.tabs-nav li,
    .post-tabs-ver ul.tabs-nav li {
        display: block;
        border-width: 0 0 1px 0;
        width: 100%;
        text-align: center;
        padding: 0;
    }

    .post-tabs ul.tabs-nav,
    .post-tabs-ver ul.tabs-nav {
        border: 1px solid #EEE;
        border-width: 0 0 1px 0;
    }

    .post-tabs-ver ul.tabs-nav,
    .post-tabs-ver .pane {
        width: 100%;
        left: 0;
        float: none;
        border-width: 1px;
    }

    .tie-padding {
        padding: 0 !important;
    }

    .post-cover-head .single-has-thumb.single-post-thumb {
        height: 350px;
    }

    .post-cover-title .post-title {
        font-size: 32px;
        line-height: 32px;
    }

    .post-cover-title p.post-meta {
        font-size: 12px;
    }

}


/* 670px */

@media only screen and (max-width: 670px) {


    .flat-social a span,
    .breaking-news span.breaking-news-title span,
    .share-post span.share-text,
    .entry #related_posts {
        display: none;
    }

    .breaking-news span.breaking-news-title {
        padding-bottom: 7px;
    }

    .breaking-news span.breaking-news-title i {
        display: block;
        margin-top: -2px;
    }

    .cat-box.column2,
    .list-box li,
    .list-box li.first-news,
    .wide-box li,
    .cat-tabs-wrap li.first-news,
    .cat-tabs-wrap li {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .list-box li.first-news {
        padding: 20px;
    }

    .list-box li.other-news {
        border: 0 none;
    }

    .share-post .flat-social a i,
    .mini-share-post .flat-social a i {
        margin: 0 10px;
    }

    .share-post .normal-social li,
    .mini-share-post .normal-social li {
        width: 33.333333% !important;
        height: 25px;
    }

    #featured-posts .featured-post {
        padding: 0 0 1px 1px !important;
        width: 50%;
    }

    #featured-posts .featured-post-inner {
        height: 140px;
    }

    #featured-posts .featured-post-1 {
        width: 100%;
    }

    #featured-posts .featured-post-1 .featured-post-inner {
        height: 220px;
    }

    #featured-posts .featured-post-1,
    #featured-posts .featured-post-2,
    #featured-posts .featured-post-4 {
        padding-left: 0 !important;
    }

}


/*  Phone  : 500px */

@media only screen and (max-width: 500px) {

    .ei-title h2,
    .content .ei-title h2,
    .content .slider-caption h2 a,
    .content .slider-caption h2,
    .slider-caption h2 a,
    .slider-caption h2,
    .full-width .content .slider-caption h2 {
        font-size: 22px;
    }

    .slider-caption {
        padding: 8px 0 5px;
    }


    .pic-box li:last-child,
    .entry ul.best-reviews .post-thumbnail,
    .ei-title h3,
    .slider-caption p,
    a.go-to-the-post {
        display: none !important;
    }

    .archive-wide-thumb {
        margin-right: -10px !important;
        margin-left: -10px !important;
        width: auto !important;
    }

    .one_half,
    .one_third,
    .two_third,
    .three_fourth,
    .one_fourth,
    .one_fifth,
    .two_fifth,
    .three_fifth,
    .four_fifth,
    .one_sixth,
    .five_sixth,
    .recent-box .recent-item,
    .scroll-box .scroll-item,
    #related_posts .related-item,
    .full-width #related_posts .related-item,
    .woocommerce ul.products li.product,
    .woocommerce-page ul.products li.product,
    .review-box,
    .google-map,
    .masonry-grid .item-list {
        float: none !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        width: 100% !important;
        height: auto !important;
    }

    .masonry-grid {
        padding: 0;
    }

    body.error404 .title-404 {
        font-size: 80px;
    }

    .cat-box li.first-news .inner-content,
    .scroll-box .cat-box-content,
    .pic-box .cat-box-content,
    .video-box .cat-box-content,
    .cat-tabs-wrap,
    .pic-box.pic-grid .cat-box-content,
    .list-box li.first-news,
    .recent-box .cat-box-content {
        padding: 10px;
    }

    .column2 li.other-news,
    .list-box li.other-news,
    .wide-box li {
        padding-right: 10px;
        padding-left: 10px;
    }

    .scroll-nav {
        margin: 10px -10px -10px;
    }

    .wide-box li.first-news .post-thumbnail,
    .item-list .post-thumbnail,
    .pic-box li.first-pic,
    .video-box .big-video-column {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 10px;
    }

    .pic-box li.first-pic,
    .video-box .big-video-column,
    .video-box li {
        margin-bottom: 0;
    }

    .pic-box li,
    .pic-box.pic-grid.cat-box li {
        width: 25%;
    }

    .video-box li {
        padding: 1px;
        width: 33.333333%;
    }

    .pic-box.pic-grid.cat-box li:last-child {
        display: block;
    }

    #masonry-grid.post-listing {
        padding: 0;
    }

    #masonry-grid .item-list {
        width: 92%;
        margin: 0 4%;
    }

    .widget.news-pic .post-thumbnail {
        width: 25%;
    }

    .entry ul.best-reviews li {
        padding-left: 65px;
    }

    .best-review-score-image {
        margin-left: -65px;
    }

    .author-info img.author-img {
        max-width: 50px;
        margin-right: 10px;
    }

    .author-info-content {
        padding-left: 60px;
    }

    .post-cover-head .single-has-thumb.single-post-thumb {
        height: 250px;
    }

    .post-cover-title {
        padding-right: 10px;
        padding-left: 10px;
    }

    .post-cover-title .post-title {
        font-size: 28px;
        line-height: 28px;
    }

    .post-cover-title p.post-meta {
        font-size: 12px;
    }

}


/* 350px */

@media only screen and (max-width: 350px) {


    .logo {
        text-align: right;
        padding-right: 20px;
    }

    .logo img {
        max-width: 50%;
        height: auto;
    }

    #theme-header.full-logo .logo {
        padding: 0;
    }

    #theme-header.full-logo .logo img {
        max-width: 100%;
    }

    #main-content {
        padding-top: 0;
    }

    .share-post .flat-social a i,
    .mini-share-post .flat-social a i {
        margin: 0 5px;
    }

    .share-post .normal-social li,
    .mini-share-post .normal-social li {
        width: 50% !important;
    }

    .overlay-icon:before,
    .tie_slider .overlay-icon:before {
        border-width: 2px;
        width: 30px;
        height: 30px;
        font-size: 14px;
        line-height: 26px;
        margin: -15px 0 0 -15px;
    }

    .widget.news-pic .post-thumbnail {
        width: 33.3333%;
    }

    .flickr-widget .flickr_badge_image {
        width: 25%;
    }

}


@media only screen and (max-width: 800px) {
    .ei-slider,
    .flexslider,
    .flexslider .slides > li,
    .content .ei-slider,
    .content .flexslider,
    .content .flexslider .slides > li {
        height: 411px;
    }

}

@media only screen and (max-width: 700px) {
    .ei-slider,
    .flexslider,
    .flexslider .slides > li,
    .content .ei-slider,
    .content .flexslider,
    .content .flexslider .slides > li {
        height: 350px;
    }
}


@media only screen and (max-width: 600px) {
    .ei-slider,
    .flexslider,
    .flexslider .slides > li,
    .content .ei-slider,
    .content .flexslider,
    .content .flexslider .slides > li {
        height: 300px;
    }
}


@media only screen and (max-width: 500px) {
    .ei-slider,
    .flexslider,
    .flexslider .slides > li,
    .content .ei-slider,
    .content .flexslider,
    .content .flexslider .slides > li {
        height: 250px;
    }
}


@media only screen and (max-width: 400px) {
    .ei-slider,
    .flexslider,
    .flexslider .slides > li,
    .content .ei-slider,
    .content .flexslider,
    .content .flexslider .slides > li {
        height: 200px;
    }
}


@media print {

    body {
        font-size: 20px;
    }

    .entry,
    .comment-content p {
        line-height: 40px;
    }

    .post-listing {
        border: 0 none;
    }

    .post-cover-title {
        opacity: 1 !important;
    }

    .post-title,
    .post-cover-title {
        font-size: 42px;
        line-height: 40px;
        color: #000;
    }

    #theme-header,
    .breaking-news,
    #crumbs,
    #sidebar,
    .share-post,
    #theme-footer,
    .footer-bottom,
    #related_posts,
    p.post-meta .post-comments,
    #author-box,
    .post-navigation,
    #comments,
    .e3lan,
    .post-tag,
    .post-edit-link {
        display: none !important;
    }

    .content {
        float: none;
        width: 100%;
    }
}

.row {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
}

.col-lg-4 {
    width: calc(100% / 3);
    padding: 0 15px;
}

.product-listing {
    margin-top: 30px;
}

.product--item {
    border-radius: 15px 15px 15px 15px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;
}

.product--name {
    padding: 10px 0;
    padding-top: 25px;
    color: #465157;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 23px;
    margin-bottom: 10px;
    text-align: center;
}

.product--image {
    height: 316px;
    overflow: hidden;
}

.product--image img {
    width: 100%;
    object-fit: cover;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product--image:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.col-lg-6 {
    width: calc(100% / 2);
    padding: 0 15px;
}

.mt-0 {
    margin-top: 0 !important;
}

.product--price--buy {
    font-weight: 600;
    font-size: 26px;
    color: #a31818;
}

.product--price--sale {
    font-weight: 600;
    font-size: 16px;
    padding-left: 5px;
}

.body--description, .product--price {
    margin-top: 10px;
}

.parents .post-box-title {
    margin-bottom: 10px;
}

.parents .post-box-title a {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
}

.parents .post-thumbnail {
    height: 170px;
    border-radius: 15px;
    overflow: hidden;
}

.parents .post-thumbnail img {
    height: 170px;
    object-fit: cover;
}

.parents .cat-box-title h2 {
    text-align: center;
    float: unset !important;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
}

.parents .see--more {
    text-align: right;
    margin-bottom: 10px;
}

.parents .scroll-navigation {
    position: absolute;
    top: 40%;
    font-size: 50px;
    font-weight: 600;
    cursor: pointer;
}

.parents .nav--next {
    right: -50px;
}

.parents .nav--prev {
    left: -50px;
}
@media (max-width: 480px) {
    .row {
        display: block;
    }

    .col-xs-12 {
        width: calc(100% - 30px);
        margin: auto;
    }

    .detail--name {
        margin-top: 10px !important;
    }

    .product--inner {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .entry-content {
        padding-left: 15px;
        padding-right: 15px;
    }
}
