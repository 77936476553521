.content-main {
    float: left;
    width: 100%;
}
.wrap-section:not(:last-child) {
    margin-bottom: 30px;
}
.section-carousel .item-list {
    padding: 0!important;
}
.section-carousel .entry {
    float: left;
    width: 100%;
}

.section-carousel .post-thumbnail {
    margin: 0!important;
    border-radius: 20px;
    overflow: hidden;
}

.section-carousel .post-thumbnail img {
    height: 140px;
    object-fit: cover;
}
.section-carousel .post-box-title {
    float: left;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
}
.section-carousel .post-box-title a {
    font-family: 'Muli';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 46px;
}
.item-list p.post-meta {
    width: 100%;
}
.post-thumbnail.tie-appear {
    width: 100%;
}

.section-label {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 5px solid #a31818;
}

.section-label a {
    font-family: 'Muli';
    font-size: 26px;
    font-weight: 600;
}

.articles .section-label {
    margin-top: 20px;
}
.articles .section-label:not(:first-child) {
    margin-top: 0;
}
.owl-theme .owl-nav {
    font-size: 87px;
    color: #a31818;
}
.owl-next, .owl-prev{
    position: absolute;
    top: 13%;
}

.slider-desktop .owl-next, .slider-desktop .owl-prev {
    top: 30%;
}

.owl-next {
    right: 15px;
}

.owl-prev {
    left: 15px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: unset!important;
}

#sliders {
    margin-bottom: 30px;
}

#abouts {
    padding: 40px 10px;
}
.about-wrap {
    padding: 4px 0;
    display: flex;
}
.about-image {
    width: 47%;
}
.about-image img {
    margin: auto;
    display: block;
}
.about-content {
    width: 53%;
    padding-left: 30px;
}
.about-title {
    font-size: 32px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.about-description {
    text-align: justify;
}

.parameter-wrap {
    display: flex;
    justify-content: space-around;
}
.parameter-item {
    text-align: center;
    width: 33.333%;
    color: #fff;
    padding: 30px;
}
.parameter-content {
    /*background: rgba(163, 25, 25, 0.7);*/
    border-radius: 20px;
    /*padding: 30px 0px;*/
    overflow: hidden;
}
.parameter-icon img {
    /*height: 80px;*/
    margin-bottom: 10px;
}
.parameter-int {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
}

.parameter-title {
    font-weight: 600;
    font-size: 18px;
}
.articles .post-thumbnail img  {
    height: 223px;
}

.articles:nth-child(3) .post-thumbnail img  {
    height: 160px!important;
}

#posts-list-widget-2 h3 {
    margin-top: 5px;
}

.form-group .form-control {
    padding: 10px 10px 10px 25px;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #86868b;
    background: #fff;
    border: 1px solid #e5e5e6;
    border-radius: 26px;
    width: 100%;
}

.form-group {
    margin-bottom: 16px;
}

.btn-register {
    border: 1px solid #fff;
    border-radius: 50px;
    width: 204px;
    padding: 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-size: 1.2em;
    display: block;
    margin: auto;
}
 .btn-register span {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 12px 30px;
    font-size: 15px;
}

 .form-image {
     width: 65%;
 }
 .form-image img {
     height: 500px;
     object-fit: cover;
     width: 100%;
 }
 .form-content {
     width: 35%;
     padding: 30px;
     background: #a31818 !important;
 }
 .form-title {
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
     color: #fff;
}
@media (max-width: 480px) {
    #parameters {
        padding: 0 10px;
    }
    .parameter-wrap, .about-wrap {
        display: block;
    }
    .parameter-item {
        width: 100%;
        padding: 15px 0;
    }
    .parameter-item:last-child {
        margin-bottom: 0!important;
    }
    .about-image, .about-content {
        width: 100%;
        padding-left: 0;
    }
    .form-image, .form-content {
        width: 100%;
    }
}
